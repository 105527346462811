const getSheets = async (axiosInstance, {type, studentId, sheetsId}) => {
    try {
        const result = await axiosInstance.get(`/student_azhar_sheet?student=${studentId}&${type}=${sheetsId}`);
        if (result)

            return [result.data, null];

    } catch (errors) {
        return [null, errors.response.data];
    }
};

async function submit(axiosInstance, {marks, studentId, sheetsId}) {

    try {
        const data = {
            student: studentId,
            marks: Object.values(marks),
        }
        const result = await axiosInstance.put(`/student_azhar_sheet/${sheetsId}`, data);
        if (result.status >= 200 && result.status <= 209) {
            return ["Sheets Marked Successfully."];
        }


    } catch (errors) {
        return [null, errors.response.data];
    }
}

const requests = {get: getSheets, submit}
export default requests;