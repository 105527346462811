import React, {useState, useEffect, useRef} from "react";
import {Container, Row, Col, Modal, Form, Button} from "react-bootstrap";
import axiosInstance, {BASE_URL} from "../../api/axios";
import img from "../../images/courseImg.png";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import {useParams, Link} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import {Collapse, Result, Input} from "antd";
import {useSelector} from "react-redux";
import {Player} from "video-react";
import {Pagination} from "antd";
import PaymentPage from "./Payment"
import {useContext, useCallback} from "react";
import {UNSAFE_NavigationContext as NavigationContext} from "react-router-dom";
import useScreenShotPreventer from "../../hooks/screenshotpreventer";
import SubLink from "../Link";

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
var test;

export function useBlocker(blocker, when = true, videoPlayer, id) {
    test = id;
    const {navigator} = useContext(NavigationContext);

    const doSomething = async (videoPlayer, id) => {
        console.log("firevideooooo2");
        console.log(test);
        let putCurrentTime = await axiosInstance
            .put(`/StudentSession/${test}`, {
                current: Math.floor(videoPlayer.current.video.props.player.currentTime)
            })
            .catch((err) => {
                console.log(err.response);
            });
        console.log(putCurrentTime.data);
    };

    useEffect(() => {
        if (!when) return;

        const unblock = navigator.block((tx) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    // Automatically unblock the transition so it can play all the way
                    // through before retrying it. TODO: Figure out how to re-enable
                    // this block if the transition is cancelled for some reason.
                    // console.log("firevideooooo");
                    doSomething(videoPlayer, id);

                    unblock();
                    tx.retry();
                }
            };

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, blocker, when]);
}

/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
export function usePrompt(message, when = true, videoPlayer, id) {
    const blocker = useCallback(
        (tx) => {
            console.log("usePrompt", message, tx);

            // eslint-disable-next-line no-alert
            if (window.confirm(message)) tx.retry();
        },
        [message]
    );

    useBlocker(blocker, when, videoPlayer, id);
}

function SessionDetails(props) {
    const {Panel} = Collapse;
    const videoPlayer = useRef(null);

    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [code, setCode] = useState("");
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [link, setLink] = useState("");
    const [desc, setDesc] = useState("");
    const [order, setOrder] = useState("");
    const [currimage, setCurrImage] = useState(null);
    const [currvideo, setCurrVideo] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [isPaid, setIsPaid] = useState(true);
    const [errMsg, setErrMsg] = useState("");
    const [showpayCode, setShowPayCode] = useState(false);
    const [showPayBtn, setShowPayBtn] = useState(false);
    const [materials, setMaterials] = useState([]);
    const [materialsPage, setMaterialsPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [_id, setId] = useState("");
    const [current, setcurrent] = useState(0);
    const [timer, setTimer] = useState("");

    const params = useParams();
    const user = useSelector((state) => state.auth.user);


    const getMaterials = async () => {
        let result = await axiosInstance.get(
            `/material_session/${params.id}?page=${materialsPage}`
        );
        if (result) {
            setMaterials(result.data);
            setLoading(false);
        }
    };

    const getDetails = async () => {
        setLoading(true)
        let result = await axiosInstance
            .get(`/view_session/${params.id}`)
            .catch((err) => {
                if (err.response && err.response.status !== 500) {
                    for (const error in err.response.data) {
                        setTimeout(() => {
                            setErrMsg(
                                `${err.response.data[error][0].charAt(0).toUpperCase() +
                                err.response.data[error][0].slice(1)
                                }`
                            );
                        }, 500);
                        if (
                            err.response.data[error][0] ==
                            "buy the session first"
                        ) {
                            setIsPaid(false);
                        } else {

                        }

                        setLoading(false);

                    }
                } else {
                    setTimeout(() => {
                        setErrMsg("Server Error !");
                    }, 500);
                }
            });
        if (!result) return;
        // console.log(result.data);
        setDesc(result.data.session.description);
        setLink(result.data.session.zoom_link);
        setPrice(result.data.session.price);
        setOrder(result.data.session.order);
        setCurrImage(result.data.session.image);
        setCurrVideo(result.data.session.video);
        setTitle(result.data.session.title);
        setId(result.data.id);
        setcurrent(result.data.current);
        console.log({a33: new Date(result.data.created_add)})
        getRemainingTime(result.data.timer);
        setLoading(false);
    };
    useEffect(() => {
        getDetails();
    }, []);

    useEffect(() => {
        setLoading(true);

        getMaterials();
    }, [materialsPage]);
    const openModelPayment = () => {
        setShowPayCode(true);
    };
    const closeCodeModel = () => {
        setShowPayCode(false);
    };
    const payCode = async () => {
        let data = {
            session: params.id,
            code: code
        };
        console.log(data);
        let result = await axiosInstance
            .post(`/StudentSession/`, data)
            .catch((err) => {
                console.log(err.response);
                if (err.response) {
                    setShowPayCode(false);
                    setTimeout(() => {
                        toast.error(err.response.data);
                    }, 500);
                }
            });
        if (result) {
            console.log(result);
            if (result.data) {
                setShowPayCode(false);
                setTimeout(() => {
                    toast.success("Enrolled Successfully");
                }, 500);
                setIsPaid(true);
                getDetails();
            }
        }
    };
    const payWallet = async () => {
        let data = {
            session: params.id
        };
        console.log(data);
        let result = await axiosInstance
            .post(`/StudentSession/`, data)
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status !== 500) {
                    for (const error in err.response.data) {
                        setTimeout(() => {
                            toast.error(
                                `${error +
                                " : " +
                                err.response.data[error][0].charAt(0).toUpperCase() +
                                err.response.data[error][0].slice(1)
                                }`
                            );
                        }, 500);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Server Error !");
                    }, 500);
                }
            });
        if (result) {
            console.log(result);
            if (result.data) {
                setShowPayCode(false);
                setTimeout(() => {
                    toast.success("Enrolled Successfully");
                }, 500);
                setIsPaid(true);
                getDetails();
            }
        }
    };

    const formIsDirty = true; // Condition to trigger the prompt.
    usePrompt("Leave screen?", formIsDirty, videoPlayer, _id);
    useScreenShotPreventer();
    const getRemainingTime = (e) => {
        let d = Number(e);
        var days = Math.floor(d / (1000 * 60 * 60 * 24));
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);
        console.log(
            ("0" + h).slice(-2) +
            ":" +
            ("0" + m).slice(-2) +
            ":" +
            ("0" + s).slice(-2),
            "days",
            days
        );
        setTimer(
            days +
            " " +
            "Days and " +
            ("0" + h).slice(-2) +
            ":" +
            ("0" + m).slice(-2) +
            ":" +
            ("0" + s).slice(-2) +
            " " +
            "hours passed"
        );
    };
    return (
        <>{loading ? <>loading</> : <div className="">
            {!isPaid ? (
                <PaymentPage confirmPaid={() => {
                    setTimeout(() => {
                        toast.success("Enrolled Successfully");
                    }, 500);
                    setIsPaid(true);
                    getDetails();
                }}/>
            ) : (
                <Container>
                    <Row className="py-4">
                        <Col sm={8}>
                            {" "}
                            <SubLink text="Courses"/>
                            <i class="fas fa-chevron-right px-3 title2"></i>
                            <span className="title2"> Session Details</span>
                        </Col>
                        <Col sm="4">
                            <p className="timep">{timer}</p>
                        </Col>
                    </Row>
                    <>
                        {currimage !== null ? (
                            <div className="text-center mx-auto">
                                <img
                                    src={BASE_URL + currimage}
                                    alt={title}
                                    className="img-fluid "
                                />
                            </div>
                        ) : (
                            <p>There's no image yet</p>
                        )}

                        <Form>
                            <Row>
                                <Col sm="12">
                                    <Form.Label>Session Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Session Title"
                                        value={title}
                                        name="title"
                                        onChange={(e) => setTitle(e.target.value)}
                                        disabled={!isEditable}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <Form.Label>Session Zoom Link</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Session Zoom Link"
                                        value={link}
                                        name="link"
                                        onChange={(e) => setLink(e.target.value)}
                                        disabled={!isEditable}
                                    />
                                    <a target="_blank" href={link}>
                                        Click Here to open the session link
                                    </a>
                                </Col>
                                <Col sm="12">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Session Price"
                                        value={price}
                                        name="price"
                                        onChange={(e) => setPrice(e.target.value)}
                                        disabled={!isEditable}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col sm="12">
                  <Form.Label>Display Order</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Display Order"
                    value={order}
                    name="order"
                    onChange={(e) => setOrder(e.target.value)}
                    disabled={!isEditable}
                  />
                </Col> */}
                                <Col sm="12">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        rows="5"
                                        type="text"
                                        placeholder="Description"
                                        as="textarea"
                                        value={desc}
                                        name="desc"
                                        onChange={(e) => setDesc(e.target.value)}
                                        disabled={!isEditable}
                                    />
                                </Col>
                            </Row>
                            <Row onContextMenu={(e) => e.preventDefault()}>
                                {currvideo !== null ? (
                                    <Col sm="12 py-4">
                                        <Player
                                            startTime={current}
                                            ref={videoPlayer}
                                            playsInline
                                            src={BASE_URL + currvideo}

                                        />
                                    </Col>
                                ) : (
                                    <p>There's no video yet</p>
                                )}
                            </Row>
                        </Form>
                        {user.attendance_place === "1" ? null : (
                            <Row>
                                <Col sm="12">
                                    {/* <Collapse bordered={false} defaultActiveKey={["0"]}>
                    <Panel header="Session Quizes/Homework" key="1">
                      <Link to={`/view_quizes/${params.id}`}>
                        <i className="actionIcon fas fa fa-eye px-2"></i>View
                        Quizes/Homework
                      </Link>
                    </Panel>
                  </Collapse> */}

                                    <div className="mb-3">
                                        <span className="title1">Courses</span>
                                        <i class="fas fa-chevron-right px-3 title2"></i>
                                        <span className="title2"> Session Quizes/Homework</span>
                                    </div>

                                    <div className="mb-3">
                                        <Link to={`/view_quizes/${params.id}`}>
                                            <i className="actionIcon fas fa fa-eye px-2"></i>View
                                            Quizes/Homework
                                        </Link>
                                    </div>

                                    <div className="mb-3">
                                        <span className="title1">Courses</span>
                                        <i class="fas fa-chevron-right px-3 title2"></i>
                                        <span className="title2"> Session materials</span>
                                    </div>

                                    {loading
                                        ? null
                                        : materials.data?.map((material, i) => (
                                            <a
                                                href={BASE_URL + material.material}
                                                target="_blank"
                                                key={i}
                                            >
                                                <i className="actionIcon fas fa fa-eye px-2 py-2"></i>
                                                View material
                                            </a>
                                        ))}

                                    {loading ? null : (
                                        <Pagination
                                            className="mt-3"
                                            defaultCurrent={materialsPage}
                                            total={materials.count}
                                            onChange={(e) => setMaterialsPage(e)}
                                        />
                                    )}
                                </Col>
                            </Row>
                        )}
                    </>
                </Container>
            )}

            <Modal show={showpayCode} onHide={closeCodeModel}>
                <Container className="p-4">
                    <Row>
                        <Col className="text-center" sm={12}>
                            {" "}
                            <h12>Enter the code</h12>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" sm={12}>
                            {" "}
                            <Input
                                value={code}
                                name="code"
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="text-center" md={12}>
                            <Button onClick={payCode} className="courseBtn1 mx-3">
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        </div>
        }
        </>
    );
}

export default ErrorHandler(SessionDetails, axiosInstance);
