import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../style-sheets/loginStyle.css";
import { Form, Input, Button, Select } from "antd";
// import { showNav } from "../global-state/actions/showNavAction";
import axiosInstance from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tabs } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
const { TabPane } = Tabs;
const CreateAssistant = () => {
  const [fname, setFname] = useState("");
  const [mName, setMname] = useState("");

  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailerr] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedUser, setUser] = useState("1");
  const [switchView, setSwitch] = useState(false);
  const [school_name, setSchool] = useState("");
  const [gender, setGender] = useState("");
  const [father_phone, setFatherPhone] = useState("");
  const [mother_phone, setMotherPhone] = useState("");
  const [governate, setGovernate] = useState("");
  const [edu_lvl, setLevel] = useState("");
  const [attendance_place, setPlace] = useState("");
  const [center, setSelectedCenter] = useState("");
  const [special_student, setSpecial] = useState("");
  const [points, setPoints] = useState("");
  const [balance, setBalance] = useState("");
  const [status, setStatus] = useState("");
  const [governorate, setGovernorate] = useState([]);
  const [centers, setCenters] = useState([]);

  let navigate = useNavigate();

  const onFinish = async (values) => {
    // values.user = selectedUser
    values.first_name = fname;
    values.last_name = lname;
    values.email = email;
    values.password = password;
    values.phone = phone;
    values.middle_name = mName;

    console.log(values);
    let result;
    result = await axiosInstance
      .post(`/signup_assistant`, values)
      .catch((err) => {
        console.log(err.response);
        for (const error in err.response.data) {
          console.log(err.response.data[error][0]);
          setTimeout(() => {
            toast.error(
              `${
                error +
                " : " +
                err.response.data[error][0].charAt(0).toUpperCase() +
                err.response.data[error][0].slice(1)
              }`
            );
          }, 500);
        }
      });

    if (result) {
      console.log(result);
      setTimeout(() => {
        toast.success("User Created Successfully");
      }, 500);
      setTimeout(() => {
        navigate(`/assistants`);
      }, 2500);
    }
  };

  const onFinishFailed = (values) => {
    console.log(values);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    if (
      e.target.value.match(
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
      )
    ) {
      setEmail(e.target.value);
      setEmailerr("");
    } else {
      setEmailerr("Enter valid email!");
    }
  };

  return (
    <div className="">
      <Container className="">
        <Row className="mb-3">
          <Col className="" sm={12}>
            {" "}
            <span className="title1">Assistants</span>
            <i class="fas fa-chevron-right px-3 title2"></i>
            <span className="title2">Create Assistant Account</span>
          </Col>
        </Row>
        <Form onFinish={onFinish} layout="vertical">
          <Row>
            <Col className="text-left p-4" md={6}>
              <>
                <Form.Item
                  label="First Name"
                  name="fname"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                  layout="vertical"
                >
                  <Input
                    className="loginInput"
                    placeholder="First Name"
                    type="text"
                    name="fname"
                    value="fname"
                    onChange={(e) => setFname(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Middle Name"
                  name="mName"
                  rules={[
                    { required: true, message: "Please input your middle name!" },
                  ]}
                  layout="vertical"
                >
                  <Input
                    className="loginInput"
                    placeholder="Middle Name"
                    type="text"
                    name="mName"
                    value={mName}
                    onChange={(e) => setMname(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input valid Email!",
                      type: "email",
                    },
                  ]}
                  layout="vertical"
                >
                  <Input
                    className="loginInput"
                    placeholder="Email"
                    type="email"
                    name="email"
                    value="email"
                    onChange={handleEmail}
                  />
                </Form.Item>

                <Form.Item
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input valid phone number!",
                      min: 11,
                      max: 11,
                    },
                  ]}
                >
                  <Input
                    value={phone}
                    placeholder="Phone"
                    className="loginInput"
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    type="number"
                  />
                </Form.Item>
              </>
            </Col>
            <Col className="imgCol1" md={6}>
              <>
                <Form.Item
                  label="Last Name"
                  name="lname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last  name!",
                    },
                  ]}
                  layout="vertical"
                >
                  <Input
                    className="loginInput"
                    placeholder="Last Name"
                    type="text"
                    name="lname"
                    value="lname"
                    onChange={(e) => setLname(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input valid password!",
                      min: 5,
                      max: 15,
                    },
                  ]}
                >
                  <Input.Password
                    name="password"
                    placeholder="Password"
                    className="loginInput"
                    value="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
              </>

              <>
                {/* {" "}
                <Form.Item>
                  <Button
                    className="loginBtn1"
                    //   onClick={onLogin}
                    type="primary"
                    htmlType="submit"
                  >
                    Sign Up{" "}
                  </Button>
                </Form.Item> */}
              </>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <Form.Item>
                <Button
                  className="courseBtn1"
                  //   onClick={onLogin}
                  type="primary"
                  htmlType="submit"
                >
                  Create{" "}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>

    </div>
  );
};
export default ErrorHandler(CreateAssistant, axiosInstance);
