import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import axiosInstance from "../../api/axios";
import { Pagination, Empty } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { useParams } from "react-router-dom";
import SubLink from "../Link";

function Reviews() {
  const params = useParams();

  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [sessionID, setSessionID] = useState("");

  const getReviews = async () => {
    let result = await axiosInstance.get(`/review_course/${params.id}?page=1`);
    console.log(result);
    if (result) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);
  const onPageChange = async (page) => {
    setCurrent(page);
    let result = await axiosInstance.get(
      `/review_course/${params.id}?page=${page}`
    );
    setData(result.data.data);
    setCount(result.data.count);
  };

  return (
    <div>
      <div className="">
        <Container>
          <Row>
            <Col className="py-4" sm={6}>
              {" "}
              <SubLink text="Courses" />
              <i class="fas fa-chevron-right px-3 title2"></i>
              <span className="title1">
                {data[0]?.course.title} Reviews
              </span>
            </Col>
          </Row>
          {data.length !== 0 ? (
            data.map((info) => {
              return (
                <Row className="my-2">
                  <Col sm={8}>
                    <Card className="studentInfo" style={{ width: "90%" }}>
                      <Card.Body>
                        <Card.Text>
                          <strong>
                            {info.student?.user?.first_name}{" "}
                            {info.student?.user?.last_name}
                          </strong>
                          <p> {info.student?.code}</p>
                          <p className="ps-3">{info?.review}</p>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              );
            })
          ) : (
            <Empty />
          )}

          <Row className="p-4">
            {data.length !== 0 ? (
              <Pagination
                onChange={onPageChange}
                defaultCurrent={current}
                total={count}
                current={current}
              />
            ) : null}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ErrorHandler(Reviews, axiosInstance);
