import React, {useState, useEffect} from "react";
import styles from "./OnGoingAzharQuestions.module.css";
import {Row, Col, Container, Button, Modal} from "react-bootstrap";
import "../../../../../style-sheets/training.css";
import ErrorHandler from "../../../../../error-handler/ErrorHandler";
import axiosInstance from "../../../../../api/axios";
import {Player} from "video-react";
import {Input, Form, Radio, Space, Upload, message, Spin, Result, Empty} from "antd";
import {UploadOutlined} from "@ant-design/icons";

import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useParams, useNavigate} from "react-router-dom";
import QuestionCard from "../../Common/QuestionCard";
import PrimaryButton from "../../../../common/Button/PrimaryButton";
import SecondaryButton from "../../../../common/Button/SecondaryButton";
import AzharQue from "./AzharQue";
import PreviousQuestions from "../../Common/PreviousQuestions";
import StudentQuestions from "../../Ordinary/OnGoingStudentQuestions/StudentQuestions";
import LoadingPage from "../../../../common/LoadingPage"
import useScreenShotPreventer from "../../../../../hooks/screenshotpreventer";
import {parseCounterData} from "../../Common/DurationCounter/utils";

function OnGoingAzharQuestions({type}) {

    const states = {
        loading: 1,
        normal: 2,
        error: 3,
        success: 4,
        previous: 5,
        empty: 6,
    }

    const params = useParams();
    const navigate = useNavigate();
    useScreenShotPreventer();
    const [mcqQuestions, setMcqQuestions] = useState([]);
    const [sheetQuestion, setSheetQuestion] = useState([]);
    const [screenState, setScreenState] = useState(states.loading)
    const [loading, setLoading] = useState(true);
    const [sendLoading, setSendLoading] = useState(false);
    const [selectedChoices, setSelectedChoices] = useState({});
    const [displayError, setDisplayError] = useState(true);
    const [results, setResults] = useState("");
    const [modelAnswers, setModelAnswers] = useState({});
    const [counterData, setCounterData] = useState({});
    const getQuestions = async () => {
        setLoading(true);
        let result = await axiosInstance
            .get(`/${type}_question/${params.id}?page_size=10000`)
            .catch((err) => {
                console.log(err.response);
                if (
                    err.response.status == "400"
                ) {
                    setScreenState(states.error)
                    setDisplayError("This student passed the quiz before or exceed no of tries");
                }
            });

        if (result.status == 208) {
            setModelAnswers(result.data);
            setScreenState(states.previous);
        } else if (result) {
            setMcqQuestions(result.data.data);
            setSheetQuestion(result.data.sheets);
            setCounterData(parseCounterData(result.data[`student_${type}`], type))
            if (result.data.data?.length === 0 && result.data.sheets?.length === 0)
                setScreenState(states.empty);
            else
                setScreenState(states.normal);
        }
    };

    function checkEmptyQuestions({mcq, ids, sheet}) {
        const mcqLength = mcq.filter(it => it.answer).length;
        const textLength = sheet.filter(it => it.answer).length;

        const removeCommon = (prev, cur) => {
            for (const sheetItem of sheet) {
                if (sheetItem.answer)
                    return prev + 1;
            }
            return prev;
        }
        const sub = ids.reduce(removeCommon, 0);

        const idsLength = ids.length - sub;
        const fullAnsweredLength = mcqLength + textLength + idsLength;
        const questionsLength = mcqQuestions.length + sheetQuestion.length;
        return fullAnsweredLength < questionsLength;
    }

    const SendRequest = async (mcq, sheet, ids, skipEmptyCheck) => {
        if (!skipEmptyCheck && checkEmptyQuestions({mcq, ids, sheet})) {
            console.log("true")
            toast.error("Please answer all questions first.");
            return;
        }

        let formdata = new FormData();

        formdata.append("id", params.id);
        formdata.append("mcq", mcq.length);
        formdata.append("sheet", sheet.length);

        console.log({mcq, sheet, ids});

        for (let i = 0; i < mcq.length; i++) {
            formdata.append(`question_${i}`, JSON.stringify(mcq[i]));
        }

        for (let i = 0; i < sheet.length; i++) {
            formdata.append(`sheets_${i}`, JSON.stringify(sheet[i]));
        }
        for (let i = 0; i < ids.length; i++) {
            formdata.append(Object.keys(ids[i]), Object.values(ids[i])[0]);
        }


        let result = await axiosInstance
            .post(`/mark_azhar_${type}`, formdata)
            .catch((err) => {
                message.error("error");
                setSendLoading(false);
            });

        if (result) {
            skipEmptyCheck
                ? toast.warn("Time out! Your answers has been automatically submitted.")
                : toast.success("Your answers has been submitted successfully.")

            setSendLoading(false);
            setScreenState(states.success);
        }
    };

    useEffect(() => {
        if (screenState === states.success || screenState === states.loading)
            getQuestions();
    }, [screenState]);

    switch (screenState) {
        case states.loading:
            return <LoadingPage/>;
        case states.error:
            return <Result
                status="error"
                title={"Access Failed"}
                subTitle={displayError}
            />
        case states.previous:
            return <PreviousQuestions modelAnswers={modelAnswers} type={type}/>
        case states.empty:
            return <div>
                <Empty/>
            </div>
        default:
            return (
                <AzharQue mcqQuestions={mcqQuestions} sheetQuestion={sheetQuestion}
                          selectedChoices={selectedChoices} setSelectedChoices={setSelectedChoices}
                          loading={false} sendLoading={sendLoading} onSubmit={SendRequest}
                          counterData={counterData} onTimeOut={onsubmit}
                />
            );
    }


}

export default ErrorHandler(OnGoingAzharQuestions, axiosInstance);
