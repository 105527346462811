import React from 'react'
import styles from './ScratchCard.module.css'
import ScratchCardBack from './ScratchCardBack'
import ScratchCardFront from './ScratchCardFront'

export default function ScratchCard({code}) {
    return (
        <ScratchCardFront code={code} />
    )
}
