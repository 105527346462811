import {toast} from "react-toastify";

export default function toastErrors(errors) {
    console.log({errors});
    if (typeof errors === 'object') {
        if (Object.values(errors).length > 20) {
            toast.error("Something went wrong on our side.")
            return;
        }
        for (const error in errors) {
            if (Array.isArray(errors[error]))
                toast.error(`${error}: ${errors[error][0]}`);
            else
                toast.error(`${error}: ${errors[error]}`);
        }
    } else {
        if (typeof errors === "string" && errors.length > 20)
            toast.error(errors.slice(0, 20) + "...");
        else
            toast.error(errors);
    }

}