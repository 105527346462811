import {useSelector} from "react-redux";

const {useEffect} = require("react");


export default function useScreenShotPreventer() {
    var noPrint = true;
    var noCopy = true;
    var noScreenshot = true;
    var autoBlur = true;

    const listeners = [
        {
            type: "keyup",
            callback: (e) => {
                if (e.key == "PrintScreen") {
                    document.body.style.cssText = "opacity:0";

                    // "-webkit-filter: blur(100%);-moz-filter: blur(5px);-ms-filter: blur(5px);-o-filter: blur(5px);filter: blur(5px);";
                    navigator.clipboard.writeText("");
                    window.clipboardData.clearData();

                    e.cancelBubble = true;
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            }
        },
        {
            type: "keydown",
            callback: (e) => {
                if (e.key == "PrintScreen") {
                    document.body.style.cssText = "opacity:0";

                    // "-webkit-filter: blur(100%);-moz-filter: blur(5px);-ms-filter: blur(5px);-o-filter: blur(5px);filter: blur(5px);";
                    navigator.clipboard.writeText("");
                    window.clipboardData.clearData();

                    e.cancelBubble = true;
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            }
        },
        {
            type: "keydown",
            callback: (e) => {
                if (e.ctrlKey && e.key == "p") {
                    e.cancelBubble = true;
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            }
        },
        {
            type: "keydown",
            callback: (e) => {
                if (e.ctrlKey && e.key == "PrintScreen") {
                    e.cancelBubble = true;
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            }
        },
        {
            type: "keydown",
            callback: (e) => {
                if (e.ctrlKey) {
                    e.cancelBubble = true;
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            }
        },
        {
            type: "keydown",
            callback: (e) => {
                if (e.key == "PrintScreen") {
                    e.cancelBubble = true;
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            }
        },
        {
            type: "keydown",
            callback: (e) => {
                if (e.key === "Meta") {

                    navigator.clipboard.writeText("");
                    document.body.style.cssText = "opacity:0";

                    e.cancelBubble = true;
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            }
        },
        {
            type: "keydown",
            callback: (e) => {
                if (e.key === "F12") {
                    navigator.clipboard.writeText("");

                    e.cancelBubble = true;
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
            }
        }
    ]

    const actions = {
        onMousLeave: {
            default: document.onmouseleave,
            custom: function () {
                toBlur();
            },
        },
        onClick: {
            default: document.onclick,
            custom: function () {
                toClear();
            }
        },
        onBlur: {
            default: document.onblur,
            custom: function (event) {
                toBlur();
            }
        },
        onCopy: {
            default: document.body.oncopy,
            custom: function () {
                return false;
            }
        },
        onContextMenu: {
            default: document.body.oncontextmenu,
            custom: function () {
                return false;
            }
        },
        onSelectStart: {
            default: document.body.onselectstart,
            custom: function () {
                return false;
            }
        },
        onDrag: {
            default: document.body.ondrag,
            custom: function () {
                return false;
            }
        },
        onKeyDown: {
            default: document.onkeydown,
            custom: function (event) {
                if (event.ctrlKey == true && event.keyCode == 83) {
                    event.preventDefault();
                }
            }
        }
    }

    function toBlur() {
        if (autoBlur)
            document.body.style.cssText =
                "-webkit-filter: blur(100%);-moz-filter: blur(5px);-ms-filter: blur(5px);-o-filter: blur(5px);filter: blur(5px);";
        // "opacity:0";
    }

    function toClear() {
        document.body.style.cssText =
            "-webkit-filter: blur(0px);-moz-filter: blur(0px);-ms-filter: blur(0px);-o-filter: blur(0px);filter: blur(0px);";
        // "opacity:1";
    }

    const {user_type} = useSelector((state) => state.auth.user);


    useEffect(() => {

        if (user_type != "3") return;


        document.body.oncopy = actions.onCopy.custom;
        document.body.oncontextmenu = actions.onContextMenu.custom
        document.body.onselectstart = document.body.ondrag = actions.onSelectStart.custom;
        document.onkeydown = actions.onKeyDown.custom


        var c = document.createElement("span");
        c.style.display = "none";
        c.style.postion = "absolute";
        c.style.background = "#000";
        var first = document.body.firstChild;
        var wraphtml = document.body.insertBefore(c, first);
        c.setAttribute("width", document.body.scrollWidth);
        c.setAttribute("height", document.body.scrollHeight);
        c.style.display = "block";
        var cssNode3 = document.createElement("style");
        cssNode3.type = "text/css";
        cssNode3.media = "print";
        cssNode3.innerHTML = "body{display:none}";
        document.head.appendChild(cssNode3);


        var cssNode2 = document.createElement("style");
        cssNode2.type = "text/css";
        cssNode2.media = "screen";
        cssNode2.innerHTML =
            "div{-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}";
        document.head.appendChild(cssNode2);
        document.body.style.cssText =
            "-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;";

        document.onmouseleave = actions.onMousLeave.custom
        document.onclick = actions.onClick.custom
        document.onblur = actions.onBlur.custom

        listeners.forEach(({type, callback}) => document.addEventListener(type, callback, true));
        return () => {
            listeners.forEach(({type, callback}) => document.removeEventListener(type, callback, true));

            document.body.oncopy = actions.onCopy.default;
            document.body.oncontextmenu = actions.onContextMenu.default;
            document.body.ondrag = actions.onDrag.default;
            document.onkeydown = actions.onKeyDown.default;
            document.onmouseleave = actions.onMousLeave.default;
            document.onclick = actions.onClick.default;
            document.onblur = actions.onBlur.default;
            document.body.onselectstart = actions.onSelectStart.default;
        }
    }, [])
}