import React from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Form, Input, Button } from "antd";

function UpdateCourse({ setShow, show, hide, getData, id }) {
  const onFinish = async (data) => {
    console.log(data);
    let result = await axiosInstance.put(`/CourseAdmin/${id}`, data).catch((err) => {
      console.log(err.response);
    });
    if (result.data) {
      console.log(result);
      setShow(false);
      getData();
    }
  };
  return (
    <Modal show={show} onHide={hide}>
      <Container className="p-4">
        <Row>
          <Col className="text-center" sm={12}>
            {" "}
            <h6>Update Course Info</h6>
          </Col>
        </Row>
        <Row>
          <Form
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item label="Title" name="title" layout="vertical">
              <Input className="loginInput" placeholder="Title" type="text" />
            </Form.Item>
            <Form.Item label="Level" name="edu_lvl" layout="vertical">
              <Input
                className="loginInput"
                placeholder="Level"
                type="number"
                max={"3"}
              />
            </Form.Item>
            <Row>
              <Col className="text-center" md={12}>
                <Button htmlType="submit" className="courseBtn1 mx-3">
                  Save Changes
                </Button>
                <Button className="courseBtn1" onClick={hide}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </Container>
    </Modal>
  );
}

export default ErrorHandler(UpdateCourse, axiosInstance);
