import axiosInstance from "../../../api/axios"

async function basePayment(data) {
    try {
        const result = await axiosInstance.post("StudentSession/", data)
        console.log({ result })
        return [result, null]
    }
    catch (error) {
        if (error.response && error.response.status !== 500) {
            const messages = Object.values(error.response.data).map((it, i) => {
                return `${it[0].charAt(0).toUpperCase() +
                    it[0].slice(1)
                    }`
            })
            return [null, messages]
        }
        else return [null, ["Server Error!"]]
    }
}

const paymentMethods = {
    payByWallet: async (sessionId) => {
        const data = { session: sessionId, method: 0 }
        return await basePayment(data)
    },
    payByScratchCard: async (sessionId, code) => {
        const data = { session: sessionId, code, method: 1 }
        return await basePayment(data)
    },
}

export default paymentMethods