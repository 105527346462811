import React from "react";
import axios from "axios";
import {
  Button,
  Form,
  Select,
  Input,
  TimePicker,
  Checkbox,
  Upload,
  message,
  Spin,
  Space,
  Radio
} from "antd";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
const CreateQues = () => {
  const onFinish = (values) => {
    console.log(values);
  };
  return (
    <Container>
      <Form onFinish={onFinish} layout="vertical">
        <Row>
          <Col sm="12" md="6">
            <Form.Item
              label="Question"
              name="question"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm="12" md="6">
            <Form.Item
              label="Points"
              name="points"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.List name="users">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <>
                  <Form.Item
                    {...restField}
                    name={[name, "answer"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing field"
                      }
                    ]}
                    label="Answer"
                  >
                    <Input placeholder="answer" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "is_correct"]}
                    label="Radio"
                  >
                    <Radio.Group>
                      <Radio checked={false}> Correct? </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Button onClick={() => remove(name)}></Button>
                </>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Button htmlType="submit">Submit</Button>
      </Form>
    </Container>
  );
};

export default CreateQues;
