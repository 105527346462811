import React from "react";
import styles from "./QuestionCard.module.css";
import {BASE_URL} from "../../../../../api/axios";
import AnswerState from "./AnswerState";

export default function QuestionCardChoice({choice, state, ...rest}) {
    return (
        <div className={[styles.choice, styles[state]].join(" ")} {...rest}>
            <p>{choice.choice}</p>
            <AnswerState state={state}/>
            {choice.choice_img && <img src={BASE_URL + choice.choice_img}/>}
        </div>
    )
}