
const createHomework = async (axiosInstance,data) => await axiosInstance.post(`/Quiz/`, data);
const createQuiz = async (axiosInstance,data) => await axiosInstance.post("/create_quiz/", data);

export async function create(axiosInstance,data) {
    const formData = new FormData();
    for (const item in data) {
        if (data[item]) formData.append(item, data[item]);
    }

    try {
        const response = data.type === "1"
            ? await createHomework(axiosInstance,formData)
            : await createQuiz(axiosInstance,formData);
        return [response, null];
    } catch (error) {
        return [null, error];
    }

}

export default {create}