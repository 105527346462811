import { useEffect } from "react";

const useGrayBackground = () =>
  useEffect(() => {
    const className = "grayBody";
    document.body.classList.add(className);
    return () => document.body.classList.remove(className);
  }, []);

export default useGrayBackground;
