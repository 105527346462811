import axios from "axios";

// testing
const BASE_URL = "https://chem-back.div-systems.com"
// deployment
// const BASE_URL = "https://9ee1-197-246-52-103.eu.ngrok.io";
// local
// const BASE_URL = "http://192.168.8.131:4444";

const axiosInstance = axios.create({
    baseURL: BASE_URL,
});
export {BASE_URL};
export default axiosInstance;
