import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import axiosInstance from "../../api/axios";
import img from "../../images/courseImg.png";
import { Pagination, Select } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { useParams, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import SubLink from "../Link";
function Permissions() {
  const params = useParams();

  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [selectedPermission, setSelectedPermissions] = useState([]);
  const [nextData, setNextData] = useState([]);
  const [loadMore, setLoadMore] = useState(true);
  const [userPer, setUserPer] = useState([]);
  const [perCount, setPerCount] = useState(1);
  const [perCurrent, setPerCurrent] = useState(1);
  const [columns, setColumns] = useState([
    {
      name: "Permission",
      selector: "name",
      sortable: true,
      right: true,
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <div className="table-data-feature">
          <React.Fragment>
            <OverlayTrigger
              key={"2"}
              placement={"top"}
              overlay={<Tooltip id={`tooltip-${"top"}`}>Delete Permission</Tooltip>}
            >
              <i
                onClick={removePermission}
                id={row.id}
                className="actionIcon fas fa fa-trash ps-2"
              ></i>
            </OverlayTrigger>
          </React.Fragment>
        </div>
      ),
    },
  ]);
  const getPermissions = async () => {
    let result = await axiosInstance.get(`/permissions?page=1`);
    console.log(result);
    setData(result.data.data);
    setCount(result.data.count);
    setNextData(result.data.next);
  };

  useEffect(() => {
    getPermissions();
    getAssistantPermissions();
  }, []);
  const handlePermissions = (e) => {
    setSelectedPermissions(e);
  };

  const onScrollPer = async (event) => {
    console.log("eee");
    var target = event.target;
    let result;
    if (
      loadMore &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      result = await axiosInstance
        .get(`${nextData}`)
        .catch((err) => console.log(err));

      if (result) {
        console.log(result);
        setData([...data, ...result.data.data]);
        setNextData(result.data.next);
        if (result.data.next === null) {
          setLoadMore(false);
        }
      }
    }
  };

  const getAssistantPermissions = async () => {
    let result = await axiosInstance.get(
      `/assistant_permissions?user_id=${params.id}`
    );
    console.log(result);
    setUserPer(result.data.data);
    setPerCount(result.data.count);
  };

  const addPermission = async () => {
    let data = {
      user_id: params.id,
      permissions: selectedPermission,
    };
    console.log(data);
    let result = await axiosInstance
      .post(`/add_permissions`, data)
      .catch((err) => {
        console.log(err.response);
      });
    console.log(result);
    if (result) {
      getAssistantPermissions();
      setSelectedPermissions([]);
    }
  };

  const removePermission = async (e) => {
    let data = {
      user_id: params.id,
      permissions: [e.target.id],
    };
    console.log(data);
    let result = await axiosInstance
      .post(`/remove_permissions`, data)
      .catch((err) => {
        console.log(err.response);
      });
    console.log(result);
    if (result) {
      getAssistantPermissions();
      setSelectedPermissions([]);
    }
  };

  return (
    <div>
      <div>
        <Container>
          <Row>
            <Col className="py-4" sm={12}>
              {" "}
              <SubLink text="Assistants" />
              <i class="fas fa-chevron-right px-3 title2"></i>
              <span className="title2">User Permissions</span>
            </Col>
          </Row>
          <label>Select Permissions to be added</label>

          <Row>
            <Col className="pb-4 pt-2" sm={6}>
              {" "}
              <Select
                value={selectedPermission}
                onChange={handlePermissions}
                placeholder="Select Permissions"
                onPopupScroll={(e) => onScrollPer(e)}
                mode="multiple"
              >
                {data.map((per) => {
                  return (
                    <Select.Option value={per.id}> {per.name}</Select.Option>
                  );
                })}
              </Select>
            </Col>
            <Col sm="4">
              {" "}
              <Button onClick={addPermission} className="courseBtn1 ">
                Add Permission
              </Button>
            </Col>
          </Row>
          <Row>
            {" "}
            <Col style={{ textAlign: "center" }} sm={12}>
              <DataTable columns={columns} data={userPer} />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ErrorHandler(Permissions, axiosInstance);
