import React, { useEffect, useState } from 'react'
import PaymentOptions from './PaymentOptions'
import styles from "./PaymentPage.module.css"
import { ReactComponent as ChapterIcon } from "./resources/chapter.svg"
import { ReactComponent as SessionIcon } from "./resources/session.svg"
import { ReactComponent as WalletIcon } from "./resources/wallet.svg"
import { ReactComponent as ScratchCardIcon } from "./resources/scratchcard.svg"
import axiosInstance from '../../../api/axios'
import { useParams } from 'react-router-dom'



export default function PaymentCard({ options, selectedOptionIndex, setSelectedOptionIndex, price }) {

    return (
        <>
            <h1>Amount to Pay . {price} EGP</h1>
            <h2>Select Payment Method</h2>
            <PaymentOptions options={options}
                selectedOptionIndex={selectedOptionIndex}
                setSelectedOption={setSelectedOptionIndex} />
            {options[selectedOptionIndex].component}
        </>
    )
}
