import React, { useEffect, useState } from 'react'
import PaymentOptions from './PaymentOptions'
import styles from "./PaymentPage.module.css"
import { ReactComponent as ChapterIcon } from "./resources/chapter.svg"
import { ReactComponent as SessionIcon } from "./resources/session.svg"
import { ReactComponent as WalletIcon } from "./resources/wallet.svg"
import { ReactComponent as ScratchCardIcon } from "./resources/scratchcard.svg"
import { ReactComponent as CreditCardIcon } from "./resources/creditcard.svg"

import axiosInstance from '../../../api/axios'
import { useParams } from 'react-router-dom'
import PaymentCard from './PaymentCard'
import ConfirmCard from './ConfirmCard'
import paymentMethods from './PaymentPageRequests'
import { toast } from 'react-toastify'
import PrimaryButton from "../../common/Button/PrimaryButton";

function HeaderItem({ className, icon, category, title }) {
    return (
        <div className={`${styles.headerItem} ${className}`}>
            <div className={styles.headerItemIcon}>
                {icon}
            </div>
            <div>{`${category} / ${title}`}</div>
        </div>
    )
}

function PayByWallet({ balance, paymentAmount, setConfirming }) {

    return (
        <div className={styles.payByWallet}>
            <h3>Wallet balance . <span>{balance} EGP</span></h3>
            <p>Pay <span>{paymentAmount} EGP</span> Via Your Wallet to Purchase this Session?</p>
            <PrimaryButton className={styles.buttons}
                disabled={balance == "...loading" || paymentAmount > balance}
                onClick={setConfirming}
            >Pay</PrimaryButton>

        </div>
    )
}

function PayByScratchCard({ setConfirming, value, onChange }) {
    return (
        <div className={styles.payByScratchCard}>
            <h2>Enter Your Scratch Card Number</h2>
            <input type="number" placeholder='Type Number Here'
                value={value} onChange={(e) => onChange(e.target.value)}
            />
            <PrimaryButton
                className={styles.buttons}
                disabled={!value}
                onClick={setConfirming}
            >Get it</PrimaryButton>

        </div>
    )
}

export default function PaymentPage({ confirmPaid }) {

    const params = useParams();
    const [walletBalance, setBalance] = useState("...loading")
    const [session, setSession] = useState({})
    const [isConfirming, setConfirming] = useState(false)
    const [payload, setPayload] = useState()
    const options = [
        {
            name: "My Wallet",
            icon: <WalletIcon />,
            confirmationMessage: <>Pay <span>{session.price} EGP</span> From Your Wallet to Purchase this Session?</>,
            paymentMethod: paymentMethods.payByWallet,
            component: <PayByWallet balance={walletBalance}
                paymentAmount={session.price}
                setConfirming={() => setConfirming(true)} />,

        },
        {
            name: "Scratched Card Number",
            icon: <ScratchCardIcon />,
            confirmationMessage: <>Use This Scratch Card Number To Get This Session? <span>{payload}</span></>,
            paymentMethod: paymentMethods.payByScratchCard,
            component: <PayByScratchCard setConfirming={() => setConfirming(true)} value={payload} onChange={(v) => setPayload(v)} />,
        }
    ]

    useEffect(() => {
        fetchBalance()
        fetchSession()
    }, [])

    function fetchBalance() {
        axiosInstance.get("profile").then(balanceRes => {
            console.log({ balanceRes })
            setBalance(balanceRes.data.balance)
        }).catch((err) => console.log({ err }))
    }

    function fetchSession() {
        axiosInstance.get(`preview_session/${params.id}`)
            .then(sessionRes => {
                console.log({ sessionRes })
                setSession(sessionRes.data)
            })
            .catch(err => console.log({ err }))
    }

    async function continuePayment(paymentMethod) {
        const [response, errors] = await paymentMethod(params.id, payload);

        if (response) {
            confirmPaid()
            return;
        }

        errors.forEach(it =>
            setTimeout(() => toast.error(it), 500)
        )
        console.log({ errors })
    }

    const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)

    return (
        <div className={styles.container}>
            <div className={styles.pathHeader}>
                <SessionIcon /> Session <span>/</span> <CreditCardIcon /> {session.title}
                {isConfirming ? <> <span>/</span> Confirmation</> : null}

            </div>

            <div className={styles.formContainer}>
                <header>
                    <div className={`${styles.headerIcon} ${session.course?.edu_lvl ? styles[`year-${session.course.edu_lvl}`] : ""}`} />
                    <div className={styles.headerDivider} />
                    <HeaderItem
                        className={styles.chapterItem}
                        icon={<ChapterIcon />}
                        category={"Chapter"}
                        title={session.course?.title} />
                    <HeaderItem
                        className={styles.sessionItem}
                        icon={<SessionIcon />}
                        category={"Session"}
                        title={session.title} />
                </header>
                <hr />
                {
                    isConfirming ? <ConfirmCard message={options[selectedOptionIndex].confirmationMessage}
                        onBack={() => setConfirming(false)}
                        onConfirm={async () => await continuePayment(options[selectedOptionIndex].paymentMethod)}
                    />
                        : <PaymentCard options={options} selectedOptionIndex={selectedOptionIndex}
                            setSelectedOptionIndex={setSelectedOptionIndex} price={session.price} />

                }
            </div>

        </div>
    )
}
