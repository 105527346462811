import { combineReducers } from "redux";
import authReducer from "./authReducer";
import tokenReducer from "./tokenReducer";
import questionReducer from "./questionReducer"
import isAzharReducer from "./isAzharReducer"
import markedQuizReducer from "./markedQuizReducer"

const allReducers = combineReducers({
  auth: authReducer,
  accessToken: tokenReducer,
  questionType : questionReducer,
  azhar:isAzharReducer,
  quizID:markedQuizReducer
});

export default allReducers;
