import { Link } from "react-router-dom";
import "../../style-sheets/StudentSessionCard.css";
import { BASE_URL } from "../../api/axios";

export default function StudentSessionCard({ slide }) {
  return (
    <Link className="noselect mb-5" draggable="false" to={`/session_details/${slide.id}`}>
      <div className="p-3 mb-5" style={{marginInlineStart: "25px"}}>
        <div
          className="cardd"
          style={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 60.8%, rgba(0, 0, 0, 0.5) 71.42%, #000000 97.07%), ${
              slide.image ? `url(${BASE_URL + slide.image})` : "#0a2a38"
            }`,
          }}
        >
          <div
            className="d-flex align-items-start justify-content-between"
            style={{ width: "100%" }}
          >
            <div className="cardInfoHolder">
              <h1 className="cardTitle"> {slide.title}</h1>
              <p className="cardDesc">{slide.description}</p>
            </div>
            <div>
              <div className="cardPlayBtn">
                <i class="fas fa-play"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );


}