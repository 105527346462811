import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import axiosInstance from "../../api/axios";
import { Empty, Modal, Pagination, Select } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { Collapse } from "antd";
import { ToastContainer, toast } from "react-toastify";

const { Panel } = Collapse;
function StudentsInSession({ show, hide, id }) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [studentsCount, setStudentsCount] = useState("");

  const getStudents = async () => {
    let result = await axiosInstance.get(
      `/students_count?center_id=${id}&page=1`
    );
    if (result) {
      console.log(result);
      setData(result.data.students.data);
      setCount(result.data.students.count);
      setStudentsCount(result.data.student_count);
    }
  };

  useEffect(() => {
    if (show) {
      getStudents();
    }
  }, [show]);
  const onPageChange = async (page) => {
    setCurrent(page);
    let result = await axiosInstance.get(
      `/students_count?center_id=${id}&page=${page}`
    );
    setData(result.data.students.data);
    setCount(result.data.students.count);
    setStudentsCount(result.data.student_count);
  };
  return (
    <Modal visible={show} onCancel={hide} size={"lg"}>
      <div className="p-3">
        <Row>
          <Col className="text-center" sm="12">
            <h5>Students List ({studentsCount} Student)</h5>
          </Col>
        </Row>
        <Row>
          {data.length > 0 ? (
            data.map((info) => {
              return (
                <Col className="" sm="12">
                  <h5>{info.user?.first_name}</h5>
                </Col>
              );
            })
          ) : (
            <Empty />
          )}
        </Row>
        <Row>
          {" "}
          {data.length !== 0 && count >= 10 ? (
            <Pagination
              onChange={onPageChange}
              defaultCurrent={current}
              total={count}
              current={current}
            />
          ) : null}
        </Row>
      </div>
    </Modal>
  );
}

export default ErrorHandler(StudentsInSession, axiosInstance);
