import React from "react";
import styles from "./MarkSheet.module.css";
import {BASE_URL} from "../../../../api/axios";

export default function SheetItem({sheet, value, onChange}) {
    const max = sheet.sheet.mark;
    return (
        <div className={styles.sheetItem}>
            <div className={styles.question}>
                <div>
                    <header className={styles.questionHeader}>
                        Question:
                    </header>
                    {sheet.sheet.question}</div>
                <div>
                    <header>
                        Mark:
                    </header>
                    <div className={styles.mark}>
                        <input type="number" min="0" max={max} onWheel={(e) => e.target.blur()}
                               value={value ?? 0}
                               onChange={(e) => onChange(Math.min(max, +e.target.value))}/>
                        <span>/{sheet.sheet.mark}</span>
                    </div>
                </div>
            </div>
            <div className={styles.answers}>
                <div className={styles.answer}>
                    <header>Model Answer:</header>
                    {sheet.sheet.answer}
                </div>
                <div className={[styles.answer, styles.studentAnswer].join(" ")}>
                    <header>
                        Student's Answer:
                    </header>
                    {sheet.answer}
                </div>
            </div>

            {
                sheet.answer_file && <div className={styles.imageContainer}>
                    <header> Extra Files:</header>
                    {/* eslint-disable-next-line no-undef */}
                    <img src={BASE_URL + sheet.answer_file}/>

                </div>
            }
        </div>
    )
}