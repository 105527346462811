import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/loginStyle.css";
import { Form, Input, Button, Select, Checkbox } from "antd";
import { ToastContainer, toast } from "react-toastify";

// import { showNav } from "../global-state/actions/showNavAction";
import { useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { Tabs } from "antd";
import { govs } from "../../../data-objects/governorates";
import { BASE_URL } from "../../../api/axios";
import axios from "axios";
import logo from "../../../images/logo.png";

const { TabPane } = Tabs;
const Registeration = () => {
  const [attendance_place, setPlace] = useState("");
  const [discoverySource, setSource] = useState("");
  const [centers, setCenters] = useState([]);

  let navigate = useNavigate();

  const onFinish = async (values) => {
    console.log(values);
    let result;
    result = await axios
      .post(BASE_URL + `/signup_student`, values)
      .catch((err) => {
        console.log(err.response);
        if (err.response.status !== 500) {
          for (const error in err.response.data) {
            console.log(err.response.data[error][0]);
            setTimeout(() => {
              toast.error(
                `${
                  error +
                  " : " +
                  err.response.data[error][0].charAt(0).toUpperCase() +
                  err.response.data[error][0].slice(1)
                }`
              );
            }, 500);
          }
        }
      });

    if (result) {
      console.log(result);
      setTimeout(() => {
        toast.success("User Created Successfully");
      }, 500);
      navigate("/");
    }
  };

  const handlePlace = (e) => {
    setPlace(e);
  };
  const getCenter = async () => {
    let result = await axios.get(BASE_URL + `/PublicCenter/`);
    console.log(result);
    setCenters(result.data);
  };

  return (
    <div className="loginPage py-5">
      <Container className="registerContainer">
        <Row>
          <Col sm="3">
            <img src={logo} alt="chem-valley-logo" className="logo img-fluid" />
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            {" "}
            <h1 className=" mt-4">Sign Up</h1>
            <div className="mb-3">
              {" "}
              <h3>Personal Info</h3>
              <div id="dash"></div>
            </div>
          </Col>
        </Row>
        <Form onFinish={onFinish} layout="vertical">
          <Row>
            <Col className="text-left p-4" md={6}>
              <>
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    { required: true, message: "Please input your Name!" },
                    {
                      pattern: /^[a-zA-Z]+$/,
                      message: `only characters are allowed without any spaces`,
                    },
                  ]}
                  layout="vertical"
                >
                  <Input
                    type="text"
                    className="loginInput"
                    placeholder="First Name"
                  />
                </Form.Item>

                <Form.Item
                  label="Middle Name"
                  name="middle_name"
                  rules={[
                    { required: true, message: "Please input your Name!" },
                    {
                      pattern: /^[a-zA-Z]+$/,
                      message: `only characters are allowed without any spaces`,
                    },
                  ]}
                  layout="vertical"
                >
                  <Input
                    type="text"
                    className="loginInput"
                    placeholder="Middle Name"
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input valid Email!",
                      type: "email",
                    },
                  ]}
                  layout="vertical"
                >
                  <Input className="loginInput" placeholder="Email" />
                </Form.Item>

                <Form.Item
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input valid phone!",
                      min: 11,
                      max: 11,
                    },
                  ]}
                >
                  <Input placeholder="Phone" className="loginInput" />
                </Form.Item>
              </>
            </Col>
            <Col className="imgCol1" md={6}>
              <>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last  name!",
                    },
                    {
                      pattern: /^[a-zA-Z]+$/,
                      message: `only characters are allowed without any spaces`,
                    },
                  ]}
                  layout="vertical"
                >
                  <Input
                    type="text"
                    className="loginInput"
                    placeholder="Last Name"
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                      min: 5,
                      max: 15,
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    className="loginInput"
                  />
                </Form.Item>
              </>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <h3 className="">Other Info</h3>
              <div id="dash"></div>
            </Col>
          </Row>
          <Row>
            <Col className="text-left p-4" md={6}>
              <>
                <Form.Item
                  label="Father's Phone"
                  name="father_phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Father's Phone!",
                      min: 11,
                      max: 11,
                    },
                  ]}
                  layout="vertical"
                >
                  <Input className="loginInput" placeholder="Father's Phone" />
                </Form.Item>

                <Form.Item
                  label="School Name"
                  name="school_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your school  name!",
                    },
                  ]}
                  layout="vertical"
                >
                  <Input className="loginInput" placeholder="School Name" />
                </Form.Item>

                <Form.Item
                  label="Select Governorate"
                  name="governate"
                  rules={[
                    {
                      required: true,
                      message: "Please select Governorate!",
                    },
                  ]}
                  layout="vertical"
                >
                  <Select placeholder="Select Governorate">
                    {govs.map((gov) => {
                      return (
                        <Select.Option value={gov.governorate_name_en}>
                          {gov.governorate_name_en} - {gov.governorate_name_ar}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Select Place"
                  name="attendance_place"
                  rules={[
                    {
                      required: true,
                      message: "Please select Place!",
                    },
                  ]}
                  layout="vertical"
                >
                  <Select
                    placeholder="Select Place"
                    value={attendance_place}
                    onChange={handlePlace}
                  >
                    <Select.Option value="1">Center</Select.Option>
                    <Select.Option value="2">Online</Select.Option>
                  </Select>
                </Form.Item>
                {attendance_place == "1" ? (
                  <Form.Item
                    label="Select Center"
                    name="center"
                    layout="vertical"
                  >
                    <Select placeholder="Select Center" onClick={getCenter}>
                      {centers.map((center) => {
                        return (
                          <Select.Option value={center.id} name={center.name}>
                            {center.title}
                          </Select.Option>
                        );
                      })}
                      {/* <Select.Option value="1">Center</Select.Option>
                      <Select.Option value="2">Online</Select.Option> */}
                    </Select>
                  </Form.Item>
                ) : null}
                <Form.Item name="azhar" valuePropName="checked">
                  {/* <Radio.Group onChange={handleAzhar} value={isAzhar}>
                    <Radio value={true}>Azhar?</Radio>
                  </Radio.Group> */}
                  <Checkbox>Azhar?</Checkbox>
                </Form.Item>
              </>
            </Col>
            <Col className="imgCol1" md={6}>
              <></>

              <>
                {" "}
                <Form.Item
                  label="Mother's Phone"
                  name="mother_phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your mom's phone!",
                      min: 11,
                      max: 11,
                    },
                  ]}
                  layout="vertical"
                >
                  <Input className="loginInput" placeholder="Mother's Phone" />
                </Form.Item>
                <Form.Item
                  label="Select Gender"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Please select Gender!",
                    },
                  ]}
                  layout="vertical"
                >
                  <Select placeholder="Select Gender">
                    <Select.Option value="1">Male</Select.Option>
                    <Select.Option value="2">Female</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Select Level"
                  name="edu_lvl"
                  rules={[
                    {
                      required: true,
                      message: "Please select Level!",
                    },
                  ]}
                  layout="vertical"
                >
                  <Select placeholder="Select Level">
                    <Select.Option value="1">First Grade</Select.Option>
                    <Select.Option value="2">Second Grade</Select.Option>
                    <Select.Option value="3">Third Grade</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="How Did You Discover ChemValley?"
                  name="discover"
                  rules={[
                    {
                      required: true,
                      message: "Please select how did you discover ChemVally!",
                    },
                  ]}
                  layout="vertical"
                >
                  <Select
                    placeholder="Select Source"
                    value={discoverySource}
                    onChange={setSource}
                  >
                    <Select.Option value="1">Friends and Family</Select.Option>
                    <Select.Option value="2">
                      Center Recommendation
                    </Select.Option>
                    <Select.Option value="3">Facebook</Select.Option>
                    <Select.Option value="4">Youtube</Select.Option>
                    <Select.Option value="5">Instagram</Select.Option>
                    <Select.Option value="6">Other</Select.Option>
                  </Select>
                </Form.Item>
                {discoverySource === "6" ? (
                  <Form.Item
                    label="Other"
                    name="other"
                    rules={[
                      {
                        required: true,
                        message: "Please input discovery source!",
                      },
                    ]}
                    layout="vertical"
                  >
                    <Input
                      type="text"
                      className="loginInput"
                      placeholder="Other"
                    />
                  </Form.Item>
                ) : null}
                <Form.Item>
                  <Button
                    className="loginBtn1"
                    //   onClick={onLogin}
                    type="primary"
                    htmlType="submit"
                  >
                    Sign Up{" "}
                  </Button>
                </Form.Item>
              </>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <p className="p1">
                Already Have An Account ?{" "}
                <a href="/" className="span1">
                  Sign In
                </a>
              </p>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};
export default Registeration;
