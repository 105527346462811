import React from "react";
import styles from "./Button.module.css";

export default function SecondaryButton({className, ...rest}) {
    return (
        <button
            className={[styles.secondaryButton, "noselect", className].join(" ")}
            {...rest}
        />
    )
}