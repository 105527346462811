import React, {useEffect, useState} from "react";
import styles from "./ReviewModel.module.css";
import {Modal, Spin} from "antd";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import {useSelector} from "react-redux";

function ReviewModel({reviewedChapter, onCancel}) {

    const [review, setReview] = useState()
    const [loading, setLoading] = useState(true);

    async function getReview() {
        try {
            setLoading(true)
            const result = await axiosInstance.get(`review_course/${reviewedChapter}`);
            if (result.data)
                setReview(result.data.data[0]?.review)
        } catch (error) {
            console.log({error})
        } finally {
            setLoading(false)
        }
    }

    async function writeReview(e) {
        e.preventDefault();
        setLoading(true);
        const review = e.target.review.value;
        await axiosInstance.post(`review_course/`, {
            course: reviewedChapter,
            review,
        }).catch(error => console.log(error));
        await getReview();
        setLoading(false)
    }

    useEffect(() => {
        setReview("")
        if (!reviewedChapter) return;
        getReview()
    }, [reviewedChapter])

    return (
        <Modal visible={reviewedChapter} onCancel={onCancel} size={"lg"}>
            <form className={styles.container} onSubmit={writeReview}>
                <h4>Review Chapter</h4>
                <textarea name="review" value={review} disabled={review}/>
                {
                    !loading && (review && review.length > 0) ? undefined :
                        <button className="courseBtn1">{loading ? <Spin/> : "Review"}</button>
                }
            </form>
        </Modal>
    )
}

export default ErrorHandler(ReviewModel, axiosInstance);
