import { MARK_QUIZ } from "../actions/actionTypes";
const initialState = {
  quizID : ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MARK_QUIZ:
      return {
        ...state,
        quizID : action.payload
      };

    
    default:
      return state;
  }
}