import React from "react";
import styles from "./OnGoingAzharQuestions.module.css";
import {Container} from "react-bootstrap";
import QuestionCard from "../../Common/QuestionCard";
import {Form, Input, Upload} from "antd";
import SecondaryButton from "../../../../common/Button/SecondaryButton";
import {UploadOutlined} from "@ant-design/icons";
import PrimaryButton from "../../../../common/Button/PrimaryButton";
import {Player} from "video-react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import DurationCounter from "../../Common/DurationCounter";


export default function AzharQue({
                                     mcqQuestions, sheetQuestion,
                                     selectedChoices, setSelectedChoices,
                                     loading, sendLoading,
                                     onSubmit, onTimeOut, counterData,
                                 }) {
    const {TextArea} = Input;
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const onFinish = async (values, skipEmptyCheck = false) => {

        const mcq = mcqQuestions.map(({id}, i) => ({question: id, answer: selectedChoices[i]}));
        let sheet = [];
        let ids = [];

        Object.entries(values).forEach((key, index, arr, va) => {
            for (const [keyy, value] of Object.entries(values)) {
                if (key[0] === keyy + "T") {
                    if (key[1]) {
                        sheet.push({
                            sheet: keyy,
                            answer: value === undefined ? null : value,
                        });

                        ids.push({
                            [keyy]: key[1].file,
                        });
                    } else {
                        sheet.push({
                            sheet: keyy,
                            answer: value === undefined ? null : value,
                        });
                    }
                }
            }
        });

        await onSubmit(mcq, sheet, ids, skipEmptyCheck);
    };

    function onSelectChoice(choice, i) {
        const newChoices = {...selectedChoices, [i]: choice};
        setSelectedChoices(newChoices);
        console.log({newChoices})
    }

    return (
        <div className={styles.container}>
            {" "}

            <Container className={styles.content}>
                <h4 style={{color: "#001731"}}>
                    <i
                        onClick={() => navigate(-1)}
                        className="fas fa-chevron-left pe-4 pb-4"
                    ></i>
                    View Questions
                </h4>
                <section className={styles.questionsSection}>
                    <header className={styles.sectionTitle}>MCQ Questions</header>
                    {
                        mcqQuestions.map((it, i) =>
                            <div className={styles.questionContainer}>
                                <header className={styles.questionHeader}>Question {i + 1}</header>
                                <QuestionCard className={styles.questionCard} question={it}
                                              selectedChoice={selectedChoices[i]}
                                              onSelect={choice => onSelectChoice(choice, i)}/>
                            </div>
                        )
                    }
                </section>
                <section>
                    <header className={styles.sectionTitle}>Sheet Questions</header>

                    <Form onFinish={(v) => onFinish(v)} form={form}>
                        {sheetQuestion &&
                            sheetQuestion.map((question, i) => (
                                <div>
                                    <header className={styles.questionHeader}>Question {i + 1}</header>

                                    <div className="pb-3">
                                        <p className={styles.sheetHeader}>{question.question}</p>
                                        <Form.Item name={question.id}>
                                            <TextArea
                                                rows={4}
                                                placeholder="Enter your answer here"
                                                className={styles.sheetText}
                                            />
                                        </Form.Item>
                                        <Form.Item name={question.id + "T"}>
                                            <Upload
                                                beforeUpload={(e) => {
                                                    return false;
                                                }}
                                            >
                                                <SecondaryButton
                                                    className={styles.imageButton}
                                                    icon={<UploadOutlined/>} onClick={e => e.preventDefault()}>
                                                    Or click to upload answer
                                                </SecondaryButton>
                                            </Upload>
                                        </Form.Item>
                                    </div>
                                </div>
                            ))}
                        <div className="text-end pt-4 pb-4">
                            <PrimaryButton disabled={sendLoading}>
                                {" "}
                                {sendLoading ? "loading..." : "Submit"}
                            </PrimaryButton>
                        </div>
                    </Form>
                </section>
            </Container>
            <DurationCounter
                className={styles.counter}
                startTimeInSec={counterData.startTimeInSec}
                durationInSec={counterData.durationInSec}
                onTimeOut={() => onFinish(form.getFieldsValue(), true)}
            />
        </div>
    );
}