import Slider from "react-slick";
import StudentSessionCard from "../Layouts/StudentSessionCard";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import React, {useEffect, useState} from "react";
import "../../style-sheets/StudentViewAllSession.css";
import {Spin, Empty} from "antd";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Review from "./Review";

function StudentViewAllSession() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reviewedChapter, setReviewedChapter] = useState();

    function SampleNextArrow(props) {
        const {className, onClick} = props;
        return (
            <div className={className} onClick={onClick}>
                <i class="fas fa-arrow-right"></i>
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const {className, onClick} = props;
        return (
            <div className={className} onClick={onClick}>
                <i class="fas fa-arrow-left"></i>
            </div>
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoSlidesToShow: true,
        variableWidth: true,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>
    };

    const getSession = async () => {
        let result = await axiosInstance.get(`/StudentCourseSessionView`);
        console.log(result);
        if (result) {
            setData(result.data);
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        getSession();
    }, []);

    return (
        <main>
            <Review reviewedChapter={reviewedChapter} onCancel={() => setReviewedChapter(undefined)}/>
            <h1 className="pageTitle ms-5">Sessions</h1>
            {loading ? (
                <Spin tip="loading..."/>
            ) : !loading && data.length == 0 ? (
                <Empty/>
            ) : (
                data.map((sliders) => (
                    <section>
                        <div
                            className={`chapter-header ${sliders.sessioncourse.length == 0 ? "chapter-header-empty" : ""}`}
                        >
                            <h2 className="chapterTitle ms-5 mt-4">{sliders.title}</h2>
                            <OverlayTrigger
                                key={"2"}
                                placement={"top"}
                                overlay={<Tooltip id={`tooltip-review`}>Write a Review</Tooltip>}
                            >
                                <button onClick={() => setReviewedChapter(sliders.id)}><i
                                    className="fas fa-comment-medical"></i></button>
                            </OverlayTrigger>
                        </div>
                        {
                            <Slider {...settings}>
                                {sliders.sessioncourse.length > 0 ? sliders.sessioncourse.map((slide, i) => (
                                    <div key={i}>
                                        <StudentSessionCard slide={slide}/>
                                    </div>
                                )) : <Empty style={{width: "100%"}}/>}
                            </Slider>
                        }
                    </section>
                ))
            )}
        </main>
    );
}

export default ErrorHandler(StudentViewAllSession, axiosInstance);
