import React from "react";
import styles from "./QuestionCard.module.css";
import {BASE_URL} from "../../../../../api/axios";
import QuestionCardChoice from "./QuestionCardChoice";

export default function QuestionCard({question, selectedChoice, onSelect, className}) {
    return (
        <div className={[styles.questionCard, className].join(" ")}>
            <header className={styles.questionHeader}>
                <p>{question.question}</p>
                {question.question_img && <img src={BASE_URL + question.question_img}/>}
            </header>
            <div className={styles.choicesList}>
                {
                    question.choicequestion.map(choice =>
                        <QuestionCardChoice
                            key={choice.id} choice={choice}
                            onClick={() => onSelect(choice.id)}
                            state={
                                selectedChoice == choice.id ? "selected" : null
                            }
                        />)
                }
            </div>
        </div>
    )
}