import styles from "./Home.module.css";
import React, {useState, useEffect, useRef} from "react";
import {Row, Col} from "react-bootstrap";
import axiosInstance, {BASE_URL} from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import {useSelector} from "react-redux";
import bgimage from "../../../images/watch.png";
import NotificationBlock from "../../Layouts/NotificationBlock";
import {Link} from "react-router-dom";
import Notifications from "../Notifications";
import {Tooltip} from "antd";
import HomeContinueWatching from "./HomeContinueWatching";
import useGrayBackground from "../../../hooks/use-gray-background";

function Home() {
    const [data, setDate] = useState([]);
    const user = useSelector((state) => state.auth.user);

    useEffect(() => getDetails(), []);
    useGrayBackground();

    async function getDetails() {
        let result = await axiosInstance.get(`/StudentSession/`).catch((err) => {
            console.log(err.response);
        });
        console.log(result.data);
        setDate(result.data);
    };

    return (
        <>
            <Tooltip>
                <div className="whatsappIcon">
                    <a
                        href="https://wa.me/+201113599978"
                        class="whatsapp_float"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i class="fab fa-whatsapp whatsapp-icon"/>
                    </a>
                </div>
            </Tooltip>
            <div className={`${styles.container}`}>
                <HomeContinueWatching
                    user={user}
                    videos={data}
                />
            </div>
            <Notifications className={styles.notification}/>
        </>
    );
}

export default ErrorHandler(Home, axiosInstance);
