import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axiosInstance, { BASE_URL } from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { useParams } from "react-router-dom";
import { Select, Empty, Skeleton, Input, Form, Button, Checkbox } from "antd";
import ViewComments from "../Layouts/ViewComments";
import { useSelector } from "react-redux";
import SubscribedSessionModal from "../modals/SubscribedSessionModal";
import AddComentModal from "../modals/AddCommentModal";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
function StudentDetails() {
  const [form] = Form.useForm();
  const params = useParams();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [isActive, setActive] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [viewComments, setViewComments] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [centers, setCenters] = useState([]);
  const [center, setCenter] = useState();
  const viewCommentsModel = (e) => {
    setViewComments(true);
    setShowComment(false);
  };
  const getStudentDetails = async () => {
    let result;

    result = await axiosInstance.get(`/StudentProfile/${params.id}`);
    if (result) {
      setData(result.data);
      console.log({ result });
      form.setFieldsValue({
        first_name: result.data?.user?.first_name,
        last_name: result.data?.user?.last_name,
        middle_name: result.data?.user?.middle_name,
        balance: result.data?.balance,
        email: result.data?.user?.email,
        phone: result.data?.user?.phone,
        school_name: result.data?.school_name,
        gender: result.data?.gender,
        governate: result.data?.governate,
        mother_phone: result.data?.mother_phone,
        father_phone: result.data.father_phone,
        status: result.data?.status,
        special_student: result.data?.special_student,
        center: result.data?.center?.id,
        azhar: result.data?.azhar,
        attendance_place: result.data?.attendance_place
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getStudentDetails();
    getCenters();
  }, []);

  const activate = async (e) => {
    console.log({ e });

    let result = await axiosInstance
      .put(`/User/${data.user.id}`, {
        is_active: e
      })
      .catch((err) => {
        console.log(err.response);
      });
    if (result) {
      getStudentDetails();
    }
  };
  const openModel = () => {
    setShow(true);
  };
  const close = () => {
    setShow(false);
  };
  const openEdit = () => {
    setShowEdit(true);
  };
  const hideEdit = () => {
    setShowEdit(false);
  };

  const updated = () => {
    getStudentDetails();
  };
  const openCommentModel = () => {
    setShowComment(true);
  };
  const closeCommentModel = () => {
    setShowComment(false);
  };

  const hideAllComments = () => {
    setViewComments(false);
  };

  const getCenters = async () => {
    let result = await axios.get(BASE_URL + `/PublicCenter/`);
    console.log(result);
    setCenters(result.data);
  };

  const updateStudent = async (values) => {
    // console.log(values);
    // let basicInfo = {
    //   first_name: values.first_name,
    //   last_name: values.last_name,
    //   middle_name: values.middle_name,
    //   email: values.email,
    //   phone: values.phone
    // };

    // if (values.email === data.user.email && values.phone === data.user.phone)
    //   return;

    console.log({ values });

    let result = await axiosInstance
      .put(`/StudentProfile/${params.id}`, values)
      .catch((err) => console.log(err.response));
    if (result) {
      console.log(result);
      toast.info("Student's Profile Updated!");
    }
  };
  return (
    <div className="detailsPage">
      <div className="">
        {loading ? (
          <Skeleton active />
        ) : (
          <div className="px-5">
            <p className="title1">Profile</p>

            <Row>
              <Col sm="12">
                <div className="d-flex align-items-center">
                  <Button className="codeBtn me-2">#{data.code} </Button>
                  <div
                    style={{
                      backgroundColor: `${
                        data.status === "1"
                          ? "#18AB56"
                          : data.status === "2"
                          ? "#FFDD2B"
                          : "#EB5757"
                      }`
                    }}
                    className="status"
                  ></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <p className="studentName"> {data.user?.first_name}</p>
                <p className="studentName">
                  {data.user?.middle_name} {data.user?.last_name}
                </p>
                <div className="mt-2">
                  <Button
                    className={`eduLevel ${
                      data.edu_lvl == "1"
                        ? "firstBtn"
                        : data.edu_lvl == "2"
                        ? "secBtn"
                        : "thirdBtn"
                    }`}
                  >
                    {data.edu_lvl === "1" ? (
                      <p className="pStyle">
                        1<sup>st</sup> sec
                      </p>
                    ) : data.edu_lvl === "2" ? (
                      <p className="pStyle">
                        2<sup>nd</sup> sec
                      </p>
                    ) : (
                      <p className="pStyle">
                        3<sup>rd</sup> sec
                      </p>
                    )}
                  </Button>
                  {data.attendance_place === "1" ? (
                    <Button
                      style={{
                        border: `solid 2px #f28537`,
                        color: `#FFB37B`
                      }}
                      className="centerBtn"
                    >
                      Center
                    </Button>
                  ) : (
                    <Button
                      style={{
                        border: `solid 2px #0058dc`,
                        color: `#94BFFF`
                      }}
                      className="centerBtn mx-2"
                    >
                      Online
                    </Button>
                  )}
                  <Button
                    style={{
                      border: `${
                        data.azhar ? "solid 2px #b467d9" : "solid 2px #89cc88"
                      }`,
                      color: `${data.azhar ? "#C88BE6" : "#738F4F"}`,
                      width: "unset"
                    }}
                    className="centerBtn"
                  >
                    {data.azhar ? "Azhar" : "General"}
                  </Button>
                </div>
              </Col>
            </Row>
            <div className="commentsDiv">
              <p onClick={openCommentModel}>Comments</p>
              <p onClick={openModel}>Sessions</p>
              <p style={{ marginBottom: "0rem" }}>
                <Link to={`/student_report/${params.id}`}>Report</Link>
              </p>
            </div>
            <Form form={form} onFinish={updateStudent}>
              <Row className="mt-5 mb-3">
                {" "}
                <p className="personalInfoTitle">System info</p>
              </Row>
              <Row>
                <Col sm="12" md="3">
                  <label className="profileLabel">Account Status</label>

                  <Form.Item name="status">
                    <Select>
                      <Select.Option value="1">Accepted</Select.Option>
                      <Select.Option value="2">Pending</Select.Option>
                      <Select.Option value="3">Rejected</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm="12" md="3">
                  <label className="profileLabel">Attendance</label>

                  <Form.Item name="attendance_place">
                    <Select>
                      <Select.Option value="1">Center</Select.Option>
                      <Select.Option value="2">Online</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm="12" md="3">
                  <label className="profileLabel">Payment</label>

                  <Form.Item name="special_student">
                    <Select>
                      <Select.Option value="1" id="1">
                        Default
                      </Select.Option>
                      <Select.Option value="3">Half</Select.Option>
                      <Select.Option value="2">Free</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm="12" md="3">
                  <label className="profileLabel">Balance</label>
                  <Form.Item name="balance">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-5 mb-3">
                {" "}
                <p className="personalInfoTitle">Personal info</p>
              </Row>

              <Row>
                <Col sm="12" md="3">
                  <label className="profileLabel">First Name</label>
                  <Form.Item name="first_name">
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm="12" md="3">
                  <label className="profileLabel">Middle Name</label>
                  <Form.Item name="middle_name">
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm="12" md="3">
                  <label className="profileLabel">Last Name</label>
                  <Form.Item name="last_name">
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm="12" md="3">
                  <label className="profileLabel">Gender</label>
                  <Form.Item name="gender">
                    <Select>
                      <Select.Option value="1">Male</Select.Option>
                      <Select.Option value="2">Female</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="3">
                  <label className="profileLabel">Email</label>

                  <Form.Item name="email">
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm="12" md="3">
                  <label className="profileLabel">Phone Number</label>
                  <Form.Item name="phone">
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm="12" md="3">
                  <label className="profileLabel">School</label>
                  <Form.Item name="school_name">
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm="12" md="3">
                  <label className="profileLabel">Governorate</label>
                  <Form.Item name="governate">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col sm="12" md="3">
                  <Form.Item name="azhar" valuePropName="checked">
                    <Checkbox>Azhar?</Checkbox>
                  </Form.Item>
                </Col>
                {data?.attendance_place == "1" && (
                  <Col sm="12" md="3">
                    <label className="profileLabel">Center</label>
                    <Form.Item name="center">
                      <Select value={center}>
                        {centers.map((it) => (
                          <option key={it.id} value={it.id}>
                            {it.title}
                          </option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Row className="mt-5">
                <Col sm="6">
                  {" "}
                  <p className="personalInfoTitle">Parents info</p>
                  <Row className="mt-1">
                    <Col sm="12" md="4">
                      <label className="profileLabel">
                        Mother’s Phone Number
                      </label>
                      <Form.Item name="mother_phone">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col sm="12" md="4">
                      <label className="profileLabel">
                        Father’s Phone Number
                      </label>
                      <Form.Item name="father_phone">
                        <Input />
                      </Form.Item>
                    </Col>{" "}
                  </Row>
                </Col>
              </Row>
              <Row className="my-4 detailsRow p-4">
                <Col sm="12" md="6">
                  <Button
                    className="tableBtn mt-2"
                    style={{ background: "transparent", color: "#FF6A6A" }}
                    onClick={() =>
                      activate(data.user?.is_active ? false : true)
                    }
                  >
                    {data.user?.is_active
                      ? " Deactivate account"
                      : " Activate account"}{" "}
                  </Button>
                </Col>
                <Col className="text-end" sm="12" md="6">
                  <Button
                    onClick={() => getStudentDetails()}
                    className="resetChanges me-3"
                  >
                    Reset changes
                  </Button>
                  <Button htmlType="submit" className="applyChanges">
                    Apply changes
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
      <SubscribedSessionModal show={show} hide={close} data={data} />
      <ViewComments
        update={showComment}
        show={viewComments}
        id={params.id}
        hide={hideAllComments}
        setViewComments={setViewComments}
      />

      <AddComentModal
        show={showComment}
        hide={closeCommentModel}
        id={params.id}
        setViewComments={viewCommentsModel}
      />
    </div>
  );
}

export default ErrorHandler(StudentDetails, axiosInstance);
