import React, { useEffect, useState } from "react";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import axiosInstance, { BASE_URL } from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Pagination, Spin } from "antd";
import { useSelector } from "react-redux";
import DeleteModal from "../modals/deleteModal";
import SubLink from "../Link";

function ViewMaterial() {
  const params = useParams();
  const [show, setShow] = useState("");
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [materialID, setMaterialID] = useState("");
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const [columns, setColumns] = useState([
    {
      name: "Material",
      cell: (row) => (
        <div>
          <a href={BASE_URL + row.material}>{BASE_URL + row.material}</a>
        </div>
      ),
      sortable: true,
      right: true
    },
    {
      name: "Actions",
      width: "150px",
      button: true,
      cell: (row) => (
        <div className="table-data-feature">
          {user.user_type == "3" ? null : (
            <OverlayTrigger
              key={"2"}
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-${"top"}`}>Delete Material</Tooltip>
              }
            >
              <i
                onClick={showDelete}
                id={row.id}
                className="actionIcon fas fa fa-trash ps-2"
              ></i>
            </OverlayTrigger>
          )}
        </div>
      )
    }
  ]);
  const getMaterials = async () => {
    let result = await axiosInstance.get(
      `/material_session/${params.id}?page=1`
    );
    console.log(result);
    if (result) {
      setData(result.data.data);
      setCount(result.data.count);
      setLoading(false);
    }
  };
  const onPageChange = async (page) => {
    setCurrent(page);
    let result = await axiosInstance.get(
      `/material_session/${params.id}?page=${page}`
    );
    setData(result.data.data);
    setCount(result.data.count);
  };
  useEffect(() => {
    setLoading(true);

    getMaterials();
  }, []);
  const close = () => {
    setShow(false);
  };
  const showDelete = (e) => {
    setMaterialID(e.target.id);
    setShow(true);
  };
  return (
    <div className="">
      <Container>
        <Row>
          <Col className="py-4" sm={12}>
            {" "}
            <SubLink text="Session" />
            <i class="fas fa-chevron-right px-3 title2"></i>
            <span className="title2">View Materials</span>
          </Col>
        </Row>
        {loading ? (
          <Spin tip="...loading" />
        ) : (
          <>
            {" "}
            <Row>
              <Col style={{ textAlign: "center" }} sm={12}>
                <DataTable columns={columns} data={data} />
              </Col>
            </Row>
            <Row className="p-4">
              {data.length !== 0 ? (
                <Pagination
                  onChange={onPageChange}
                  defaultCurrent={current}
                  total={count}
                  current={current}
                />
              ) : null}
            </Row>
          </>
        )}

        <DeleteModal
          text={"Are u sure u want to delete this Material?"}
          show={show}
          setShow={setShow}
          hide={close}
          link={`/Material/${materialID}`}
          getData={getMaterials}
        />
      </Container>
    </div>
  );
}

export default ErrorHandler(ViewMaterial, axiosInstance);
