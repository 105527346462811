export function getStartSeconds(startDateTimeStamp) {
    const timeZonedStart = `${startDateTimeStamp}+00:00`
    const startDate = new Date(timeZonedStart);
    const currentDate = new Date();
    const diffMillis = Math.abs(currentDate.getTime() - startDate.getTime());
    return Math.round(diffMillis / 1000);
}

export function parseCounterData(StudentData, type) {
    const durationInSec = StudentData[type].duration_sec;
    const startDate = StudentData.start_date;
    const startTimeInSec = startDate ? getStartSeconds(startDate) : 0;
    const data = {startTimeInSec, durationInSec}
    return data;
}
