import React, {useEffect, useState} from "react";
import {Container, Row, Col, OverlayTrigger, Tooltip} from "react-bootstrap";
import axiosInstance from "../../../api/axios";
import {Pagination, Spin} from "antd";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import "../../../style-sheets/session.css";
import {useParams, Link} from "react-router-dom";
import DataTable from "react-data-table-component";
import {questionType} from "../../../global-state/actions/questionAction";
import {useDispatch} from "react-redux";
import DeleteModal from "../../modals/deleteModal";
import {isAzharAction} from "../../../global-state/actions/isAzharAction";
import {useSelector} from "react-redux";
import SubLink from "../../Link";
import OpenExamManually from "./OpenExamManually";

function ViewExams() {
    const azharDispatch = useDispatch();
    const params = useParams();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const [showOpenExamManually, setShowOpenExamManually] = useState(false);
    const [columns, setColumns] = useState([
        {
            name: "Exam Name",
            selector: (row) => row["title"],
            sortable: true,
            right: true
        },
        {
            name: "Start Date",
            selector: (row) => row["start_date"],
            sortable: true,
            right: true
        },
        {
            name: "End Date",
            selector: (row) => row["end_date"],
            sortable: true,
            right: true
        },
        {
            name: "Azhar",
            selector: (row) => (row.azhar ? "true" : "false"),
            sortable: true,
            right: true
        },
        {
            name: "Actions",
            width: "170px",
            button: true,
            cell: (row) => (
                <div className="table-data-feature">
                    {user.user_type == "3" ? (
                        <React.Fragment>
                            <OverlayTrigger
                                key={"1"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>View Questions</Tooltip>
                                }
                            >
                                <Link to={`/questions/${row.id}`}>
                                    <i
                                        onClick={() => {
                                            dispatch(questionType("exam"));
                                        }}
                                        className="actionIcon fas fa fa-eye ps-2"
                                    ></i>
                                </Link>
                            </OverlayTrigger>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <OverlayTrigger
                                key={"2"}
                                placement={"top"}
                                overlay={<Tooltip id={`tooltip-${"top"}`}>Delete Exam</Tooltip>}
                            >
                                <i
                                    onClick={showDelete}
                                    id={row.id}
                                    className="actionIcon fas fa fa-trash ps-2"
                                ></i>
                            </OverlayTrigger>

                            <OverlayTrigger
                                key={"3"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>Add Questions</Tooltip>
                                }
                            >
                                <Link to={`/create_questions/${row.id}`}>
                                    <i
                                        onClick={() => {
                                            dispatch(questionType("exam"));
                                            {
                                                row.azhar
                                                    ? azharDispatch(isAzharAction(true))
                                                    : azharDispatch(isAzharAction(false));
                                            }
                                        }}
                                        className="actionIcon fas fa fa-plus ps-2"
                                    ></i>
                                </Link>
                            </OverlayTrigger>
                            {row.azhar ? (
                                <OverlayTrigger
                                    key={"4"}
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>
                                            Add Sheet Questions
                                        </Tooltip>
                                    }
                                >
                                    <Link to={{pathname: `/create_sheet_questions/exam/${row.id}`}}>
                                        <i className="actionIcon fas fa fa-plus ps-2"></i>
                                    </Link>
                                </OverlayTrigger>
                            ) : null}
                            <OverlayTrigger
                                key={"5"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>Open Exam Manually</Tooltip>
                                }
                            >
                                <i
                                    onClick={() => {
                                        setShowOpenExamManually(row.id);
                                    }}
                                    className="actionIcon fas fa fa-user-plus ps-2"
                                ></i>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key={"6"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>View Questions</Tooltip>
                                }
                            >
                                <Link to={`/questions/${row.id}`}>
                                    <i
                                        onClick={() => {
                                            dispatch(questionType("exam"));
                                        }}
                                        className="actionIcon fas fa fa-eye ps-2"
                                    ></i>
                                </Link>
                            </OverlayTrigger>
                            {row.azhar ? (
                                <OverlayTrigger
                                    key={"7"}
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>
                                            View Sheets
                                        </Tooltip>
                                    }
                                >
                                    <Link to={`/view_exam_sheets/${row.id}`}>
                                        <i className="actionIcon fas fa fa-eye ps-2"></i>
                                    </Link>
                                </OverlayTrigger>
                            ) : null}
                            <OverlayTrigger
                                key={"8"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>View Results</Tooltip>
                                }
                            >
                                <Link
                                    to={`/view_results/${row.id}/exam/${row.azhar ? "1" : "2"}`}
                                >
                                    <i className="actionIcon fas fa fa-eye ps-2"></i>
                                </Link>
                            </OverlayTrigger>
                        </React.Fragment>
                    )}
                </div>
            )
        }
    ]);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [current, setCurrent] = useState(1);
    const [examID, setExamID] = useState("");
    const [show, setShow] = useState("");
    const [loading, setLoading] = useState(false);

    const getExams = async () => {
        let result = await axiosInstance.get(
            `/Exam/?course_id=${params.id}`
        );
        console.log(result);
        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
            setLoading(false);
        }
    };

    const onPageChange = async (page) => {
        setCurrent(page);
        let result = await axiosInstance.get(
            `/Exam/?course_id=${params.id}&page=${page}`
        );
        setData(result.data.data);
        setCount(result.data.count);
    };
    const showDelete = (e) => {
        setExamID(e.target.id);
        setShow(true);
    };
    const hide = () => {
        setShow(false);
    };

    useEffect(() => {
        setLoading(true);
        getExams();
    }, [show]);


    return (
        <div>
            <OpenExamManually show={showOpenExamManually}
                              hide={() => setShowOpenExamManually(false)}
                              id={params.id}
            />
            <div className="">
                <Container>
                    <Row>
                        <Col className="py-4" sm={12}>
                            {" "}
                            <SubLink text="Courses"/>
                            <i class="fas fa-chevron-right px-3 title2"></i>
                            <span className="title2">View Exams</span>
                        </Col>
                    </Row>
                    {loading ? (
                        <Spin tip="loading....."/>
                    ) : (
                        <>
                            {" "}
                            <Row>
                                <Col style={{textAlign: "center"}} sm={12}>
                                    <DataTable columns={columns} data={data}/>
                                </Col>
                            </Row>
                            <Row className="p-4">
                                {data.length !== 0 ? (
                                    <Pagination
                                        onChange={onPageChange}
                                        defaultCurrent={current}
                                        total={count}
                                        current={current}
                                    />
                                ) : null}
                            </Row>
                        </>
                    )}
                </Container>
            </div>
            <DeleteModal
                show={show}
                setShow={setShow}
                hide={hide}
                link={`/Exam/${examID}`}
                text={`Are you sure you want to delete this exam?`}
                getData={getExams}
            />
        </div>
    );
}

export default ErrorHandler(ViewExams, axiosInstance);
