import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axiosInstance from "../../api/axios";
import { Pagination, Spin } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
function ViewLogs() {
  const params = useParams();
  const [columns, setColumns] = useState([
    {
      name: "Actor",
      cell: (row) => (
        <div className="ps-2">
          {row.actor.first_name} {row.actor.last_name}
        </div>
      ),
      width: "250px"
    },
    {
      name: "Date",
      width: "250px",

      button: true,
      cell: (row) => <div className="ps-2">{row.timestamp.split("T")[0]}</div>
    },
    {
      name: "Action",
      button: true,
      width: "250px",

      cell: (row) => (
        <div className="ps-2">
          {row.action == "1"
            ? "Update"
            : row.action == "2"
            ? "Delete"
            : "Create"}{" "}
          in {row.object_repr}
        </div>
      )
    }
  ]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);

  const getLogs = async () => {
    let result = await axiosInstance.get(
      `/backlog?user_id=${params.id}&page=1`
    );
    console.log(result);
    if (result) {
      setData(result.data.data);
      setCount(result.data.count);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getLogs();
  }, []);
  const onPageChange = async (page) => {
    setCurrent(page);
    let result = await axiosInstance.get(
      `/backlog?user_id=${params.id}&page=${page}`
    );
    setData(result.data.data);
    setCount(result.data.count);
  };

  return (
    <div>
      <div className="">
        <Container>
          <Row>
            <Col className="py-4" sm={6}>
              {" "}
              <span className="title1">Assistant Logs</span>
            </Col>
          </Row>
          {loading ? (
            <Spin tip="loading....." />
          ) : (
            <>
              <Row>
                <Col style={{ textAlign: "center" }} sm={12}>
                  <DataTable columns={columns} data={data} />
                </Col>
              </Row>
              <Row className="p-4">
                {data.length !== 0 ? (
                  <Pagination
                    onChange={onPageChange}
                    defaultCurrent={current}
                    total={count}
                    current={current}
                  />
                ) : null}
              </Row>
            </>
          )}
        </Container>
      </div>
    </div>
  );
}

export default ErrorHandler(ViewLogs, axiosInstance);
