import React, {useEffect, useRef} from "react";
import styles from "./EditSheets.module.css";
import SheetInput from "./SheetInput";
import PrimaryButton from "../../common/Button/PrimaryButton";

export default function EditableSheetItem({sheet, onSubmit, onDelete, order}) {
    const questionRef = useRef();
    const answerRef = useRef();
    useEffect(() => {
        if (!sheet) return;
        console.log({sheet: sheet.question})
        questionRef.current.value = sheet.question;
        answerRef.current.value = sheet.answer;
    }, [sheet, questionRef, answerRef])
    return (
        <div className={styles.card}>
            <label>Question {order}</label>
            <div className={styles.inputsContainer}>
                <SheetInput label="Question" placeholder="Question Text" ref={questionRef}/>
                <SheetInput label="Answer" placeholder="Answer Text" ref={answerRef}/>
            </div>
            <div className={styles.buttonsContainer}>
                <PrimaryButton onClick={() => onDelete(sheet.id)}>Delete</PrimaryButton>
                <PrimaryButton onClick={() => onSubmit(sheet.id,
                    {
                        question: questionRef.current.value,
                        answer: answerRef.current.value
                    }
                )}>Save</PrimaryButton>
            </div>
        </div>
    )
}