import React, {useState, useEffect} from "react";
import {Row, Col, Button} from "react-bootstrap";
import axiosInstance from "../../api/axios";
import {Empty, Modal, Pagination, Select} from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import {Collapse} from "antd";
import {ToastContainer, toast} from "react-toastify";

const {Panel} = Collapse;

function ActiviteSessionManually({show, hide, id}) {
    const [data, setData] = useState([]);
    const [current, setCurrent] = useState(1);
    const [selectedStudents, setSelectedStudents] = useState("");
    const {Option} = Select;

    const getStudents = async () => {
        let result = await axiosInstance.get(`/blocked_student_in_session?page=1&session=${id}&page_size=10000`, );
        console.log(result);
        if (result) {
            setData(result.data);
        }
    };

    useEffect(() => {
        if (show) getStudents();
    }, [show]);

    const handleStudents = (e) => {
        console.log(e);
        setSelectedStudents(e);
    };
    const add = async () => {
        let result = await axiosInstance.put(`/reopen_student_session`, {
            student_id: selectedStudents.split("&")[1],
            session_id: id
        }).catch(error => {
            if (error.response.status == 404) toast.error("Student isn't enrolled in this session.")
        });
        console.log(result);
        if (result) {
            if (result.status === 202) {
                setTimeout(() => {
                    toast.info(`Students has been added successfully`);
                }, 500);
                setTimeout(() => {
                    hide();
                }, 3500);
            }
        }
    };
    return (
        <Modal visible={show} onCancel={hide} size={"lg"}>
            <div className="p-3">
                <Row>
                    <Col className="text-center" sm="12">
                        <p>Select students to activate this session to them</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center" sm={12}>
                        {" "}
                        <Select
                            showSearch
                            onChange={handleStudents}
                            value={selectedStudents}
                            placeholder="Select Students"
                            style={{display: "block", width: 100}}
                            //   onPopupScroll={onScrollStates}
                        >
                            {data.map((student) => {
                                return (
                                    <Option
                                        name={student.user.first_name}
                                        key={student.id}
                                        value={`${student.code}&${student.id}`}
                                    >
                                        {student.user.first_name} {student.user.last_name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-3" sm="12">
                        <Button
                            disabled={selectedStudents.length > 0 ? false : true}
                            className="courseBtn1"
                            onClick={add}
                        >
                            Confirm
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}

export default ErrorHandler(ActiviteSessionManually, axiosInstance);
