import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  OverlayTrigger,
  Tooltip,
  Button
} from "react-bootstrap";
import axiosInstance from "../../api/axios";
import img from "../../images/courseImg.png";
import { Pagination, Form, Input, Spin } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { useParams, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
function AllAssistants() {
  const params = useParams();
  const [assistantID, setAssistantID] = useState("");
  const [columns, setColumns] = useState([
    {
      name: "First Name",
      selector: "first_name",
      sortable: true,
      right: true
    },
    {
      name: "Last Name",
      selector: "last_name",
      sortable: true,
      right: true
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      right: true
    },
    {
      name: "Phone",
      selector: "phone",
      sortable: true,
      right: true
    },
    {
      name: "Actions",
      width: "120px",
      button: true,
      cell: (row) => (
        <div className="table-data-feature">
          <React.Fragment>
            <OverlayTrigger
              key={"2"}
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-${"top"}`}>View Permissions</Tooltip>
              }
            >
              <Link to={`/Permissions/${row.id}`}>
                <i className="fas fa-users-cog ps-2 actionIcon"></i>
              </Link>
            </OverlayTrigger>
            <OverlayTrigger
              key={"2"}
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-${"top"}`}>Delete Assistant</Tooltip>
              }
            >
              <i
                onClick={showDelete}
                id={row.id}
                className="actionIcon fas fa fa-trash ps-2"
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              key={"2"}
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-${"top"}`}>Edit Assistant</Tooltip>
              }
            >
              <i
                onClick={showEdit}
                id={row.id}
                className="actionIcon fas fa fa-edit ps-2"
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              key={"2"}
              placement={"top"}
              overlay={<Tooltip id={`tooltip-${"top"}`}>View Logs</Tooltip>}
            >
              <Link to={`/logs/${row.id}`}>
                <i className="fas fa-eye ps-2 actionIcon"></i>
              </Link>
            </OverlayTrigger>
          </React.Fragment>
        </div>
      )
    }
  ]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [show, setShow] = useState(false);
  const [showEditModel, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const getAssistants = async () => {
    let result = await axiosInstance.get(`/search_users?user_type=2&page=1`);
    console.log(result);
    if (result) {
      setData(result.data.data);
      setCount(result.data.count);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getAssistants();
  }, []);
  const onPageChange = async (page) => {
    setCurrent(page);
    let result = await axiosInstance.get(
      `/search_users?user_type=2&page=${page}`
    );
    setCount(result.data.count);
    setCount(result.data.count);
  };

  const deleteAccount = async () => {
    let result = await axiosInstance.delete(`/User/${assistantID}`);
    if (result) {
      console.log(result);
      setShow(false);
      getAssistants();
    }
  };
  const showDelete = (e) => {
    setAssistantID(e.target.id);
    setShow(true);
  };
  const close = () => {
    setShow(false);
  };
  const showEdit = (e) => {
    setAssistantID(e.target.id);
    setShowEdit(true);
  };
  const closeEdit = () => {
    setShowEdit(false);
  };
  const onFinish = async (data) => {
    let result = await axiosInstance.put(`/User/${assistantID}`, data);
    if (result) {
      console.log(result);
      if (result) {
        closeEdit();
        getAssistants();
      }
    }
  };
  return (
    <div>
      <div className="">
        <Container>
          <Row>
            <Col className="py-4" sm={6}>
              {" "}
              <span className="title1">All Assistants</span>
            </Col>
            <Col className="py-4 text-end" sm={6}>
              {" "}
              <Link to="/create_assistant">
                <Button className="courseBtn1">
                  + Create New Assistant Account
                </Button>
              </Link>
            </Col>
          </Row>
          {loading ? (
            <Spin tip="...loading" />
          ) : (
            <>
              <Row>
                <Col style={{ textAlign: "center" }} sm={12}>
                  <DataTable columns={columns} data={data} />
                </Col>
              </Row>
              <Row className="p-4">
                {data.length !== 0 ? (
                  <Pagination
                    onChange={onPageChange}
                    defaultCurrent={current}
                    total={count}
                    current={current}
                  />
                ) : null}
              </Row>
            </>
          )}
        </Container>
      </div>
      <Modal show={show} onHide={close}>
        <Container className="p-4">
          <Row>
            <Col className="text-center" sm={12}>
              {" "}
              <h6>Are u sure u want to delete this account?</h6>
            </Col>
            <Row>
              <Col className="text-center" md={12}>
                <Button onClick={deleteAccount} className="courseBtn1 mx-3">
                  Delete
                </Button>
                <Button className="courseBtn1" onClick={close}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Row>
        </Container>
      </Modal>
      <Modal show={showEditModel} onHide={closeEdit}>
        <div className="p-3">
          <Row>
            <Col className="text-center" sm={12}>
              {" "}
              <h6>Update Assistant Info</h6>
            </Col>
          </Row>
          <Form onFinish={onFinish} layout="vertical">
            <Row>
              <Col className="text-left" md={6}>
                <>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    layout="vertical"
                  >
                    <Input
                      className="loginInput"
                      placeholder="First Name"
                      type="text"
                    />
                  </Form.Item>

                  <Form.Item label="Email" name="email" layout="vertical">
                    <Input
                      className="loginInput"
                      placeholder="Email"
                      type="email"
                    />
                  </Form.Item>
                </>
              </Col>
              <Col className="" md={6}>
                <>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    layout="vertical"
                  >
                    <Input
                      className="loginInput"
                      placeholder="Last Name"
                      type="text"
                    />
                  </Form.Item>
                  <Form.Item label="Phone" name="phone">
                    <Input
                      placeholder="Phone"
                      className="loginInput"
                      type="number"
                    />
                  </Form.Item>
                </>
              </Col>
            </Row>

            <Row>
              <Col className="text-center" sm="12">
                <Form.Item>
                  <Button
                    className="courseBtn1"
                    //   onClick={onLogin}
                    type="primary"
                    htmlType="submit"
                  >
                    save Changes{" "}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default ErrorHandler(AllAssistants, axiosInstance);
