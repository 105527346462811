import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axiosInstance from "../../api/axios";
import { Form, Input, Button } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SubLink from "../Link";

function CreateCourse() {
  let navigate = useNavigate();
  const [disable, setDisabled] = useState(false);

  const onFinish = async (data) => {
    setDisabled(true);
    console.log(data);
    let result = await axiosInstance.post(`/CourseAdmin/`, data).catch((err) => {
      console.log(err.response);
      setDisabled(false);
    });
    if (result) {
      console.log(result);
      if (result.data) {
        console.log(result);
        setTimeout(() => {
          toast.info(`New Course Added Successfully`);
        }, 500);
        setTimeout(() => {
          navigate("/home");
        }, 3500);
        setDisabled(true);
      }
    }
  };
  return (
    <div className="">
      <Container className="p-4">
        <Row className="mb-3">
          <Col className="" sm={12}>
            {" "}
            <SubLink text="Courses" route="/home" />
            <i class="fas fa-chevron-right px-3 title2"></i>
            <span className="title2">Create Course</span>
          </Col>
        </Row>
        <Row>
          <Col sm="8">
            <Form
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Form.Item
                rules={[
                  { required: true, message: "Please input Course Title!" },
                ]}
                label="Title"
                name="title"
                layout="vertical"
              >
                <Input className="loginInput" placeholder="Title" type="text" />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Please input Course Level!" },
                ]}
                label="Education Level"
                name="edu_lvl"
                layout="vertical"
              >
                <Input
                  className="loginInput"
                  placeholder="Level"
                  type="number"
                  max={"3"}
                />
              </Form.Item>
              <Row>
                <Col className="text-center" md={12}>
                  <Button
                    disabled={disable}
                    htmlType="submit"
                    className="courseBtn1 mx-3"
                  >
                    {disable ? "loading..." : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ErrorHandler(CreateCourse, axiosInstance);
