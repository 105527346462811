import React, {useState, useEffect} from "react";
import {Row, Col, Container, Button, Modal} from "react-bootstrap";
import "../../style-sheets/training.css";
import {Radio} from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance, {BASE_URL} from "../../api/axios";
import {Player} from "video-react";

import {
    Empty,
    Spin,
    Input,
    Pagination,
    Result,
    Checkbox,
    Alert,
    Skeleton,
} from "antd";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useSelector} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import useScreenShotPreventer from "../../hooks/screenshotpreventer";
import SubLink from "../Link";

function ViewQuestions({qtype}) {
    const navigate = useNavigate();
    const params = useParams();
    const user = useSelector((state) => state.auth.user);
    const stype = useSelector((state) => state.questionType.questionType);
    const type = qtype ?? stype;
    const [loading, setLoading] = useState(false);
    const [question, setQuestion] = useState("");
    const [questions, setQuestions] = useState([]);
    const [count, setCount] = useState(0);

    const [current, setCurrent] = useState(1);
    const [questionNumber, setQuestionNumber] = useState("");
    const [questionCount, setQuestionCount] = useState(0);
    const [answerID, setAnswerID] = useState("");
    const [show, setShow] = useState(false);
    const [quesID, setQuesID] = useState("");
    const [showAddModal, setShowAddModal] = useState(false);
    const [choice, setChoice] = useState("");
    const [updatedChoice, setUpdatedChoice] = useState({
        id: undefined,
        index: undefined,
        image: undefined,
        choice: undefined,
    })
    const [updatedQuestion, setUpdatedQuestion] = useState({
        id: undefined,
        question: undefined,
        image: undefined,
    })
    const [disable, setDisable] = useState(false);
    const [quizes, setQuizes] = useState([{question: "", answer: ""}]);
    const [index, setIndex] = useState(0);
    const [results, setResults] = useState("");
    const [err, setErr] = useState("");
    const [answer, setAnswer] = useState([]);

    useScreenShotPreventer();


    const getQuestions = async () => {
        setLoading(true);
        let result;
        if (type == "exam") {
            result = await axiosInstance
                .get(`/exam_question/${params.id}?page=${current}${user.user_type == "3" ? "&page_size=1000" : ""}`)
                .catch((err) => {
                    console.log(err.response);
                    setErr(err.response.data.error);
                });
        } else {
            result = await axiosInstance
                .get(`/quiz_question/${params.id}?page=${current}${user.user_type == "3" ? "&page_size=1000" : ""}`)
                .catch((err) => {
                    console.log(err.response);
                    setErr(err.response.data);
                });
        }
        if (result) {
            setLoading(false);
            console.log(result);
            setQuestions(result.data.data);
            setLoading(false);
            setQuestionCount(result.data.count);
        }
    };

    const onPageChange = async (page) => {
        setCurrent(page);
        let result;
        if (type == "exam") {
            result = await axiosInstance.get(
                `/exam_question/${params.id}?page=${current}`
            );
        } else {
            result = await axiosInstance.get(
                `/quiz_question/${params.id}?page=${current}`
            );
        }
        setQuestions(result.data.data);
        setLoading(false);
        setQuestionCount(result.data.count);
    };

    useEffect(() => {
        setLoading(true);
        getQuestions();
    }, []);

    const next = () => {
        setCount(count + 1);

        let list = [...quizes];
        setQuizes([...list, {question: "", answer: ""}]);
    };
    const prev = () => {
        setDisable(false);
        setCount(count - 1);
    };

    const selectedAnswer = (e, index) => {
        console.log(e.target.value, e.target.id);
        setIndex(index);
        setAnswerID(e.target.value);
        setQuesID(e.target.id);
    };

    const selectedTextAnswer = (e, index) => {
        let copy = [...answer];
        copy[index] = e.target.value;
        setAnswer(copy);
        setQuesID(e.target.id);
        setIndex(index);
    };

    useEffect(() => {
        if ((answerID !== "" || answer.length > 0) && quesID) {
            console.log(answer[index]);
            let list = [...quizes];
            if (list[index].question == "") {
                setDisable(false)
            }
            list[index]["question"] = quesID;
            list[index]["answer"] = user.azhar ? answer[index] : answerID;
            console.log(list);
            setQuizes(list);
        }
    }, [answer, answerID]);

    const showWarningModal = (e) => {
        setShow(true);
        setQuesID(e.target.id);
    };
    const close = (e) => {
        setShow(false);
    };

    const deleteQues = async () => {
        let result = await axiosInstance.delete(`/Question/${quesID}`);
        console.log(result);
        if (result.data == "") {
            setShow(false);
            getQuestions();
        }
    };

    const deleteChoice = async (e) => {

        let result = await axiosInstance.delete(`/Choice/${e.target.id}`);
        console.log(result);
        if (result.data == "") {
            setShow(false);
            getQuestions();
        }
    };

    const editQues = async (e) => {
        if (updatedQuestion.id != e.target.id) return;

        let data = new FormData();
        if (updatedQuestion.question) data.append("question", updatedQuestion.question);
        if (updatedQuestion.image) data.append("question_img", updatedQuestion.image);

        let result = await axiosInstance
            .put(`/Question/${e.target.id}`, data)
            .catch((err) => console.log(err));
        if (result) {
            toast.success(`Question has been edited successfully`);
        }
    };
    const editChoice = async (e, i) => {
        if (updatedChoice.id != e.target.id || updatedChoice.index != i) return;

        let data = new FormData();
        console.log({updatedChoice})
        if (updatedChoice.choice) data.append("choice", updatedChoice.choice);
        if (updatedChoice.image) data.append("choice_img", updatedChoice.image);

        let result = await axiosInstance
            .put(`/Choice/${e.target.id}`, data)
            .catch((err) => console.log(err));
        if (result) {
            toast.success(`Choice has been edited successfully`);
        }
    };

    const viewAdd = (e) => {
        setShowAddModal(true);
        setQuesID(e.target.id);
    };
    const hideAdd = () => {
        setShowAddModal(false);
    };

    const handleChange = (e, setFunction) => {
        console.log(e);
        setFunction(e.target.value);
    };
    const addChoice = async () => {
        let data = {
            choice: choice,
            question: quesID,
        };
        console.log(data);
        let result = await axiosInstance.post(`/Choice/`, data).catch((err) => {
            console.log(err.response);
        });
        console.log(result);
        if (result) {
            if (result.data) {
                setShowAddModal(false);
                setChoice("");
                getQuestions();
            }
        }
    };

    const onChange = (page) => {
        console.log(page);
        setCurrent(page);
    };
    useEffect(() => {
        getQuestions();
    }, [current]);

    const checkEmptyQuestions = (prev, cur) => cur.question == "" ? true : prev
    const checkAnswer = (prev, cur, answer) => {
        console.log({cur: cur.answer, answer, bool: cur.answer == answer})
        return cur.answer == answer ? true : prev
    }

    function checkIfEmptyQuestions() {
        const filteredQuestions = quizes.filter(it => it.question != "");
        return filteredQuestions.length < questionCount;
    }

    const submitAnswers = async () => {
        setDisable(true);
        if (checkIfEmptyQuestions()) {
            setDisable(true);
            toast.error("Please solve all questions first");
            return;
        }
        let data = {
            id: params.id,
            questions: quizes.filter(it => it.question != ""),
        };
        console.log({data});
        return;
        let result;
        if (user.azhar) {
            result = await axiosInstance.post(
                `${type == "exam" ? "/mark_azhar_exam" : "/mark_azhar_quiz"}`,
                data
            );
        } else {
            result = await axiosInstance.post(
                `${type == "exam" ? "/mark_exam" : "/mark_quiz"}`,
                data
            );
        }
        if (result) {
            console.log(result);
            if (user.azhar) {
                setResults("Your answers has been submitted successfully");
                // setTimeout(() => {
                //   navigate("/home");
                // }, 3000);
            } else {
                setResults(result.data);
                // setTimeout(() => {
                //   navigate("/home");
                // }, 3000);
            }
        }
    };

    const go = (e) => {
        e.preventDefault();
        if (questionNumber > questionCount) {
            toast.error(`Value must be less than or equal ${questionCount}`);
        } else {
            setCount(questionNumber - 1);
        }
    };

    return (
        <div>
            {user.user_type === "3" ? (
                <Container>
                    <Row className="mb-5">
                        <Col md="6" sm="12">
                            <h4 style={{color: "#001731"}}>
                                <SubLink text={<span className="fas fa-chevron-right px-3 title2"
                                                     style={{transform: "rotate(180deg)"}}/>}/>
                                View Questions
                            </h4>
                        </Col>
                        {questions.length > 0 ? (
                            <Col sm="12" md="6">
                                <form onSubmit={(e) => e.preventDefault}>
                                    <Row>
                                        <Col sm="12" md="6">
                                            <input
                                                value={questionNumber}
                                                name="questionNumber"
                                                onChange={(e) => handleChange(e, setQuestionNumber)}
                                                max={questionCount}
                                                type="number"
                                                className="ant-input questionNumberInput"
                                                placeholder="Pick a Question"
                                            />
                                        </Col>
                                        <Col sm="12" md="6">
                                            <input
                                                value="Pick a Question"
                                                className="forumBtn1 p-1 ml-2"
                                                onClick={go}
                                                type="submit"
                                            />
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                        ) : null}
                    </Row>
                    {questions.map((ques, index) => {
                        return count == index ? (
                            <React.Fragment>
                                <Row>
                                    <Col sm="12" md="12">
                                        <div className="questionDiv p-4">
                                            <p>{ques.question}</p>
                                            {ques.question_img && <img
                                                style={{maxHeight: "300px", maxWidth: "100%", objectFit: "contain"}}
                                                src={`${BASE_URL}${ques.question_img}`}/>}
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    {ques.choicequestion.map((choice) => {
                                        console.log({ques, choice})
                                        return (
                                            <Col className="my-3" sm="12">
                                                <p className="choices1">
                                                    <Radio.Group
                                                        onChange={(e) => selectedAnswer(e, index)}
                                                        value={answerID}
                                                        name={ques.id}
                                                    >
                                                        <Radio id={ques.id} value={choice.id}></Radio>
                                                    </Radio.Group>
                                                    {choice.choice}{" "}
                                                </p>
                                                {choice.choice_img && <img style={{maxHeight: "250px", maxWidth: "90%"}}
                                                                           src={`${BASE_URL}${choice.choice_img}`}/>}
                                            </Col>
                                        );
                                    })}
                                </Row>
                                <Row>
                                    <Col className="my-3" sm="8">
                                        {user.azhar ? (
                                            <textarea
                                                value={answer[index]}
                                                id={ques.id}
                                                row={5}
                                                onChange={(e) => selectedTextAnswer(e, index)}
                                                placeholder="Write you answer here.."
                                            />
                                        ) : null}
                                    </Col>
                                </Row>
                            </React.Fragment>
                        ) : null;
                    })}
                    <Row>{loading && questions.length == 0 ? <Skeleton/> : null}</Row>

                    {questions.length == 0 && err == "" && loading == false ? (
                        <Empty/>
                    ) : null}

                    {questions.length > 1 ? (
                        <Row>
                            <Col sm="12 mt-3 text-right p-3">
                                <Button
                                    disabled={count == 0}
                                    onClick={prev}
                                    className="quesBtn1"
                                >
                                    Back
                                </Button>

                                {questionCount == count + 1 ? (
                                    <Button
                                        disabled={disable}
                                        onClick={submitAnswers}
                                        className="quesBtn2"
                                    >
                                        Submit
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={disable}
                                        onClick={next}
                                        className="quesBtn2"
                                    >
                                        Next
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    ) : questions.length == 1 ? (
                        <Button
                            disabled={disable}
                            onClick={submitAnswers}
                            className="quesBtn2"
                        >
                            Submit
                        </Button>
                    ) : null}
                    {questions.length == 0 && err !== "" ? (
                        <Result
                            status="error"
                            title={"Access Failed"}
                            subTitle={err}
                        ></Result>
                    ) : null}

                    {results !== "" ? (
                        <>
                            <Alert
                                message={
                                    user.azhar
                                        ? `${results}`
                                        : `You have answered ${results.result} correct questions your mark is ${results.result} out of ${results.mark}`
                                }
                                type="info"
                                showIcon
                                className="mt-3"
                            />
                            {results.passed === true ? (
                                <>
                                    <br/>
                                    <p> The model answer : </p>
                                    {/* <a
                    href={results.ans_video}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>Video</p>
                  </a> */}
                                    <div className="p-5 ">
                                        <Player playsInline src={results && results.ans_video}/>
                                    </div>
                                    <a
                                        href={results.answer_doc}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <p> Document</p>
                                    </a>{" "}
                                </>
                            ) : null}
                        </>
                    ) : null}
                </Container>
            ) : (
                <Container>
                    <Row>
                        <Col sm="12">
                            <h4
                                onClick={() => navigate.goBack()}
                                style={{color: "#001731"}}
                            >
                                <SubLink text={<span className="fas fa-chevron-right px-3 title2"
                                                     style={{transform: "rotate(180deg)"}}/>}/>
                                View Questions
                            </h4>
                        </Col>
                    </Row>
                    {questions.map((ques, index) => {
                        const isHasImage = ques.question_img || updatedQuestion.id == ques.id && updatedQuestion.image;
                        return (
                            <div className="questionDiv mb-4" style={{width: "90%"}}>

                                <Row className="p-4 mb-2">

                                    <h4>Question</h4>
                                    <Col xs={12} md={6}>

                                        <div className="imageContainer">
                                            {isHasImage && <img
                                                src={updatedQuestion.id == ques.id && updatedQuestion.image ?
                                                    URL.createObjectURL(updatedQuestion.image) :
                                                    BASE_URL + ques.question_img}
                                                style={{width: "100%"}}/>}
                                            <div
                                                className={["changeContainer", !isHasImage ? "addContainer" : ''].join(" ")}>
                                                <input type="file" name={`ques-${index}`} id={`ques-${index}`}
                                                       onChange={(e) => setUpdatedQuestion({
                                                           id: ques.id,
                                                           question: ques.id == updatedQuestion.id ? updatedQuestion.question : undefined,
                                                           image: e.target.files[0],
                                                       })}
                                                />
                                                <label htmlFor={`ques-${index}`}><i
                                                    className="fas fa fa-edit ps-2"/></label>
                                            </div>
                                        </div>
                                        {/*<img src={BASE_URL + ques.question_img}*/}
                                        {/*     style={{width: "100%"}}/>*/}
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Input.TextArea
                                            rows={4}
                                            type="text"
                                            defaultValue={ques.question}
                                            onChange={(e) => setUpdatedQuestion({
                                                id: ques.id,
                                                question: e.target.value,
                                                image: ques.id == updatedQuestion.id ? updatedQuestion.image : undefined,
                                            })}
                                            className="questInput"
                                            style={{height: "100%"}}
                                        />
                                        <i
                                            id={ques.id}
                                            onClick={editQues}
                                            className="fas fa-edit px-1 editIcon"
                                        />
                                        <i
                                            onClick={showWarningModal}
                                            id={ques.id}
                                            className="fas fa-trash  editIcon"
                                        ></i>
                                    </Col>
                                </Row>
                                <div className="choiceTitle">
                                    <h4>Choices:</h4>
                                    {ques.quiz?.azhar || ques.exam?.azhar ? null : (
                                        <div>
                                            <Button
                                                onClick={viewAdd}
                                                id={ques.id}
                                                className="forumBtn1"
                                            >
                                                Add Choice
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <div className="p-4 choiceItem">
                                    {ques.choicequestion.map((choice, i) => {

                                        const isHasChoiceImage = choice.choice_img || (updatedChoice.index == i && updatedChoice.id == choice.id && updatedChoice.image);
                                        return (
                                            <Row>
                                                <h5 className="choiceLabel">Choice {i + 1}</h5>
                                                <Col xs={12} md={6}>
                                                    <div className="imageContainer">
                                                        {isHasChoiceImage && <img
                                                            src={updatedChoice.index == i && updatedChoice.id == choice.id && updatedChoice.image ?
                                                                URL.createObjectURL(updatedChoice.image) :
                                                                BASE_URL + choice.choice_img}
                                                            style={{width: "100%"}}/>}
                                                        <div
                                                            className={["changeContainer", !isHasChoiceImage ? "addContainer" : ''].join(" ")}>
                                                            <input type="file" name={`choice-${i}-${choice.id}`}
                                                                   id={`choice-${i}-${choice.id}`}
                                                                   onChange={(e) => setUpdatedChoice({
                                                                       id: choice.id,
                                                                       index: i,
                                                                       choice: choice.id == updatedChoice.id && updatedChoice.index == i ? updatedChoice.choice : undefined,
                                                                       image: e.target.files[0],
                                                                   })
                                                                   }
                                                            />
                                                            <label htmlFor={`choice-${i}-${choice.id}`}><i
                                                                className="fas fa fa-edit ps-2"/></label>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Input.TextArea
                                                        rows={4}
                                                        style={{height: "100%"}}
                                                        className="questInput"
                                                        defaultValue={choice.choice}
                                                        onChange={(e) => setUpdatedChoice({
                                                            id: choice.id,
                                                            index: i,
                                                            choice: e.target.value,
                                                            image: choice.id == updatedChoice.id && updatedChoice.index == i ? updatedChoice.image : undefined,
                                                        })}
                                                    />
                                                    <i
                                                        id={choice.id}
                                                        onClick={(e) => editChoice(e, i)}
                                                        className="fas fa-edit px-1 editIcon"
                                                    />
                                                    <i
                                                        onClick={deleteChoice}
                                                        id={choice.id}
                                                        className="fas fa-trash editIcon"
                                                    ></i>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                    {loading ? (
                        <Col className="mt-5 text-center" sm="12">
                            {" "}
                            <Spin tip="...loading"/>
                        </Col>
                    ) : questions.length === 0 && loading == false ? (
                        <Empty/>
                    ) : null}
                    <Row className="p-4">
                        {questions.length !== 0 ? (
                            <Pagination
                                onChange={onPageChange}
                                defaultCurrent={current}
                                total={questionCount}
                                current={current}
                            />
                        ) : null}
                    </Row>
                </Container>
            )}
            <Modal show={show} onHide={close}>
                <Container className="p-4">
                    <Row>
                        <Col className="text-center" sm={12}>
                            {" "}
                            <h6>Are you sure you want to delete this Question?</h6>
                        </Col>
                        <Row style={{margin: "auto"}}>
                            <Col md={12}>
                                <Button onClick={deleteQues} className="headBtn mx-3">
                                    Delete
                                </Button>
                                <Button className="headBtn" onClick={close}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </Modal>
            <Modal className="text-center" show={showAddModal} onHide={hideAdd}>
                <Container className="py-4">
                    <Row>
                        <Col sm="12">
                            <Input.TextArea
                                rows={4}
                                className="questInput"
                                onChange={(e) => {
                                    setChoice(e.target.value);
                                    setDisable(false);
                                }}
                                placeholder="Add choice"
                            />
                            <Checkbox defaultChecked={false}>Is true?</Checkbox>
                        </Col>
                        <Col className="centering" sm="4">
                            <Button
                                disabled={disable}
                                className="forumBtn1"
                                onClick={addChoice}
                            >
                                Add
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        </div>
    );
}

export default ErrorHandler(ViewQuestions, axiosInstance);
