import {useEffect, useState} from "react";
import {onMessageListener} from "./firebase-services";

export default function useNotification(deps = []) {
    const [newNotification, setNewNotification] = useState();

    useEffect(() => {
        onMessageListener((message) => {
            console.log({message})
            setNewNotification(message.notification)
        });
    }, deps);

    return newNotification;
}