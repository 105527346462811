import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import {
    Container,
    Row,
    Col,
    Card,
    Modal,
    Form,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import ErrorHandler from "../../error-handler/ErrorHandler";

import axiosInstance, { BASE_URL } from "../../api/axios";
import img from "../../images/courseImg.png";
// import { Form, Input, Button } from "antd";
import "../../style-sheets/session.css";
import { InputNumber } from "antd";
import { DISPLAY_MODE, FawryPay } from "./fawrypay-payments";
import { sha256 } from "js-sha256";
import { useSelector } from "react-redux";
import axios from "axios";
import { async } from "@firebase/util";

let signature;
let return_url = `${BASE_URL}/response_fawry`;
let merchantCode = "siYxylRjSPwVaIFPiORyNQ==";
let hash_key = "8250efc06ee94f97b08c2fb018fd3e38";

const axiosPayInstance = axios.create({
    // baseURL: "https://circlepay.ai/",
    baseURL: "https://openapi.circlepay.ai/",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "account-token":
            "Bearer eyJhbGciOiJkaXIiLCJlbmMiOiJBMTI4Q0JDLUhTMjU2Iiwia2lkIjpudWxsLCJjdHkiOiJKV1QifQ..EchOSLge7GlwU1UdU9kBZA.yq9b_22vY9c_DLVWe946KLkieXckrXiD909TMWP1UFnOsGgiFIvwuEqEBgOb7OaggmcxUHK3kNonHmbJHuBbWCSoLjeBi5kNs1-33Rv-va-r4IjXe6OvvIeSbaX3_HhbHJUZrIIMAYRKu_lgDLBFN1T7ygBJfsN3WcizB_Nxf5K8VdDcIJL6Us2J9NToueIuM8Af02DfGGhZaQNh8pM_S7yYig7giiYn4zMgqg9KHZw0tbm2YNxAuw2XHilnmByEUol7kygArnYMsh4PIKW_iCdH7XjhyU7etQtmIyX-PSeeZBsyb5mj-krZH_aauQjKfKxMx3q9pQ-nRZ2w5rR4kv6MSBD8aohj01tjirdLI-tolM-ih1l6GW5_A-zqHhb-.5xX_YEEJi48hkLpnHP9-PQ",
        "account-key": "00aa0fcc-d937-3507-8ee7-c2c9f747cbb6",
        "merchant-token":
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTM2LCJpc1ZlcmlmaWVkIjpmYWxzZSwidG9rZW4iOiIiLCJmb3JnZXRQYXNzd29yZHRva2VuIjoiIiwiZ29vZ2xlT2F1dGhJRCI6IiIsImZhY2Vib29rT2F0aElkIjoiIiwiYXV0aHlfaWQiOiIiLCJzdGF0dXMiOiIwIiwiYWRkcmVzcyI6IiIsImJ1c2luZXNzTmFtZSI6IiIsImZpcnN0TmFtZSI6Ik1vaGFtZWQiLCJsYXN0TmFtZSI6Ik5hYmlsIiwiZW1haWwiOiJtb2hhbWVkbmFiaWw4ODg1QGdtYWlsLmNvbSIsIm1vYmlsZSI6IisyMDExMTY3OTc5NzYiLCJidXNpbmVzc0FkZHJlc3MiOiJvbmxpbmUiLCJ1c2VybmFtZSI6bnVsbCwidXBkYXRlZEF0IjoiMjAyMi0wOS0xMlQxODo0MjozMy42NThaIiwiY3JlYXRlZEF0IjoiMjAyMi0wOS0xMlQxODo0MjozMy42NThaIiwiYXBwX25hbWUiOiJjaGVtdmFsbHkiLCJpYXQiOjE2NjMwMDgxNTN9.FCHb9m66rgW259qLlskbZAwC59C3CDymD6z9Gr1zYwA",
    },
});

const axiosInstanceDefault = axios.create({
    baseURL: BASE_URL,
    headers: {
        authorization: "Bearer " + Cookies.get("accessToken"),
    },
});

let ms = new Date().getTime() + 86400000; // adding a whole day worth of seconds
let tomorrow = new Date(ms);
console.log(tomorrow);

function Wallet() {
    const [price, setPrice] = useState("");
    const [profile, setProfile] = useState("");

    const user = useSelector((state) => state.auth.user);
    let date = new Date();
    const circlePayObject = {
        invoice: {
            customer_mobile: "+2" + user.phone,

            due_date: `${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1
            }-${tomorrow.getDate()}`,
            items: [
                {
                    description: "recharge the student wallet ",
                    name: user.first_name + " " + user.last_name,
                    price: price,
                    quantity: 1,
                },
            ],
        },
    };

    const getStudents = async () => {
        let result = await axiosInstance.get(
            // `/search_students?page=1&id=${params.id}`

            `/profile`
        );
        if (result) {
            console.log("profile", result.data);
            setProfile(result.data);
        }
    };

    const getFrameInfo = async () => {
        let result = await axiosPayInstance
            .post(`invoice/create`, circlePayObject)
            .catch((err) => {
                console.log(err);
            });
        if (result) {
            console.log(result);
            // window.location.href = `${result.data.data[0].invoice_url}`;
            // window.open(`${result.data.data[0].invoice_url}`);
            PayAnInvoice(result.data.data[0].invoice_number);
            // console.log(result.data.data[0].invoice_number);
            // sendInvoiceNumber(result.data.data[0].invoice_number);
        }
    };

    useEffect(() => {
        getStudents();
    }, []);

    const PayAnInvoice = async (invoice_number) => {
        let result = await axiosPayInstance
            .post("invoice/pay", {
                invoice_number: invoice_number,
                customer_mobile: "+2" + user.phone,
            })
            .catch((err) => console.log(err));
        if (result) {
            console.log(result);
            const isValid = await sendTransactionId(result.data.data[0].transaction_id);
            if (isValid) window.open(`${result.data.data[0].invoice_url}`);
        }
    };

    const sendTransactionId = async (id) => {
        let result = await axiosInstance
            .post("circle_pay/start_transaction", {
                transaction_id: id,
                amount: price,
            })
            .catch((err) => console.log(err));
        if (result) {
            console.log(result);
            return true;
        }
    };

    // const checkout = async () => {
    //   // intent();
    //   let result = await axiosInstance.get(
    //     `/recharge_wallet_fawry?price=${price}`
    //   );
    //   if (result.data) {
    //     signature = sha256(
    //       merchantCode +
    //         result.data.order_id +
    //         return_url +
    //         "1" +
    //         1 +
    //         price +
    //         hash_key
    //     );
    //     console.log(signature);
    //     console.log(result.data);
    //     const configuration = {
    //       locale: "en", //default en
    //       mode: DISPLAY_MODE.POPUP, //required, allowed values [POPUP, INSIDE_PAGE, SIDE_PAGE]
    //     };
    //     FawryPay.checkout(
    //       buildChargeRequest(result.data.order_id, signature),
    //       configuration
    //     );
    //   }
    // };

    const buildChargeRequest = (refNum, signature) => {
        const chargeRequest = {
            merchantCode: "siYxylRjSPwVaIFPiORyNQ==",
            merchantRefNum: refNum,
            customerMobile: user.phone,
            customerEmail: user.email,
            customerName: user.first_name,
            chargeItems: [
                {
                    itemId: "1",
                    price: price,
                    quantity: 1,
                },
            ],
            returnUrl: `${BASE_URL}/pay_fawry`,
            authCaptureModePayment: false,
            signature: signature,
            paymentMethod: "PayAtFawry",
        };
        return chargeRequest;
    };

    return (
        <div className="">
            <Container>
                <Row>
                    <Col className="py-4" sm={8}>
                        {" "}
                        <span className="title1">My Wallet</span>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2"> Recharge my wallet </span>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <p>Balance {profile.balance} </p>{" "}
                        <InputNumber
                            type={"number"}
                            // min={100}
                            // max={100000}
                            style={{ margin: "20px 16px", width: "250px" }}
                            value={price}
                            onChange={(e) => setPrice(e)}
                            placeholder="Type the amount here.."
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="py-4" sm={12}>
                        <Button
                            disabled={price == "" ? true : false}
                            onClick={getFrameInfo}
                            className="courseBtn1 mx-3"
                        >
                            Recharge Via wallet
                        </Button>
                        {/* <Button
              disabled={price == "" ? true : false}
              onClick={checkout}
              alt="pay-using-fawry"
              id="fawry-payment-btn"
              className="courseBtn1"
            >
              Recharge via Fawry
            </Button> */}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ErrorHandler(Wallet, axiosInstance);
