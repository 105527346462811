import React, {useRef, useState} from "react";
import styles from "./PreviousQuestions.module.css";
import {useNavigate} from "react-router-dom";
import {Player} from "video-react";
import {BASE_URL} from "../../../../../api/axios";
import {Empty} from "antd";

export default function PreviousQuestions({modelAnswers, type}) {
    console.log({modelAnswers})
    const navigate = useNavigate();
    const video = modelAnswers[`student_${type}`]?.[type]?.ans_video;
    const doc = modelAnswers[`student_${type}`]?.[type]?.ans_doc;
    const [currentTab, setCurrentTab] = useState(0)
    const [isInNewTab, setIsInNewTab] = useState(false)
    const categories = [
        {
            name: "Video",
            children: video ? <div onContextMenu={(e) => e.preventDefault()}>
                {

                    <Player
                        playsInline
                        onContextMenu={(e) => e.preventDefault()}
                        src={BASE_URL + video}/>

                }
            </div> : undefined
        },
        {
            name: "Document",
            children: doc ? <div>
                {
                    isInNewTab ? "Opened In New Tab" :
                        <img src={BASE_URL + doc}
                             onError={_ => {
                                 setIsInNewTab(true)
                                 window.open(BASE_URL + doc, '_blank')
                             }}
                        />
                }
            </div> : undefined
        }
    ]
    return (
        <div className={styles.container}>
            <header>
                <div>
                    <span
                        onClick={() => navigate(-1)}
                        className="fas fa-chevron-left pe-4"
                    />
                    <span>
                        Model Answers
                    </span>
                </div>
                <div>
                    {
                        categories.map((it, i) => <button
                            className={[styles.tab, i === currentTab ? styles.selected : ""].join(" ")}
                            onClick={() => setCurrentTab(i)}
                            key={it.name}>{it.name}</button>)
                    }
                </div>
            </header>
            <section className={styles.content}>
                {
                    categories[currentTab].children ?? <Empty/>
                }
            </section>
        </div>
    )
}