import {parseCounterData} from "../../Common/DurationCounter/utils";

async function getQuestions(axiosInstance, {type, id}) {
    const endpoint = type === "exam" ? "/exam_question/" : "/quiz_question/";
    try {
        const result = await axiosInstance.get(
            `${endpoint}${id}?page_size=1000`
        );

        console.log({result})
        if (result)
            return [
                result.status == 208
                    ? result.data
                    : {
                        data: result.data.data,
                        count: result.data.count,
                        counter: parseCounterData(result.data[`student_${type}`], type),
                    },
                null,
                result.status,
            ];
    } catch (errors) {
        console.log({errors})
        return [null, errors.response.data, +errors.response.status];
    }
}

function checkEmptyQuestions({questions, selectedChoices}) {
    return Object.keys(selectedChoices).length < questions.length;
}

async function submit(
    axiosInstance,
    {type, isAzhar, id, questions, selectedChoices, skipEmptyCheck}
) {
    if (
        !skipEmptyCheck &&
        checkEmptyQuestions({questions, selectedChoices})
    ) {
        return [null, "Please answer all questions."];
    }

    const formattedQuestions = questions
        .map((it, i) => {
            return {question: it.id, answer: selectedChoices[i]};
        })
        .filter((it) => it.answer);

    const data = {
        id,
        questions: formattedQuestions,
    };
    console.log(data);
    try {
        const result = isAzhar
            ? await axiosInstance.post(
                `${type == "exam" ? "/mark_azhar_exam" : "/mark_azhar_quiz"}`,
                data
            )
            : await axiosInstance.post(
                `${type == "exam" ? "/mark_exam" : "/mark_quiz"}`,
                data
            );

        console.log(result);
        if (result)
            return [
                skipEmptyCheck
                    ? "Time out! Your answers has been automatically submitted."
                    : "Your answers has been submitted successfully.",
                null,
            ];
    } catch (errors) {
        return [null, errors.response.data];
    }
}

const requests = {get: getQuestions, submit};
export default requests;
