import { Link } from "react-router-dom";
import "../../../style-sheets/StudentSessionCard.css";
import { BASE_URL } from "../../../api/axios";
import styles from "./ExamsPage.module.css";
export default function ExamCard({ slide }) {
    const img =
        `${BASE_URL}${slide.image}`;

    console.log({slide})
    return (
        <Link className="noselect mb-5" draggable="false" to={slide.azhar ? `/exam_azhar/${slide.id}` :`/exam/${slide.id}`}>
            <div className={` mb-5`}>
                <div
                    className={`cardd ${styles.card}`}
                    style={{
                        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 60.8%, rgba(0, 0, 0, 0.5) 71.42%, #000000 97.07%), url(${img})`,
                    }}
                >
                    <div
                        className=""
                        style={{ width: "100%" }}
                    >
                        <div className={`cardInfoHolder ${styles.cardHeader}`}>
                            <h1 className="cardTitle"> {slide.title}</h1>
                            <p className="cardDesc">{slide.description}</p>
                            <div className={`${styles.cardOption} ${slide.isExpired ? styles.expired : ""}`}>
                                <div >
                                    {
                                        slide.isExpired ? "View" : "Start"
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Link>
    );
}
