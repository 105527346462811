import React from "react";
import styles from "./Footer.module.css";

export default function Footer({}) {
  return (
    <footer className={styles.container}>
      Powered By{" "}
      <a target="_blank" href="https://div-systems.com/">
        Div Systems
      </a>
    </footer>
  );
}
