import React, {useState, useEffect} from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Modal,
    Form,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import axiosInstance, {BASE_URL} from "../../api/axios";
import img from "../../images/courseImg.png";
// import { Form, Input, Button } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import {useParams, Link} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import {Collapse, Spin} from "antd";
import SubLink from "../Link";

const {Panel} = Collapse;

function EditSession(props) {
    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [link, setLink] = useState("");
    const [desc, setDesc] = useState("");
    const [order, setOrder] = useState("");
    const [currimage, setCurrImage] = useState(null);
    const [currvideo, setCurrVideo] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [videoName, setVideoName] = useState("");
    const [imageName, setImageName] = useState("");

    const params = useParams();
    // const { TextArea } = Input;
    const edit = async () => {
        // console.log(params);
        setDisabled(true);
        let formdata = new FormData();
        if (image !== null) {
            formdata.append("image", image);
        }
        if (video !== null) {
            formdata.append("video", video);
        }
        if (link)
            formdata.append("zoom_link", link);
        formdata.append("description", desc);
        formdata.append("price", price);
        formdata.append("order", order);
        formdata.append("title", title);

        let result = await axiosInstance
            .put(`/Session/${params.id}`, formdata)
            .catch((err) => {
                // for (const error in err.response.data) {
                //   console.log(err.response.data[error][0]);
                //   setTimeout(() => {
                //     toast.error(
                //       `${
                //         error +
                //         " : " +
                //         err.response.data[error][0].charAt(0).toUpperCase() +
                //         err.response.data[error][0].slice(1)
                //       }`
                //     );
                //   }, 500);
                // }
                // setDisabled(false);
                console.log(err.response);
            });
        if (result) {
            console.log(result);
            if (result.data) {
                setTimeout(() => {
                    toast.info(`Session Updated Successfully`);
                }, 500);
            }
        }
    };
    const onImgUpload = (e) => {
        e.preventDefault();
        setImage(e.target.files[0]);
    };
    const onVideoUpload = (e) => {
        e.preventDefault();
        setVideo(e.target.files[0]);
        setVideoName(e.target.files[0].name);
    };

    const getDetails = async () => {
        let result = await axiosInstance.get(`/Session/${params.id}`);
        console.log(result);
        setDesc(result.data.description);
        setLink(result.data.zoom_link);
        setPrice(result.data.price);
        setOrder(result.data.order);
        setCurrImage(result.data.image);
        setCurrVideo(result.data.video);
        setTitle(result.data.title);
        setLoading(false);
    };
    useEffect(() => {
        setLoading(true);
        getDetails();
    }, []);
    const changeEditState = (e) => {
        setIsEditable(true);
    };
    const handleClick = (event) => {
        const {target = {}} = event || {};
        target.value = "";
    };
    const removeVideo = () => {
        setVideo(null);
        setVideoName("");
    };
    const removeImage = () => {
        setImage(null);
        setImageName("");
    };
    return (
        <div className="">
            <Container>
                <Row>
                    <Col className="py-4" sm={8}>
                        {" "}
                        <SubLink text="Courses"/>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">View Session Details</span>
                    </Col>
                    <Col sm={4}>
                        {" "}
                        <OverlayTrigger
                            key={"2"}
                            placement={"top"}
                            overlay={<Tooltip id={`tooltip-${"top"}`}>Edit Session</Tooltip>}
                        >
                            <i
                                onClick={changeEditState}
                                className="fas fa-edit editSessionIcon"
                            ></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                {loading ? (
                    <Spin tip="...loading"/>
                ) : (
                    <Form>
                        <Row>
                            <Col sm="6">
                                <Form.Label>Session Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Session Title"
                                    value={title}
                                    name="title"
                                    onChange={(e) => setTitle(e.target.value)}
                                    disabled={!isEditable}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <Form.Label>Session Zoom Link</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Session Zoom Link"
                                    value={link}
                                    name="link"
                                    onChange={(e) => setLink(e.target.value)}
                                    disabled={!isEditable}
                                />
                            </Col>
                            <Col sm="6">
                                <Form.Label>Price</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Session Price"
                                    value={price}
                                    name="price"
                                    onChange={(e) => setPrice(e.target.value)}
                                    disabled={!isEditable}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <Form.Label>Display Order</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Display Order"
                                    value={order}
                                    name="order"
                                    onChange={(e) => setOrder(e.target.value)}
                                    disabled={!isEditable}
                                />
                            </Col>
                            <Col sm="6">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Description"
                                    as="textarea"
                                    value={desc}
                                    name="desc"
                                    onChange={(e) => setDesc(e.target.value)}
                                    disabled={!isEditable}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <Form.Label>Uploaded Video</Form.Label>

                                <div class="wrapper">
                                    <div class="file-upload">
                                        <input
                                            onClick={handleClick}
                                            onChange={onVideoUpload}
                                            type="file"
                                        />
                                        <i class="fa fa-plus"></i>
                                    </div>
                                    {video != null ? (
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>{videoName}</p>

                                            <i
                                                class="fas fa-window-close"
                                                onClick={() => removeVideo()}
                                            ></i>
                                        </div>
                                    ) : null}
                                </div>
                                {currvideo == null ? (
                                    "No Uploaded Video"
                                ) : (
                                    <a href={BASE_URL + currvideo} target="_blank">
                                        uploaded video
                                    </a>
                                )}
                            </Col>
                            <Col sm="6">
                                <Form.Label>Uploaded Image</Form.Label>

                                <div class="wrapper">
                                    <div class="file-upload">
                                        <input
                                            onClick={handleClick}
                                            onChange={onImgUpload}
                                            type="file"
                                        />
                                        <i class="fa fa-plus"></i>
                                    </div>
                                </div>
                                {image != null ? (
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p>{imageName}</p>

                                        <i
                                            class="fas fa-window-close"
                                            onClick={() => removeImage()}
                                        ></i>
                                    </div>
                                ) : null}
                                {currimage == null ? (
                                    "No Uploaded Image"
                                ) : (
                                    <a href={BASE_URL + currimage} target="_blank">
                                        uploaded Image
                                    </a>
                                )}
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col className="text-center" sm="12">
                                <Button
                                    disabled={!isEditable}
                                    className="courseBtn1"
                                    onClick={edit}
                                >
                                    Save Changes
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}

                <Row>
                    <Col sm="12">
                        <Collapse bordered={false} defaultActiveKey={["0"]}>
                            <Panel header="Session Quizes/Homework" key="1">
                                <Link to={`/create_quiz/${params.id}`}>
                                    <i className="actionIcon fas fa fa-plus px-2"></i>Create
                                    Quizes/Homework
                                </Link>
                                <Link to={`/view_quizes/${params.id}`}>
                                    <i className="actionIcon fas fa fa-eye px-2"></i>View
                                    Quizes/Homework
                                </Link>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Container>

        </div>
    );
}

export default ErrorHandler(EditSession, axiosInstance);
