import React from "react";
import NotificationCard from "./NotificationCard";
import styles from "./NotificationCard.module.css";
import examIcon from "./assets/examIcon.svg";
import newVideoIcon from "./assets/newVideoIcon.svg"
import newSessionIcon from "./assets/newSessionIcon.svg"
import hwIcon from "./assets/hwIcon.svg"
import materialIcon from "./assets/materialIcon.svg"
import zoomLinkIcon from "./assets/zoomLinkIcon.svg"
import chapterIcon from "./assets/chapterIcon.svg"
import quizIcon from "./assets/quizIcon.svg"
import {useNavigate} from "react-router-dom";
import {BASE_URL} from "../../../../api/axios";


function NavigatorNotificationCard({onClick, actionRoute, ...rest}) {
    const navigate = useNavigate();
    return <NotificationCard
        onClick={() => navigate(actionRoute)}
        {...rest}
    />
}

// TODO
function ExamNotificationCard(props) {
    return (
        <NotificationCard
            icon={examIcon}
            buttonText={"Enter Exam Room"}
            secondary={props.instance.title}
            extraText={props.instance.description}
            {...props}
        >
            {/*<div className={styles.remainingTime}>{"29m 12s"}</div>*/}
        </NotificationCard>
    )
}

function NewVideoNotificationCard(props) {
    return (
        <NavigatorNotificationCard
            {...props}
            icon={newVideoIcon}
            buttonText={"View"}
            secondary={props.instance.title}
            actionRoute={`/session_details/${props.instance.id}`}
        >
        </NavigatorNotificationCard>
    )
}

function MaterialNotificationCard(props) {
    return (
        <NotificationCard
            {...props}
            secondary={props.instance.session.title}
            icon={materialIcon}
            buttonText={"Check"}
            onClick={() =>
                window.open(`${BASE_URL}${props.instance.material}`, '_newtab')
            }
        />
    )
}

function ZoomLinkNotificationCard(props) {
    return (
        <NotificationCard
            {...props}
            icon={zoomLinkIcon}
            buttonText={"Check"}
            secondary={props.instance.title}
            onClick={() =>
                window.open(props.instance.zoom_link, '_newtab')
            }
        />
    )
}

function QuizNotificationCard(props) {
    return (
        <NavigatorNotificationCard
            {...props}
            icon={quizIcon}
            secondary={props.instance.session.title}
            actionRoute={`/questions/${props.instance.id}`}
            buttonText={"View"}
        />
    )
}

function NewChapterNotificationCard(props) {
    const grade = [
        <>1<sup>st</sup> Grade</>,
        <>2<sup>nd</sup> Grade</>,
        <>3<sup>rd</sup> Grade</>,
    ][props.instance.edu_lvl - 1]

    return (
        <NavigatorNotificationCard
            {...props}
            icon={chapterIcon}
            secondary={props.instance.title}
            body={<>New chapter added for the {grade}.</>}
            actionRoute={"/StudentSessions"}
            buttonText={"Check"}
        />
    )
}

function NewSessionNotificationCard(props) {
    return (
        <NavigatorNotificationCard
            {...props}
            icon={newSessionIcon}
            secondary={props.instance.title}
            actionRoute={`/session_details/${props.instance.id}`}
            buttonText={"Check"}

        />
    )
}

function HWNotificationCard(props) {
    return (
        <NavigatorNotificationCard
            {...props}
            icon={hwIcon}
            secondary={props.instance.session.title}
            actionRoute={`/questions/${props.instance.id}`}
            buttonText={"View"}
        />
    )
}

export default {
    ExamNotificationCard, NewVideoNotificationCard, NewSessionNotificationCard, ZoomLinkNotificationCard,
    QuizNotificationCard, NewChapterNotificationCard, MaterialNotificationCard, HWNotificationCard
};