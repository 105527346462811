import React, {useRef, useState} from "react";
import styles from "./OnGoingStudentQuestions.module.css";
import QuestionCard from "../../Common/QuestionCard";
import {Empty} from "antd";
import SecondaryButton from "../../../../common/Button/SecondaryButton";
import PrimaryButton from "../../../../common/Button/PrimaryButton";
import QuestionsGrid from "../../Common/QuestionsGrid";
import {CSSTransition} from "react-transition-group";
import DurationCounter from "../../Common/DurationCounter";

export default function StudentQuestions({
                                             type,
                                             questions,
                                             selectedChoices,
                                             onChange,
                                             onSubmit,
                                             counterData,
                                             onTimeOut,
                                         }) {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const submitDisabled =
        Object.keys(selectedChoices).length < questions.count;
    const animationStates = {
        forward: styles.slideForward,
        backward: styles.slideBackward,
        idle: "",
    };
    const nodeRef = useRef(null);
    const [inProp, setInProp] = useState(false);

    const [animationDirection, setAnimationDirection] = useState(
        animationStates.current
    );

    function onForwardNavigation() {
        setAnimationDirection(animationStates.forward);
        setTimeout(() => setAnimationDirection(animationStates.idle), 500);
    }

    function onBackwardNavigation() {
        setAnimationDirection(animationStates.backward);
        setTimeout(() => setAnimationDirection(animationStates.idle), 500);
    }

    function onNext() {
        setCurrentQuestionIndex((prev) =>
            prev == questions.count - 1 ? prev : prev + 1
        );
        onForwardNavigation();
    }

    function onBack() {
        setCurrentQuestionIndex((prev) => (prev == 0 ? 0 : prev - 1));
        onBackwardNavigation();
    }

    function onNavigate(i) {
        if (i < currentQuestionIndex) onBackwardNavigation();
        else if (i > currentQuestionIndex) onForwardNavigation();
        else setAnimationDirection(animationStates.idle);

        setCurrentQuestionIndex(i);
    }

    function onSelectChoice(choice) {
        const newChoices = {
            ...selectedChoices,
            [currentQuestionIndex]: choice,
        };
        onChange(newChoices);
        console.log({newChoices});
    }

    return (
        <div className={styles.container}>
            <progress
                className={styles.questionsProgress}
                max={questions.count}
                value={currentQuestionIndex}
            />

            <main>
                <header className={styles.questionNumber}>
                    <strong>Question {currentQuestionIndex + 1}</strong>
                    <span>/{questions.count}</span>
                </header>
                <div className={[styles.content].join(" ")}>
                    {questions.data && questions.data[0] ? (
                        <div className={animationDirection}>
                            <QuestionCard
                                question={questions.data[currentQuestionIndex]}
                                selectedChoice={
                                    selectedChoices[currentQuestionIndex]
                                }
                                onSelect={onSelectChoice}
                            />
                        </div>
                    ) : (
                        <Empty/>
                    )}
                </div>
                <footer className={styles.navigation}>
                    {currentQuestionIndex != 0 && (
                        <SecondaryButton onClick={onBack}>Back</SecondaryButton>
                    )}
                    {currentQuestionIndex == questions.count - 1 ? (
                        <PrimaryButton
                            className={styles.submitButton}
                            onClick={() => onSubmit()}
                            disabled={submitDisabled}
                            tooltip={
                                submitDisabled
                                    ? "Please answer all questions first"
                                    : ""
                            }
                        >
                            Submit
                        </PrimaryButton>
                    ) : (
                        <PrimaryButton onClick={onNext}>Next</PrimaryButton>
                    )}
                </footer>
            </main>
            <section className={styles.questionsContainerBackground}>
                <div className={styles.questionsContainer}>
                    <DurationCounter
                        className={styles.counter}
                        startTimeInSec={counterData.startTimeInSec}
                        durationInSec={counterData.durationInSec}
                        onTimeOut={onTimeOut}
                    />
                    <QuestionsGrid
                        questions={questions}
                        selectedChoices={selectedChoices}
                        onSelect={onNavigate}
                    />
                </div>
            </section>
        </div>
    );
}
