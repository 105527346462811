import React from "react";
import styles from "./Button.module.css";

export default function PrimaryButton({className, ...rest}) {
    return (
        <button
            className={[styles.primaryButton,"noselect", className].join(" ")}
            {...rest}
        />
    )
}