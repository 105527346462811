import React, {useState} from "react";
import {Container, Row, Col, Card, Modal} from "react-bootstrap";
import axiosInstance from "../../../api/axios";
import img from "../../../images/courseImg.png";
import {Form, Input, Button, DatePicker, Checkbox} from "antd";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import "../../../style-sheets/session.css";
import {useParams, useNavigate} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import SubLink from "../../Link";
import MaterialUploader from "./MaterialUploader";
import moment from "moment";

function CreateExam(props) {
    const [disabled, setDisabled] = useState(false);
    const [image, setImage] = useState()
    const [videoModelAnswers, setVideoModelAnswers] = useState({});
    const [documentModelAnswers, setDocumentModelAnswers] = useState({});

    const params = useParams();
    const navigate = useNavigate();
    const dateFormat = "YYYY-MM-DDT00:00";

    const onChange = (value, dateString, setFunction) => {
        console.log("Selected Time: ", value);
        console.log("Formatted Selected Time: ", dateString);
        setFunction(dateString);
    };


    const onFinish = async (data) => {
        setDisabled(true);

        const requestData = {
            ...data,
            course: params.id,
            image: image,
            azhar: data.azhar ?? false,
            start_date: data.start_date?.format(dateFormat),
            end_date: data.end_date?.format(dateFormat),
            start_date_re_exam: data.start_date_re_exam?.format(dateFormat),
            end_date_re_exam: data.end_date_re_exam?.format(dateFormat),
            ans_doc: documentModelAnswers.main?.file,
            ans_video: videoModelAnswers.main?.file,
            ans_doc_re_exam: documentModelAnswers.re?.file,
            ans_video_re_exam: videoModelAnswers.re?.file,
            duration: data.duration * 60,
        }

        console.log({requestData});

        const formData = new FormData();
        for (let key in requestData) {
            if (requestData[key]) formData.append(key, requestData[key]);
        }
        let result = await axiosInstance.post(`/Exam/`, formData).catch((err) => {
            setDisabled(false);
            for (const error in err.response.data) {
                console.log(err.response.data[error][0]);
                setTimeout(() => {
                    toast.error(
                        `${error +
                        " : " +
                        err.response.data[error][0].charAt(0).toUpperCase() +
                        err.response.data[error][0].slice(1)
                        }`
                    );
                }, 500);
            }
            setDisabled(false);
        });
        if (result) {
            console.log(result);
            if (result.data) {
                setTimeout(() => {
                    toast.info(`Exam Created Successfully`);
                }, 500);
                setTimeout(() => {
                    navigate("/home");
                }, 3500);
            }
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Container>
            <Row>
                <Col className="py-4" sm={12}>
                    {" "}
                    <SubLink text="Courses"/>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">Create Exam</span>
                </Col>
            </Row>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
            >

                <Row>
                    <Col sm="6">
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the start date!",
                                },
                            ]}
                        >
                            <Input type="text"/>
                        </Form.Item>

                    </Col>
                    <Col sm="6">
                        <Form.Item
                            name="description"
                            label="Description"
                        >
                            <Input type="text"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the start date!",
                                    type: "object",
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value) Promise.reject();

                                        const startDate = moment().subtract(1, 'days');
                                        if (!startDate) return Promise.resolve();
                                        else if (startDate <= value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "Please enter a valid date"
                                            )
                                        );
                                    }
                                })
                            ]}
                            label="Start Date"
                            name="start_date"
                            layout="vertical"
                        >
                            <DatePicker style={{width: "100%"}}/>
                        </Form.Item>{" "}
                    </Col>
                    <Col sm="6">
                        {" "}
                        <Form.Item
                            label="End Date"
                            name="end_date"
                            layout="vertical"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the end date!",
                                    type: "object",
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value) Promise.reject();

                                        const startDate = getFieldValue("start_date");
                                        if (!startDate) return Promise.reject(
                                            new Error(
                                                "Please fill the start date first"
                                            )
                                        );
                                        if (startDate < value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "The end date can't be before the start date or in the same day!"
                                            )
                                        );
                                    }
                                })
                            ]}
                        >
                            <DatePicker style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the start date!",
                                    type: "object",
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value) Promise.reject();

                                        const examEndDate = getFieldValue("end_date");
                                        if (!examEndDate) return Promise.reject(
                                            new Error(
                                                "Please Fill the exam dates first"
                                            )
                                        );
                                        else if (examEndDate <= value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "the re-exam shouldn't start before the exam!"
                                            )
                                        );
                                    }
                                })
                            ]}
                            label="ReExam Start Date"
                            name="start_date_re_exam"
                            layout="vertical"
                        >
                            <DatePicker style={{width: "100%"}}/>
                        </Form.Item>{" "}
                    </Col>
                    <Col sm="6">
                        {" "}
                        <Form.Item
                            label="ReExam End Date"
                            name="end_date_re_exam"
                            layout="vertical"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the end date!",
                                    type: "object",
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value) Promise.reject();

                                        const startDate = getFieldValue("start_date_re_exam");
                                        if (!startDate) return Promise.reject(
                                            new Error(
                                                "Please fill the start date first"
                                            )
                                        );
                                        else if (startDate < value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "The end date can't be before the start date or in the same day!"
                                            )
                                        );
                                    }
                                })
                            ]}
                        >
                            <DatePicker style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <Form.Item name="duration" label="Duration" rules={[
                            {required: true, message: "Please input duration!"}
                        ]}>
                            <Input min="0" type={"number"} placeholder="Duration in minutes (Ex: 60)"
                                   onWheel={(e) => e.target.blur()}/>
                        </Form.Item>
                    </Col>
                    <Col sm="3">
                        <Form.Item
                            label="Cover Image"
                            required={true}
                        >
                            <input
                                className="questionImageUpload"
                                type="file" onChange={(e) => setImage(e.target.files[0])}/>
                        </Form.Item>

                    </Col>
                    <Col sm="2" className="mt-3">
                        {" "}
                        <Form.Item
                            name="azhar"
                            valuePropName="checked"
                            wrapperCol={{span: 16}}
                        >
                            <Checkbox>
                                Azhar?
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>

                </Row>
                <MaterialUploader
                    videoModelAnswers={videoModelAnswers}
                    setVideoModelAnswers={setVideoModelAnswers}
                    documentModelAnswers={documentModelAnswers}
                    setDocumentModelAnswers={setDocumentModelAnswers}
                    uploadLevels={[
                        {level: "main", name: "Exam Model Answers"},
                        {level: "re", name: "ReExam Model Answers"},
                    ]}
                />
                <Row>
                    <Col className="text-end" md={12}>
                        <Button
                            disabled={disabled}
                            htmlType="submit"
                            className="courseBtn1 mx-3"
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}

export default ErrorHandler(CreateExam, axiosInstance);
