import React, { useState } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Checkbox, Button, message, Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import SubLink from "../Link";
import "../../style-sheets/questions.css";

function CreateQuestions() {
  const type = useSelector((state) => state.questionType.questionType);
  const isAzhar = useSelector((state) => state.azhar.azhar);
  const [choices, setChoices] = useState([{ choice: "", is_answer: false }]);
  const [disabled, setDisabled] = useState(true);
  const [dis, setDis] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isright, setRight] = useState(true);
  const params = useParams();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // setDisabled(true);
    // setLoading(true);
    let obj;
    let result;

    data.choices = choices;
    data.quiz = params.id;
    const formattedChoices = choices.map((it) => {
      const mutIt = { ...it };
      delete mutIt.image;
      return mutIt;
    });
    console.log({ data });
    let answers = data.choices.map((choice) => {
      return choice.is_answer;
    });
    console.log(answers);
    let falseAnswers = answers.filter((answer) => answer == false);
    console.log(falseAnswers);
    if (answers.length === falseAnswers.length) {
      setRight(false);
    } else {
      setRight(true);
      if (type === "exam") {
        obj = {
          question: {
            points: data.points,
            question: data.question,
            exam: params.id,
          },
          choices: formattedChoices,
        };
      } else {
        obj = {
          question: {
            points: +data.points,
            question: data.question,
            quiz: params.id,
          },
          choices: formattedChoices,
        };
      }
    }
    const fd = new FormData();
    console.log({ obj: JSON.stringify(obj) });
    fd.append("question", JSON.stringify(obj.question));
    fd.append("choices", JSON.stringify(obj.choices));

    if (data.question_img[0]) fd.append("question_img", data.question_img[0]);
    choices.forEach((it, i) => {
      console.log(it.image);
      if (it.image) fd.append(`choice_img_${i}`, it.image);
    });

    console.log({ formData: fd.get("question_img") });
    result = await axiosInstance.post(`/Question/`, fd).catch((err) => {
      console.log({ err });

      err.response.data.choices.forEach((it, i) => {
        setTimeout(() => {
          toast.error(it.question[0]);
        }, 500);
      });

      setDisabled(false);
      setLoading(false);
    });

    setDisabled(false);
    setLoading(false);

    if (result) {
      setTimeout(() => {
        toast.info("Question has been created successfully");
      }, 500);
    }
  };

  const handleAddClick = () => {
    let choicesList = [...choices];
    setChoices([...choicesList, { choice: "", is_answer: false }]);
  };
  const handleChoice = (e, index) => {
    setDisabled(false);
    const { name, value, checked, files } = e.target;

    const list = [...choices];

    if (name === "is_answer") list[index][name] = checked;
    else if (name === "image") list[index][name] = files[0];
    else list[index][name] = value;
    list[index].choice_img = `choice_img_${index}`;
    console.log({ currentChoice: list[index] });
    setChoices(list);
    let trueAns = list.filter((x) => x.is_answer == true);
    console.log(trueAns);
    if (trueAns.length > 1) {
      // e.target.checked = false;
      setDisabled(true);
      message.error("You can't choose more than one correct answer!");
    }
  };

  return (
    <div className="">
      <Container>
        <Row>
          <Col className="py-4" sm={12}>
            {" "}
            <SubLink
              text={`${type === "exam" ? "Exams" : "Quizes/Homework"}`}
            />
            {/* <span className="title1">Quizes/Homework</span> */}
            <i class="fas fa-chevron-right px-3 title2"></i>
            <span className="title2">Create Question </span>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg={6}>
                  <label className="label">Question</label>
                  <textarea
                    rows={5}
                    type="text"
                    {...register("question", { required: true })}
                    placeholder="type your question here.."
                  />
                  <input
                    className="questionImageUpload"
                    accept="image/*"
                    type="file"
                    {...register("question_img")}
                  />
                  <p className="error">
                    {errors.question?.type === "required" &&
                      "This field is required"}
                  </p>
                </Col>

                <Col lg={6}>
                  <label className="label">Points</label>
                  <React.Fragment>
                    {" "}
                    <input
                      type="number"
                      {...register("points", { required: true, min: 1 })}
                    />
                    <p className="error">
                      {errors.points?.type === "required" &&
                        "This field is required"}
                    </p>
                  </React.Fragment>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  {choices.map((choice, i) => {
                    return (
                      <React.Fragment>
                        <label>Choice {i + 1}</label>

                        <input
                          name="choice"
                          type="text"
                          onChange={(e) => handleChoice(e, i)}
                        />
                        <input
                          className="questionImageUpload"
                          type="file"
                          name="image"
                          accept="image/*"
                          onChange={(e) => handleChoice(e, i)}
                        />
                        <Checkbox
                          name="is_answer"
                          onChange={(e) => handleChoice(e, i)}
                          disabled={dis}
                        >
                          Correct Answer
                        </Checkbox>

                        <div className="btn-box">
                          {choices.length - 1 === i && (
                            <Button
                              className="courseBtn1 my-2"
                              onClick={handleAddClick}
                            >
                              Add Choice
                            </Button>
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </Col>
              </Row>

              <Button
                disabled={disabled}
                className="courseBtn1"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                {loading ? <Spin tip="loading..." /> : "Create"}
              </Button>
              {!isright ? (
                <p className="error">choose correct answer first!</p>
              ) : null}
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ErrorHandler(CreateQuestions, axiosInstance);
