import React, { useState } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Form, Input, Button } from "antd";
import {  toast } from "react-toastify";

function AddReview({ show, hide, getData, id }) {
  const [review, setReview] = useState("");
  const [disabled, setDisabled] = useState(true);
  const submit = async (data) => {
    console.log(data);
    let result = await axiosInstance
      .post(`/Review/`, {
        course: id,
        review: review
      })
      .catch((err) => {
        console.log(err.response);
      });
    if (result) {
      console.log(result);
      if (result.data) {
        hide();
        setReview("");
        setDisabled(false);
        setTimeout(() => {
          toast.info(`Review has been added Successfully`);
        }, 500);
        getData();
      }
    }
  };
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Container className="p-4">
          <Row>
            <Col className="text-center" sm={12}>
              {" "}
              <h6>Review this course</h6>
            </Col>
          </Row>
          <Row>
            <Input.TextArea
              as={10}
              value={review}
              onChange={(e) => {
                setReview(e.target.value);
                setDisabled(false);
              }}
              placeholder="Write your review here.."
            />
          </Row>
          <Row className="my-2">
            <Col className="text-center" sm="12">
              <Button
                disabled={disabled}
                className="courseBtn1"
                onClick={submit}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal>
    </>
  );
}

export default ErrorHandler(AddReview, axiosInstance);
