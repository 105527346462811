import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//redux
import allReducers from "./global-state/reducers/rootReducer";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import promiseMiddleware from "redux-promise";
/* LocalStorage Functions */
import { loadState } from "./global-state/LocalStorage";
import { saveState } from "./global-state/LocalStorage";

const persistedState = loadState();
const middleware = [thunk];
const store = createStore(
  allReducers,

  persistedState,
  composeWithDevTools(applyMiddleware(promiseMiddleware, ...middleware))
);

store.subscribe(() => {
  saveState({
    auth: store.getState().auth,
    quizID: store.getState().quizID,
    azhar: store.getState().azhar,
    questionType: store.getState().questionType
  });
});

// IMPORTS...

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("../firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}

ReactDOM.render(
  <React.StrictMode>
    {/* <Suspense fallback="loading"> */}
    <Provider store={store}>
      <App />
    </Provider>
    {/* </Suspense> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
