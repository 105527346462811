import React from "react";
import {Spin} from "antd";

export default function LoadingPage() {
    return (
        <div style={{
            width: "100%", textAlign: "center",
            overflow: "hidden", padding: "200px 64px"
        }}>
            <Spin/>
        </div>
    )
}