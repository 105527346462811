import React from 'react'
import { Button } from 'react-bootstrap'
import ScratchCard from '../card/ScratchCard'
import styles from './ScratchCodesLayout.module.css'
export default function ScratchCodesLayout({ codes }) {
    return (
        <div className={styles.cardsGrid}>
            {
                codes.map(code => {
                    console.log(code)
                    return <ScratchCard key={code.code} code={code} />
                })
            }
            <Button className="printBtn" onClick={() => window.print()}>
                <span class="material-symbols-rounded">
                    print
                </span>
            </Button>
        </div>
    )
}
