import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Form, Input, Button } from "antd";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { useParams, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import ScratchCodesLayout from "./code/layout/ScratchCodesLayout";
import "../../style-sheets/code.css"
function GenerateCode() {

  const [codes, setCodes] = useState(undefined);
  const [formData, setFormData] = useState({ count: 1, edu_lvl: 1 });

  const getCodes = (data) => {
    console.log(data)
    axiosInstance.post(`/generator`, data)
      .then((res) => {
        setCodes(res.data);
      })
      .catch((err) => { });
  };

  function onSubmitCodesData(e) {
    getCodes(formData);
  }




  return (
    <div>
      <Container>

        <div className="title1 title-codes">
          My Codes
        </div>
        {
          codes ? <ScratchCodesLayout codes={codes} /> :
            <div className="codeFormContainer">
              <Form
                onFinish={onSubmitCodesData}
              >
                <label
                  label="Education Level"
                  name="edu_lvl"
                  layout="vertical"
                  className="formInputLabel"
                >
                  Education Level
                </label>
                <select
                  className="formTextInput"
                  type="number" min="1" max="3" name="edu_lvl" value={formData.value} onChange={(e) => setFormData({ ...formData, edu_lvl: e.target.value })} >
                  <option value="1">1st Secondary</option>
                  <option value="2">2nd Secondary</option>
                  <option value="3">3rd Secondary</option>

                </select>
                <label
                  label="Number of Codes"
                  name="count"
                  layout="vertical"
                  className="formInputLabel"
                  rules={[{
                    required: true, message: "Please input number of codes!"
                  }]}
                >
                  Number of Codes
                </label>
                <div className="counter">
                  <button type="button" onClick={() => setFormData({ ...formData, count: formData.count > 1 ? formData.count - 1 : 1 })}>-</button>
                  <input
                    className="formInput"
                    type="number" min="1" name="count" value={formData.count} onChange={(e) => setFormData({ ...formData, count: +e.target.value })} />
                  <button onClick={() => setFormData({ ...formData, count: formData.count + 1 })}
                    type="button"
                  >+</button>
                </div>

                <div className="defaultCountersGroup">
                  {[50, 100, 150, 200].map((count, index) => (
                    <button className={formData.count == count ? "selected" : ""}
                      type="button"
                      onClick={() => setFormData({ ...formData, count })}>
                      {count}
                    </button>
                  ))}
                </div>

                <div className="codesSubmitButton">
                  <button
                    className="codesSubmitButton"
                    type="submit">Generate Codes</button>
                </div>

              </Form>
            </div>
        }

      </Container>

    </div>
  )
}

export default ErrorHandler(GenerateCode, axiosInstance);