import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance from "../../api/axios";
import { useEffect, useState, useRef } from "react";
import { Player } from "video-react";
/**
 * These hooks re-implement the now removed useBlocker and usePrompt hooks in 'react-router-dom'.
 * Thanks for the idea @piecyk https://github.com/remix-run/react-router/issues/8139#issuecomment-953816315
 * Source: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874#diff-b60f1a2d4276b2a605c05e19816634111de2e8a4186fe9dd7de8e344b65ed4d3L344-L381
 */
import { useContext, useCallback } from "react";
import {
  UNSAFE_NavigationContext as NavigationContext,
  useParams,
} from "react-router-dom";
/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
export function useBlocker(blocker, when = true, videoPlayer, id) {
  const { navigator } = useContext(NavigationContext);

  const doSomething = async (videoPlayer, id) => {
    console.log("firevideooooo2");

    let putCurrentTime = await axiosInstance
      .put(`/StudentSession/${id}`, {
        current: Math.floor(videoPlayer.current.video.props.player.currentTime),
      })
      .catch((err) => {
        console.log(err.response);
      });
    console.log(putCurrentTime.data);
  };

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          // console.log("firevideooooo");
          doSomething(videoPlayer, id);

          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}
/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
export function usePrompt(message, when = true, videoPlayer, id) {
  const blocker = useCallback(
    (tx) => {
      console.log("usePrompt", message, tx);

      // eslint-disable-next-line no-alert
      if (window.confirm(message)) tx.retry();
    },
    [message]
  );

  useBlocker(blocker, when, videoPlayer, id);
}
function VideoPage() {
  const videoPlayer = useRef(null);
  const params = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [video, setVideo] = useState();

  // useEffect(() => {
  // console.log(videoPlayer);
  // console.log(videoPlayer.current.video.props.player.currentTime);
  // console.log("firevideoPlayer", videoPlayer);

  // return () => {
  //   console.log("firevideoPlayer", videoPlayer);
  // };
  // });

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   window.addEventListener("unload", handleEndConcert);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //     window.removeEventListener("unload", handleEndConcert);
  //     handleEndConcert();
  //   };
  // }, []);
  // const alertUser = (e) => {
  //   console.log("fireeeeeee");
  //   console.log("fireeeeeee2", videoPlayer);
  //   e.preventDefault();
  //   e.returnValue = "";
  // };
  // const handleEndConcert = async () => {
  // if (window.confirm("Are you sure you want to exit?")) {
  //   window.preventDefault();
  // } else {
  //   window.preventDefault();
  // }
  // await fetcher({
  //   url: endConcert(concert.id),
  //   method: "PUT",
  // });
  //   console.log("fireeeeeee");
  //   console.log("fireeeeeee2", videoPlayer);
  //   console.log("fireeeeeee3", video);

  //   let putCurrentTime = await axiosInstance
  //     .get(`/StudentSession/${params.id}`, {
  //       current: videoPlayer.current.video.props.player.currentTime,
  //     })
  //     .catch((err) => {
  //       console.log(err.response);
  //     });
  //   console.log(putCurrentTime.data);
  // };

  const getDetails = async () => {
    let result = await axiosInstance
      .get(`/StudentSession/${params.id}`)
      .catch((err) => {
        console.log(err.response);
      });
    console.log(result.data.session.video);
    setData(result.data);
  };

  useEffect(() => {
    getDetails();
  }, []);

  const formIsDirty = true; // Condition to trigger the prompt.
  usePrompt("Leave screen?", formIsDirty, videoPlayer, params.id);

  return (
    <div>
      <div className="p-5 ">
        <Player
          ref={videoPlayer}
          startTime={data && data.current}
          autoPlay
          playsInline
          src={
            data && " https://chem-back.div-systems.com" + data.session.video
          }
          // data && " https://chem-back.div-systems.com" + data.session.video

          //   src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
        />
      </div>
    </div>
  );
}
export default ErrorHandler(VideoPage, axiosInstance);
