import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { message } from "antd";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { Input, Form } from "antd";
export default function AddComentModal({ show, hide, id, setViewComments }) {
  const [comment, setComment] = useState("");
  const addComment = async () => {
    let result = await axiosInstance.post(`/Comment/`, {
      student: id,
      comment: comment,
    });
    if (result) {
      console.log(result);
      if (result.data) {
        hide();
        message.success("Comment added successfully");
        setViewComments(true);
      }
    }
  };
  return (
    <Modal show={show} onHide={hide}>
      <Container className="p-3">
        <Row>
          <Col className="text-center" sm={12}>
            {" "}
            <h5>Add Comment</h5>
          </Col>
        </Row>
        <Row>
          <Col className="text-center" md={12}>
            <Form>
              <Input
                className=""
                value={comment}
                placeholder="Drop your comment here...."
                onChange={(e) => setComment(e.target.value)}
              />
            </Form>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="text-center mt-3" sm={12}>
            {" "}
            <Button
              disabled={comment == "" ? true : false}
              className="courseBtn1"
              onClick={addComment}
            >
              Submit
            </Button>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col className="text-center mt-3" sm={12}>
            {" "}
            <Button className="courseBtn1" onClick={() => setViewComments()}>
              ViewComments
            </Button>
          </Col>
        </Row>

        {/* {[1, 2, 3, 4].map((comment) => (
          <Container className="p-3 border mb-3">
            <h5>{comment}</h5>
            <p className="mb-0">{comment}</p>
          </Container>
        ))} */}
      </Container>
    </Modal>
  );
}
