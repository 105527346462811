import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { LogOut } from "../../global-state/actions/authAction";
import "../../style-sheets/side-menu.css";
import logo from "../../images/logo.svg";
import mini from "../../images/mini.svg";
import home from "../../images/home.svg";
import wallet from "../../images/wallet.svg";
import gift from "../../images/gift.svg";
import notify from "../../images/notify.svg";
import session from "../../images/session.svg";
import profile from "../../images/profile.svg";
import logout from "../../images/logout.svg";
import exams from "../../images/exams.svg";
import {
  HomeOutlined,
  FundOutlined,
  UserOutlined,
  QrcodeOutlined,
  BellOutlined,
  BankOutlined,
} from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";

const { Sider } = Layout;
const { SubMenu } = Menu;
export default function Sidemenu({ direction }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const user = useSelector((state) => state.auth.user);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <div className="logo" />
      <Menu selectedKeys={location.pathname} theme="light" mode="inline">
        <div className="image-div">
          {collapsed ? (
            <img src={mini} />
          ) : (
            <img className={collapsed ? "" : "logo-animation"} src={logo} />
          )}
          <hr />
        </div>
        <div className="name-div">
          {collapsed ? (
            <div className="name-block">
              <p>
                {user.first_name.charAt(0)} {user.last_name.charAt(0)}
              </p>
            </div>
          ) : (
            <>
              <div className="name-block">
                <p>
                  {user.first_name.charAt(0)} {user.last_name.charAt(0)}
                </p>
              </div>
              <div className="user px-2">
                <p className="mb-3"> {user.first_name}</p>
                <p className="mb-0">{user.last_name}</p>
              </div>
            </>
          )}
          <hr />
        </div>
        {user.user_type === "3" ? (
          <>
            {" "}
            <Menu.Item
              className={location.pathname === "/home" ? "activeItem" : null}
              key="/home"
              icon={<img src={home} />}
            >
              <Link activeClassName={"active"} to={"/home"}>
                Home
              </Link>
            </Menu.Item>
            <Menu.Item
              className={
                location.pathname === `/my_profile/${user.id}`
                  ? "activeItem"
                  : null
              }
              key="/profile"
              icon={<img src={profile} />}
            >
              <Link
                activeClassName={"active"}
                to={`/my_profile/${user.id}`}
              >
                Profile
              </Link>
            </Menu.Item>
            <Menu.Item
              className={
                location.pathname === "/StudentSessions" ? "activeItem" : null
              }
              key="/StudentSessions"
              icon={<img src={session} />}
            >
              <Link activeClassName={"active"} to={"/StudentSessions"}>
                Sessions
              </Link>
            </Menu.Item>
            <Menu.Item
              className={location.pathname === "/exams" ? "activeItem" : null}
              key="/exams"
              icon={<img src={exams} />}
            >
              <Link activeClassName={"active"} to={"/exams"}>
                Exams
              </Link>
            </Menu.Item>
            <Menu.Item
              className={location.pathname === "/points" ? "activeItem" : null}
              key="/points"
              icon={<img src={gift} />}
            >
              <Link activeClassName={"active"} to={"/points"}>
                Points
              </Link>
            </Menu.Item>
            <Menu.Item
              className={
                location.pathname === "/wallet" ? "activeItem wallet" : "wallet"
              }
              key="/wallet"
              icon={<img src={wallet} />}
            >
              <Link activeClassName={"active"} to={"/wallet"}>
                Wallet
              </Link>
            </Menu.Item>
            <Menu.Item
              className={
                location.pathname === "/" ? "activeItem logout" : "logout"
              }
              key="/"
              icon={<img src={logout} />}
              onClick={() => dispatch(LogOut())}
            >
              <Link activeClassName={"active"} to={"/"}>
                Log out
              </Link>
            </Menu.Item>
          </>
        ) : (
          <>
            {" "}
            <Menu.Item
              className={location.pathname === "/home" ? "activeItem" : null}
              key="/home"
              icon={<HomeOutlined />}
            >
              <Link activeClassName={"active"} to={"/home"}>
                Home
              </Link>
            </Menu.Item>
            <Menu.Item
              className={
                location.pathname === "/students" ? "activeItem" : null
              }
              key="/students"
              icon={<UserOutlined />}
            >
              <Link activeClassName={"active"} to={"/students"}>
                Students
              </Link>
            </Menu.Item>
            <Menu.Item
              className={
                location.pathname === "/assistants" ? "activeItem" : null
              }
              key="/assistants"
              icon={<UserOutlined />}
            >
              <Link activeClassName={"active"} to={"/assistants"}>
                Assistants
              </Link>
            </Menu.Item>
            <Menu.Item
              className={location.pathname === "/centers" ? "activeItem" : null}
              key="/centers"
              icon={<BankOutlined />}
            >
              <Link activeClassName={"active"} to={"/centers"}>
                Centers
              </Link>
            </Menu.Item>
            <Menu.Item
              className={
                location.pathname === "/generate_code" ? "activeItem" : null
              }
              key="/generate_code"
              icon={<QrcodeOutlined />}
            >
              <Link activeClassName={"active"} to={"/generate_code"}>
                Codes
              </Link>
            </Menu.Item>
            <Menu.Item
              className={
                location.pathname === "/income_reports" ? "activeItem" : null
              }
              key="/income_reports"
              icon={<FundOutlined />}
            >
              <Link activeClassName={"active"} to={"/income_reports"}>
                Income Reports
              </Link>
            </Menu.Item>
            {/* <Menu.Item
              className={
                location.pathname === "/wallet" ? "activeItem wallet" : "wallet"
              }
              key="/wallet"
              icon={<img src={wallet} />}
            >
              <Link activeClassName={"active"} to={"/wallet"}>
                Wallet
              </Link>
            </Menu.Item> */}
            <Menu.Item
              className={
                location.pathname === "/" ? "activeItem logout" : "logout"
              }
              key="/"
              icon={<img src={logout} />}
              onClick={() => dispatch(LogOut())}
            >
              <Link activeClassName={"active"} to={"/"}>
                Log out
              </Link>
            </Menu.Item>
          </>
        )}
      </Menu>
    </Sider>
  );
}
