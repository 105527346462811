import React, {useEffect, useState} from "react";
import {
    Container,
    Row,
    Col,
    Modal,
    OverlayTrigger,
    Tooltip,
    Button
} from "react-bootstrap";
import axiosInstance, {BASE_URL} from "../../api/axios";
import {Pagination, Spin} from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import {useParams, Link, useLocation} from "react-router-dom";
import DataTable from "react-data-table-component";
import {ToastContainer, toast} from "react-toastify";
import {questionType} from "../../global-state/actions/questionAction";
import {useDispatch} from "react-redux";
import {isAzharAction} from "../../global-state/actions/isAzharAction";
import {useSelector} from "react-redux";
import SubLink from "../Link";

function AllQuizes() {
    const dispatch = useDispatch();
    const azharDispatch = useDispatch();
    let location = useLocation();
    const params = useParams();
    const user = useSelector((state) => state.auth.user);

    const quizTypes = {
        1: "Homework",
        2: "Quiz",
        3: "ReQuiz"
    };

    console.log({user});
    const [columns, setColumns] = useState([
        {
            name: "Quiz Date",
            cell: (row) => <div>{row.created_at?.split("T").join(" ") ?? "-"}</div>,
            right: true,
            selector: "created_at",
            sortable: true,
        },
        {
            name: "Answer Video Link",
            //   selector: "ans_video",
            cell: (row) => (
                <div>
                    {row.ans_video ? (
                        row.ans_video !== null ? (
                            <a href={BASE_URL + row.ans_video} target="_blank">
                                Click Here
                            </a>
                        ) : (
                            "There's no uploaded video yet"
                        )
                    ) : (
                        "-"
                    )}
                </div>
            ),
            right: true
        },
        {
            name: "Quiz Type",
            //   selector: "ans_video",
            cell: (row) => <div>{quizTypes[row.type] ?? "-"}</div>,
            selector: "type",
            sortable: true,
            right: true
        },
        user.user_type == "3"
            ? {
                name: "Grade",
                width: `${user.azhar ? "220px" : ""}`,
                //   selector: "ans_video",
                cell: (row) => (
                    <div>
                        {!(
                            row.student_quiz &&
                            row.student_quiz.question_degree &&
                            row.student_quiz.question_full_mark
                        ) ? (
                            <>-</>
                        ) : (
                            <div>
                                {!row.azhar ? (
                                    `${row.student_quiz.question_degree}/${row.student_quiz.question_full_mark}`
                                ) : (
                                    <span>
                      {`MCQ: ${row.student_quiz.question_degree}/${
                          row.student_quiz.question_full_mark
                      }
                                        ${
                          row.student_quiz.mark
                              ? `, Total Grade: ${row.student_quiz.mark}/${row.student_quiz.full_mark}`
                              : ""
                      }`}
                    </span>
                                )}
                            </div>
                        )}
                    </div>
                ),
                right: true
            }
            : {
                name: "Is Azhar?",
                selector: "azhar",
                cell: (row) => <div>{row.azhar ? "True" : "False"}</div>,
                sortable: true,
                right: true
            },
        {
            name: "Actions",
            width: "220px",
            button: true,
            cell: (row) => (
                <div className="table-data-feature">
                    {user.user_type == "3" ? (
                        <React.Fragment>
                            <OverlayTrigger
                                key={"1"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>View Questions</Tooltip>
                                }
                            >
                                {row.azhar ? (
                                    <Link to={`/quiz_azhar/${row.id}`}>
                                        <i
                                            onClick={() => {
                                                dispatch(questionType("quiz"));
                                            }}
                                            className="actionIcon fas fa fa-eye ps-2"
                                        />
                                    </Link>
                                ) : (
                                    <Link to={`/questions/${row.id}`}>
                                        <i
                                            onClick={() => {
                                                dispatch(questionType("quiz"));
                                            }}
                                            className="actionIcon fas fa fa-eye ps-2"
                                        ></i>
                                    </Link>
                                )}
                            </OverlayTrigger>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <OverlayTrigger
                                key={"2"}
                                placement={"top"}
                                overlay={<Tooltip id={`tooltip-${"top"}`}>Delete Quiz</Tooltip>}
                            >
                                <i
                                    onClick={showDelete}
                                    id={row.id}
                                    className="actionIcon fas fa fa-trash ps-2"
                                ></i>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key={"3"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>Add Questions</Tooltip>
                                }
                            >
                                <Link
                                    to={{
                                        pathname: `/create_questions/${row.id}`,
                                        state: {prevPath: location.pathname}
                                    }}
                                >
                                    <i
                                        onClick={() => {
                                            dispatch(questionType("quiz"));
                                            {
                                                row.azhar
                                                    ? azharDispatch(isAzharAction(true))
                                                    : azharDispatch(isAzharAction(false));
                                            }
                                        }}
                                        className="actionIcon fas fa fa-plus ps-2"
                                    ></i>
                                </Link>
                            </OverlayTrigger>
                            {row.azhar ? (
                                <OverlayTrigger
                                    key={"3"}
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>
                                            Add Sheet Questions
                                        </Tooltip>
                                    }
                                >
                                    <Link
                                        to={{pathname: `/create_sheet_questions/quiz/${row.id}`}}
                                    >
                                        <i className="actionIcon fas fa fa-plus ps-2"></i>
                                    </Link>
                                </OverlayTrigger>
                            ) : null}

                            <OverlayTrigger
                                key={"4"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>View Questions</Tooltip>
                                }
                            >
                                <Link to={`/questions/${row.id}`}>
                                    <i
                                        onClick={() => {
                                            dispatch(questionType("quiz"));
                                        }}
                                        className="actionIcon fas fa fa-eye ps-2"
                                    ></i>
                                </Link>
                            </OverlayTrigger>
                            {row.azhar && <OverlayTrigger
                                key={"4"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>View Sheets</Tooltip>
                                }
                            >
                                <Link to={`/view_quiz_sheets/${row.id}`}>
                                    <i className="actionIcon fas fa fa-eye ps-2"/>
                                </Link>
                            </OverlayTrigger>}
                            {row.ans_video !== null ? null : (
                                <OverlayTrigger
                                    key={"5"}
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id={`tooltip-${"top"}`}>Add Model Answer</Tooltip>
                                    }
                                >
                                    <i
                                        onClick={showAnswerModel}
                                        id={row.id}
                                        className="actionIcon fas fa fa-plus ps-2"
                                    ></i>
                                </OverlayTrigger>
                            )}
                            <OverlayTrigger
                                key={"6"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>View Results</Tooltip>
                                }
                            >
                                <Link
                                    to={`/view_results/${row.id}/quiz/${row.azhar ? "1" : "2"}`}
                                >
                                    <i className="actionIcon fas fa fa-eye ps-2"></i>
                                </Link>
                            </OverlayTrigger>
                        </React.Fragment>
                    )}
                </div>
            )
        }
    ]);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [current, setCurrent] = useState(1);
    const [quizID, setQuizID] = useState("");
    const [show, setShow] = useState("");
    const [showVideo, setShowVideo] = useState("");
    const [material, setMaterial] = useState("");
    const [materialName, setMaterialName] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingSubmit, setloadingSubmit] = useState(false);

    const getQuizes = async () => {
        let result = await axiosInstance.get(
            `/Quiz/?session_id=${params.id}&page=1`
        );
        console.log(result);
        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);

        getQuizes();
    }, []);
    const onPageChange = async (page) => {
        setCurrent(page);
        let result = await axiosInstance.get(
            `/Quiz/?session_id=${params.id}&page=${page}`
        );
        setData(result.data.data);
        setCount(result.data.count);
    };
    const showDelete = (e) => {
        setQuizID(e.target.id);
        setShow(true);
    };
    const close = () => {
        setShow(false);
    };
    const deleteQuiz = async () => {
        let result = await axiosInstance.delete(`/Quiz/${quizID}`);
        if (result) {
            console.log(result);
            setShow(false);
            getQuizes();
        }
    };

    const showAnswerModel = (e) => {
        setQuizID(e.target.id);
        setShowVideo(true);
    };
    const hideMaterial = () => {
        setShowVideo(false);
    };
    const onFileUpload = (e) => {
        setDisabled(false);
        e.preventDefault();
        setMaterial(e.target.files[0]);
        setMaterialName(e.target.files[0].name);
    };
    const addVideo = async () => {
        setDisabled(true);
        setloadingSubmit(true);
        let formdata = new FormData();
        formdata.append("ans_video", material);
        let result = await axiosInstance
            .put(`/Quiz/${quizID}`, formdata)
            .catch((err) => {
                console.log(err.response);
                setDisabled(false);
                setloadingSubmit(false);
            });
        if (result) {
            console.log(result);
            if (result.data) {
                setShowVideo(false);
                setloadingSubmit(false);
                setTimeout(() => {
                    toast.info("A model answer video has been added successfully");
                }, 500);
                getQuizes();
            }
        }
    };
    return (
        <div>
            <div className="">
                <Container>
                    <Row>
                        <Col className="py-4" sm={12}>
                            {" "}
                            <SubLink text="Session"/>
                            <i class="fas fa-chevron-right px-3 title2"></i>
                            <span className="title2">View Quizes/Homework </span>
                        </Col>
                    </Row>
                    {loading ? (
                        <Spin tip="...loading"/>
                    ) : (
                        <>
                            {" "}
                            <Row>
                                <Col style={{textAlign: "center"}} sm={12}>
                                    <DataTable columns={columns} data={data}/>
                                </Col>
                            </Row>
                            <Row className="p-4">
                                {data.length !== 0 ? (
                                    <Pagination
                                        onChange={onPageChange}
                                        defaultCurrent={current}
                                        total={count}
                                        current={current}
                                    />
                                ) : null}
                            </Row>
                        </>
                    )}
                </Container>
            </div>

            <Modal show={showVideo} onHide={hideMaterial}>
                <Container className="p-4">
                    <Row>
                        <Col className="text-center" sm={12}>
                            {" "}
                            <h6>Add Video Model Answer</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center py-3" md={12}>
                            <div class="wrapper">
                                <div class="file-upload2">
                                    <input onChange={onFileUpload} type="file"/>
                                    <i class="fa fa-plus pe-2"></i>Upload Here
                                </div>
                            </div>
                            <p>{materialName}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" md={12}>
                            <Button
                                disabled={disabled}
                                onClick={addVideo}
                                className="courseBtn1 mx-3"
                            >
                                {loadingSubmit ? <Spin tip="loading..."/> : "Submit"}
                            </Button>
                            {/* <Button className="courseBtn1" onClick={hideMaterial}>
                Cancel
              </Button> */}
                        </Col>
                    </Row>
                </Container>
            </Modal>
            <Modal show={show} onHide={close}>
                <Container className="p-4">
                    <Row>
                        <Col className="text-center" sm={12}>
                            {" "}
                            <h6>Are u sure u want to delete this Quiz?</h6>
                        </Col>
                        <Row>
                            <Col className="text-center" md={12}>
                                <Button onClick={deleteQuiz} className="courseBtn1 mx-3">
                                    Delete
                                </Button>
                                <Button className="courseBtn1" onClick={close}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </Modal>
            <div></div>
        </div>
    );
}

export default ErrorHandler(AllQuizes, axiosInstance);
