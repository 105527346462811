import { QUESTION_TYPE } from "../actions/actionTypes";
const initialState = {
  questionType : ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case QUESTION_TYPE:
      return {
        ...state,
        questionType : action.payload
      };

    
    default:
      return state;
  }
}