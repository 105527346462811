
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { Skeleton, Input } from "antd";
import img from "../../images/profileImg.svg";
import ResetPasswordModal from "../modals/ResetPasswordModal";
import { ToastContainer, toast } from "react-toastify";

function Profile() {
  const [loading, setLoading] = useState(false);
  const [showReset, setShowReset] = useState(false);

  const [data, setData] = useState([]);
  const getStudents = async () => {
    setLoading(true);

    let result;
    result = await axiosInstance.get(`/myprofile`);
    console.log(result);
    if (result) {
      setData(result.data);
    }
  };

  useEffect(() => {
    getStudents();
  }, []);

  return (
    <div>
      {data.length == 0 && loading ? (
        <Skeleton active />
      ) : (
        <div className="ps-5">
          <Row>
            <Col sm="6">
              <p className="title1">Profile</p>
              <div className="d-flex align-items-center">
                <Button className="codeBtn me-2">#{data.code} </Button>
                <div
                  style={{
                    backgroundColor: `${
                      data.status === "1"
                        ? "#18AB56"
                        : data.status === "2"
                        ? "#FFDD2B"
                        : "#EB5757"
                    }`
                  }}
                  className="status"
                ></div>
              </div>
              <p className="studentName"> {data.user?.first_name}</p>
              <p className="studentName">
                {data.user?.middle_name} {data.user?.last_name}
              </p>
              <div className="mt-2">
                <Button
                  className={`eduLevel ${
                    data.edu_lvl == "1"
                      ? "firstBtn"
                      : data.edu_lvl == "2"
                      ? "secBtn"
                      : "thirdBtn"
                  }`}
                >
                  {data.edu_lvl === "1" ? (
                    <p className="pStyle">
                      1<sup>st</sup> sec
                    </p>
                  ) : data.edu_lvl === "2" ? (
                    <p className="pStyle">
                      2<sup>nd</sup> sec
                    </p>
                  ) : (
                    <p className="pStyle">
                      3<sup>rd</sup> sec
                    </p>
                  )}
                </Button>
                {data.attendance_place === "1" ? (
                  <Button
                    style={{
                      border: `solid 2px #f28537`,
                      color: `#FFB37B`
                    }}
                    className="centerBtn"
                  >
                    Center
                  </Button>
                ) : (
                  <Button
                    style={{
                      border: `solid 2px #0058dc`,
                      color: `#94BFFF`
                    }}
                    className="centerBtn mx-2"
                  >
                    Online
                  </Button>
                )}
                <Button
                  style={{
                    border: `${
                      data.azhar ? "solid 2px #b467d9" : "solid 2px #89cc88"
                    }`,
                    color: `${data.azhar ? "#C88BE6" : "#738F4F"}`,
                    width: "unset"
                  }}
                  className="centerBtn"
                >
                  {data.azhar ? "Azhar" : "General"}
                </Button>
              </div>
              <div className="mt-4">
                <p className="personalInfoTitle">Personal info</p>
                <Row className="mt-3">
                  <Col sm="12" md="4">
                    <label className="profileLabel">First Name</label>
                    <Input value={data.user?.first_name} disabled />
                  </Col>
                  <Col sm="12" md="4">
                    <label className="profileLabel">Middle Name</label>
                    <Input value={data.user?.middle_name} disabled />
                  </Col>{" "}
                  <Col sm="12" md="4">
                    <label className="profileLabel">Last Name</label>
                    <Input value={data.user?.last_name} disabled />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm="12" md="4">
                    <label className="profileLabel">Email</label>
                    <Input value={data.user?.email} disabled />
                  </Col>
                  <Col sm="12" md="4">
                    <label className="profileLabel">Phone Number</label>
                    <Input value={data.user?.phone} disabled />
                  </Col>{" "}
                  <Col sm="12" md="4">
                    <label className="profileLabel">Gender</label>
                    <Input
                      value={data.gender == "1" ? "Male" : "Female"}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm="12" md="4">
                    <label className="profileLabel">School</label>
                    <Input value={data.school_name} disabled />
                  </Col>
                  <Col sm="12" md="4">
                    <label className="profileLabel">Governorate</label>
                    <Input value={data.governate} disabled />
                  </Col>{" "}
                </Row>
              </div>
            </Col>
            <Col className="text-end" sm="12" md="6">
              <img src={img} className="img-fuid" alt="chem-profile-img" />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col sm="6">
              {" "}
              <p className="personalInfoTitle">Parents info</p>
              <Row className="mt-1">
                <Col sm="12" md="4">
                  <label className="profileLabel">Mother’s Phone Number</label>
                  <Input value={data.mother_phone} disabled />
                </Col>
                <Col sm="12" md="4">
                  <label className="profileLabel">Father’s Phone Number</label>
                  <Input value={data.father_phone} disabled />
                </Col>{" "}
              </Row>
            </Col>
          </Row>
          <Row className="my-4">
            <Col sm="6">
              <Button onClick={() => setShowReset(true)} className="resetBtn">
                Reset Password
              </Button>
            </Col>
          </Row>
        </div>
      )}
      <ResetPasswordModal show={showReset} hide={() => setShowReset(false)} />
    </div>
  );
}

export default ErrorHandler(Profile, axiosInstance);
