import React, {useState} from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import {useParams} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import SubLink from "../Link";
import {Form, Input, message} from "antd";
import {Button, Container} from "react-bootstrap";
import {toast} from "react-toastify";
import toastErrors from "../../utils/toast-errors";

function CreateSheetQuestions() {
    const [form] = Form.useForm();
    const params = useParams();

    const [loading, setLoading] = useState(false);
    const [doc, setDoc] = useState(null);
    const [docName, setDocName] = useState(null);

    const [video, setVideo] = useState(null);
    const [videoName, setVideoName] = useState(null);

    const {TextArea} = Input;
    const onReset = () => {
        form.resetFields();
        setDoc(null);
        setDocName(null);

        setVideo(null);
        setVideoName(null);
    };

    const onFinish = async (values) => {
        setLoading(true);
        console.log(values);
        let formdata = new FormData();

        formdata.append("question", values.question);
        formdata.append("mark", values.mark);
        formdata.append("answer", values.answer);
        formdata.append("points", values.points);
        formdata.append(`${params.type}_id`, params.id);
        if (doc !== null) {
            formdata.append("ans_doc", doc);
        }
        if (video !== null) {
            formdata.append("ans_video", video);
        }

        let result = await axiosInstance
            .post(`/azhar_sheet`, formdata)
            .catch((err) => {
                toastErrors(err.response.data);
                setLoading(false);
            });

        if (result) {
            console.log(result);
            setLoading(false);
            toast.success("successfully added");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onVideoUpload = (e) => {
        e.preventDefault();
        setVideo(e.target.files[0]);
        setVideoName(e.target.files[0].name);
    };

    const onDocUpload = (e) => {
        e.preventDefault();
        setDoc(e.target.files[0]);
        setDocName(e.target.files[0].name);
    };
    return (
        <div className="">
            <Container>
                <SubLink text="Quizes/Homework"/>
                {/* <span className="title1">Quizes/Homework</span> */}
                <i class="fas fa-chevron-right px-3 title2"></i>
                <span className="title2"> Add Sheet Questions</span>

                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="py-5"
                    form={form}
                >
                    <Form.Item
                        label="Question"
                        name="question"
                        rules={[{required: true, message: "Please input the question"}]}
                    >
                        <TextArea
                            style={{
                                height: 120,
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Answer"
                        name="answer"
                        rules={[{required: true, message: "Please input the answer"}]}
                    >
                        <TextArea
                            style={{
                                height: 120,
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Points"
                        name="points"
                        rules={[{required: true, message: "Please input the points"}]}
                    >
                        <Input type={"number"} min={1}/>
                    </Form.Item>
                    <Form.Item
                        label="Mark"
                        name="mark"
                        rules={[{required: true, message: "Please input the mark"}]}
                    >
                        <Input type={"number"} min={1}/>
                    </Form.Item>

                    <Form.Item
                        label="Upload Video Model Answers
            "
                        // name="edu_lvl"
                        layout="vertical"
                    >
                        <div class="wrapper">
                            <div class="file-upload">
                                <input onChange={onVideoUpload} type="file"/>
                                <i class="fa fa-plus"></i>
                            </div>
                        </div>
                        <p>{videoName}</p>
                    </Form.Item>

                    <Form.Item
                        label="Upload Document Model Answers
            "
                        // name="edu_lvl"
                        layout="vertical"
                    >
                        <div class="wrapper">
                            <div class="file-upload">
                                <input onChange={onDocUpload} type="file"/>
                                <i class="fa fa-plus"></i>
                            </div>
                        </div>
                        <p>{docName}</p>
                    </Form.Item>

                    <Button disabled={loading} className="courseBtn1" type="submit">
                        Create
                    </Button>
                    <Button
                        htmlType="button"
                        onClick={() => onReset()}
                        className="courseBtn1 mx-4"
                    >
                        Clear
                    </Button>
                </Form>
            </Container>
        </div>
    );
}

export default ErrorHandler(CreateSheetQuestions, axiosInstance);
