import React, { useState } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Select, Button } from "antd";

var months = [
  { name: "January", number: 1 },
  { name: "February", number: 2 },
  { name: "March", number: 3 },
  { name: "April", number: 4 },
  { name: "May", number: 5 },
  { name: "June", number: 6 },
  { name: "July", number: 7 },
  { name: "August", number: 8 },
  { name: "September", number: 9 },
  { name: "October", number: 10 },
  { name: "November", number: 11 },
  { name: "December", number: 12 },
];

let stages = [
  { name: "First Grade", value: "1" },
  { name: "Second Grade", value: "2" },
  { name: "Third Grade", value: "3" },
];
function IncomeReports() {
  const [centers, setCenters] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);
  const [results, setResults] = useState(0);
  const getCenters = async () => {
    let result = await axiosInstance.get("/Center/");
    if (result) {
      console.log(result.data);
      setCenters(result.data);
    }
  };

  const filter = async () => {
    let result = await axiosInstance.get(
      `/income_report?month=${selectedMonth}&center=${selectedCenter}&stage=${selectedStage}`
    );
    if (result) {
      setResults(result.data);
    }
  };

  const clearFilter = async () => {
    let result = await axiosInstance.get(`/income_report`);

    if (result) {
      setSelectedCenter(null);
      setSelectedMonth(null);
      setSelectedStage(null);
      setResults(result.data);
    }
  };

  const handleChange = (e, setFunction) => {
    console.log(e);
    setFunction(e);
  };

  useEffect(() => {
    getCenters();
  }, []);
  return (
    <Container>
         <Row>
            <Col className="py-4" sm={6}>
              {" "}
              <span className="title1">My Income Report</span>
            </Col>
          </Row>

      <Row>
        <Col sm="4">
          <Col sm="12">
            <Select
              className="my-2"
              onChange={(e) => handleChange(e, setSelectedMonth)}
              placeholder="Select Month"
              value={selectedMonth}
            >
              {months.map((month) => {
                return (
                  <Select.Option value={month.number}>
                    {month.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
          <Col sm="12">
            <Select
              className="my-2"
              onChange={(e) => handleChange(e, setSelectedCenter)}
              placeholder="Select Center"
              value={selectedCenter}
            >
              {centers?.map((center) => {
                return (
                  <Select.Option value={center.id}>
                    {center.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
          <Col sm="12">
            <Select
              className="my-2"
              onChange={(e) => handleChange(e, setSelectedStage)}
              placeholder="Select Level"
              value={selectedStage}
            >
              {stages?.map((stage) => {
                return (
                  <Select.Option value={stage.value}>
                    {stage.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
        </Col>
        <Col className="filterButtons" sm="8">
          <Button className="mx-1" onClick={clearFilter}>
            Clear Filter
          </Button>
          <Button className="courseBtn1" onClick={filter}>
            Apply Filters
          </Button>
        </Col>
      </Row>

      <Row className="my-5">
        <Col>
          <strong>Total Income :</strong> EGP {results}{" "}
        </Col>
      </Row>
    </Container>
  );
}

export default ErrorHandler(IncomeReports, axiosInstance);
