import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import axiosInstance from "../../api/axios";
import { Empty, Modal, Pagination } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { Collapse } from "antd";

const { Panel } = Collapse;
function ViewComments({ show, hide, id, update }) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);

  const getComments = async () => {
    let result = await axiosInstance.get(`/Comment/?student=${id}&page=1`);
    if (result) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };
  useEffect(() => {
    getComments();
  }, [update]);

  const onPageChange = async (page) => {
    setCurrent(page);
    let result = await axiosInstance.get(
      `/Comment/?student=${id}&page=${page}`
    );
    setData(result.data.data);
    setCount(result.data.count);
  };
  return (
    <Modal visible={show} onCancel={hide} size={"lg"}>
      <div className="p-3">
        <Row>
          <Col className="text-center" sm={12}>
            {" "}
            <span className="title2">Comments</span>
          </Col>
        </Row>
        <Row>
          {data.length > 0 ? (
            data.map((comment) => {
              return (
                <Col sm="12">
                  <p className="mb-0">
                    <strong> Created by {comment.writer.first_name}</strong>
                  </p>
                  <p className="mb-0">
                    <strong> {comment.date.split("T")[0]}</strong>
                  </p>

                  <p>{comment.comment}</p>
                </Col>
              );
            })
          ) : (
            <Empty />
          )}
        </Row>
        <Row className="p-4">
          {data.length !== 0 && count >= 10 ? (
            <Pagination
              onChange={onPageChange}
              defaultCurrent={current}
              total={count}
              current={current}
            />
          ) : null}
        </Row>
      </div>
    </Modal>
  );
}

export default ErrorHandler(ViewComments, axiosInstance);
