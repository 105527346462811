import React from 'react'
import styles from "./PaymentOptions.module.css"

function PaymentOption({ icon, text, selected, onSelect }) {
    return (
        <button className={`${styles.optionButton} ${selected ? styles.optionButtonSelected : ""}`}
            onClick={onSelect}
        >
            {icon}{text}
        </button>
    )
}

export default function PaymentOptions({ options, selectedOptionIndex, setSelectedOption }) {


    return (
        <div className={styles.optionsContainer}>
            {options.map((option, i) => (
                <PaymentOption
                    key={option.name}
                    icon={option.icon} text={option.name}
                    selected={selectedOptionIndex == i}
                    onSelect={() => setSelectedOption(i)}
                />
            ))}
        </div>
    )
}
