import React, {useEffect, useState} from "react";
import "react-circular-progressbar/dist/styles.css";
import styles from "./DurationCounter.module.css";
import {CircularProgressbar, buildStyles} from "react-circular-progressbar";

export default function DurationCounter({
                                            className,
                                            startTimeInSec,
                                            durationInSec,
                                            onTimeOut,
                                        }) {
    const [timeSpentInSec, setTimeSpentInSec] = useState(startTimeInSec ?? 0);
    const [intervalId, setIntervalId] = useState();
    useEffect(() => {
        const id = setInterval(() => {
            setTimeSpentInSec((prev) =>
                prev >= durationInSec ? durationInSec : prev + 1
            );
        }, 1000);
        setIntervalId(id);
    }, []);

    useEffect(() => {
        if (timeSpentInSec >= durationInSec) {
            clearInterval(intervalId);
            onTimeOut?.();
        }
    }, [timeSpentInSec]);

    const isNearEnd = () => durationInSec - timeSpentInSec <= timeSpentInSec / 4;
    const formatDate = (timeInSecond) =>
        new Date(timeInSecond * 1000).toISOString().substr(11, 5);

    return (
        <div className={[styles.container, className].join(" ")}>
            <div className={styles.progress}>
                <CircularProgressbar
                    value={timeSpentInSec}
                    maxValue={durationInSec}
                    styles={buildStyles({
                        pathColor: isNearEnd() ? "#FF8600" : "#0AAD8A",
                    })}
                />
                <div
                    className={[
                        styles.counterText,
                        isNearEnd() ? styles.warning : "",
                    ].join(" ")}
                >
                    <header>{formatDate(timeSpentInSec)}</header>
                    <div>from {formatDate(durationInSec)}</div>
                </div>
            </div>
        </div>
    );
}
