import React from 'react'
import styles from "./PaymentPage.module.css"
import PrimaryButton from "../../common/Button/PrimaryButton";
import SecondaryButton from "../../common/Button/SecondaryButton";

export default function ConfirmCard({ message, onConfirm, onBack }) {
    return (
        <div className={styles.confirmCard}>
            <p>{message}</p>
            <div>
                <PrimaryButton className={styles.buttons} onClick={onConfirm}>Confirm</PrimaryButton>
                <SecondaryButton className={styles.buttons} onClick={onBack}>Back</SecondaryButton>
            </div>
        </div>
    )
}
