import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import axiosInstance from "../../../../api/axios";
import {Form, Button, Select, Radio, Spin, Input} from "antd";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import {useParams, useNavigate} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import SubLink from "../../../Link";
import requests from "./requests";
import MaterialUploader from "../MaterialUploader";

function CreateSessionQuiz() {
    const [selectedType, setType] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [videoModelAnswers, setVideoModelAnswers] = useState({});
    const [documentModelAnswers, setDocumentModelAnswers] = useState({});
    const [loading, setLoading] = useState(false);


    const navigate = useNavigate();
    const params = useParams();

    const onFinish = async (data) => {

        const requestData = {
            session: params.id,
            type: data.type,
            azhar: data.azhar ?? false,
            ans_doc: documentModelAnswers.main?.file,
            ans_video: videoModelAnswers.main?.file,
            ans_doc_re_quiz: documentModelAnswers.re?.file,
            ans_video_re_quiz: videoModelAnswers.re?.file,
            duration: data.duration * 60,
        }

        const [result, errors] = await requests.create(axiosInstance, requestData);

        setLoading(true);
        setDisabled(true);

        if (errors) {
            if (errors.response && errors.response.status !== 500) {
                for (const error in errors.response.data) {
                    console.log(errors.response.data[error][0]);
                    setTimeout(() => {
                        toast.error(
                            `${
                                error +
                                " : " +
                                errors.response.data[error][0].charAt(0).toUpperCase() +
                                errors.response.data[error][0].slice(1)
                            }`
                        );
                    }, 500);
                }
                setDisabled(false);
                setLoading(false);
            } else {
                setTimeout(() => {
                    toast.error(`Server Error!`);
                }, 500);
                setDisabled(false);
                setLoading(false);
            }
        }
        if (result.data) {
            setLoading(false);
            console.log(result);
            setTimeout(() => {
                toast.info(`Quiz/Homework Added Successfully`);
            }, 500);
            setTimeout(() => {
                navigate(`/view_quizes/${params.id}`);
            }, 2500);
        }
    };

    const handleType = (e) => {
        setVideoModelAnswers({})
        setDocumentModelAnswers({})
        setType(e);
    };

    return (
        <div className="">
            <Container className="p-4">
                <Row className="mb-3">
                    <Col className="" sm={12}>
                        {" "}
                        <SubLink text="Sessions"/>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">Create Quiz/Homework</span>
                    </Col>
                </Row>

                <Form
                    onFinish={onFinish}
                    layout="vertical"
                >
                    {" "}
                    <Row>
                        <Col sm="6">
                            <Form.Item
                                rules={[
                                    {required: true, message: "Please input Course Title!"}
                                ]}
                                label="Quiz Type"
                                name="type"
                                layout="vertical"
                            >
                                <Select
                                    value={selectedType}
                                    onChange={handleType}
                                    placeholder="Select Quiz Type"
                                >
                                    <Select.Option value="1">Homework</Select.Option>
                                    <Select.Option value="2">Quiz</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm="3">
                            <Form.Item name="duration" label="Duration" rules={[
                                {required: true, message: "Please input duration!"}
                            ]}>
                                <Input type={"number"} placeholder="Duration in minutes (Ex: 60)"
                                       min={"0"} onWheel={(e) => e.target.blur()}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="mt-4 ps-5" sm="3">
                            <Form.Item name="azhar">
                                <Radio.Group>
                                    <Radio value={true}>Azhar?</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                    <MaterialUploader
                        videoModelAnswers={videoModelAnswers}
                        setVideoModelAnswers={setVideoModelAnswers}
                        documentModelAnswers={documentModelAnswers}
                        setDocumentModelAnswers={setDocumentModelAnswers}
                        uploadLevels={[
                            {level: "main", name: selectedType === "2" ? "Quiz Model Answer" : undefined},
                            selectedType === "2" ? {level: "re", name: "ReQuiz Model Answer"} : undefined,
                        ]}
                    />
                    <Row>
                        <Col className="text-center" md={12}>
                            <Button
                                disabled={disabled}
                                htmlType="submit"
                                className="courseBtn1 mx-3"
                            >
                                {loading ? <Spin tip="loading..."/> : "Submit"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>

        </div>
    );
}

export default ErrorHandler(CreateSessionQuiz, axiosInstance);
