import React from 'react'
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import {
    Container,
    Row,
    Col,
    Modal,
    Button,
  } from "react-bootstrap";
function DeleteModal({setShow ,show , hide , text , link ,getData}) {

    const deleteEntity = async()=>{
        let result = await axiosInstance.delete(`${link}`);
        if (result) {
          console.log(result);
          setShow(false);
          getData()
        }
    }

    return (
        <Modal show={show} onHide={hide}>
        <Container className="p-4">
          <Row>
            <Col className="text-center" sm={12}>
              {" "}
              <h6>{text}</h6>
            </Col>
            <Row>
              <Col className="text-center" md={12}>
                <Button onClick={deleteEntity} className="courseBtn1 mx-3">
                  Delete
                </Button>
                <Button className="courseBtn1" onClick={hide}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Row>
        </Container>
      </Modal>
    )
}

export default ErrorHandler(DeleteModal , axiosInstance)