import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { useParams, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Form, Input, Button,Spin } from "antd";
import StudentsInSession from "../Layouts/StudentsInSession";
function ViewCenters() {
  const params = useParams();
  const [columns, setColumns] = useState([
    {
      name: "Center Name",
      selector: "title",
      sortable: true,
      right: true
    },
    {
      name: "Location",
      selector: "location",
      sortable: true,
      right: true
    },
    {
      name: "Phone",
      selector: "phone",
      sortable: true,
      right: true
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <div className="table-data-feature">
          <React.Fragment>
            <OverlayTrigger
              key={"2"}
              placement={"top"}
              overlay={<Tooltip id={`tooltip-${"top"}`}>Delete Center</Tooltip>}
            >
              <i
                onClick={showDelete}
                id={row.id}
                className="actionIcon fas fa fa-trash ps-2"
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              key={"2"}
              placement={"top"}
              overlay={<Tooltip id={`tooltip-${"top"}`}>Edit Center</Tooltip>}
            >
              <i
                onClick={showEditModel}
                id={row.id}
                className="actionIcon fas fa fa-edit ps-2"
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              key={"2"}
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-${"top"}`}>
                  Show students in center
                </Tooltip>
              }
            >
              <i
                onClick={showStudentModel}
                id={row.id}
                className="actionIcon fas fa fa-eye ps-2"
              ></i>
            </OverlayTrigger>
          </React.Fragment>
        </div>
      )
    }
  ]);
  const [data, setData] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [show, setShow] = useState("");
  const [centerID, setCenterID] = useState("");
  const [showEdit, setShowEdit] = useState("");
  const [showStudents, setShowStudents] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCenters = async () => {
    let result = await axiosInstance.get(`/Center/`);
    console.log(result);
    if (result) {
      setData(result.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getCenters();
  }, []);

  const hideCreate = () => {
    setShowCreate(false);
  };
  const showCreateModel = () => {
    setShowCreate(true);
  };
  const onFinish = async (data) => {
    console.log(data);
    let result = await axiosInstance.post("/Center/", data).catch((err) => {
      console.log(err);
    });
    if (result) {
      if (result.data) {
        setShowCreate(false);
        getCenters();
      }
    }
  };
  const showDelete = (e) => {
    setShow(true);
    setCenterID(e.target.id);
  };
  const close = () => {
    setShow(false);
  };
  const deleteCenter = async () => {
    let result = await axiosInstance.delete(`/Center/${centerID}`);
    if (result) {
      console.log(result);
      setShow(false);
      getCenters();
    }
  };
  const showEditModel = (e) => {
    setShowEdit(true);
    setCenterID(e.target.id);
  };
  const closeEdit = () => {
    setShowEdit(false);
  };

  const editCenter = async (data) => {
    console.log(data);
    let result = await axiosInstance
      .put(`/Center/${centerID}`, data)
      .catch((err) => {
        console.log(result);
      });
    if (result) {
      setShowEdit(false);
      getCenters();
    }
  };

  const showStudentModel = (e) => {
    console.log(e.target.id);
    setCenterID(e.target.id);
    setShowStudents(true);
  };
  const closeShowStudent = () => {
    setShowStudents(false);
  };

  return (
    <div>
      <div className="">
        <Container>
          <Row>
            <Col className="py-4" sm={6}>
              {" "}
              <span className="title1">All Centers</span>
            </Col>
            <Col className="py-4 text-end" sm={6}>
              {" "}
              <Button onClick={showCreateModel} className="courseBtn1">
                + Create New Center
              </Button>
            </Col>
          </Row>
          {loading ? (
            <Spin tip="...loading" />
          ) : (
            <>
              {" "}
              <Row>
                <Col style={{ textAlign: "center" }} sm={12}>
                  <DataTable columns={columns} data={data} />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
      <Modal show={show} onHide={close}>
        <Container className="p-4">
          <Row>
            <Col className="text-center" sm={12}>
              {" "}
              <h6>Are u sure u want to delete this center?</h6>
            </Col>
            <Row>
              <Col className="text-center" md={12}>
                <Button onClick={deleteCenter} className="courseBtn1 mx-3">
                  Delete
                </Button>
                <Button className="courseBtn1" onClick={close}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Row>
        </Container>
      </Modal>
      <Modal show={showCreate} onHide={hideCreate}>
        <Container className="p-4">
          <Row>
            <Col className="text-center" sm={12}>
              {" "}
              <h6>Create New Center</h6>
            </Col>
          </Row>
          <Row>
            {" "}
            <Form
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Form.Item
                rules={[
                  { required: true, message: "Please input Center Name!" }
                ]}
                label="Center Name"
                name="title"
                layout="vertical"
              >
                <Input
                  className="loginInput"
                  placeholder="Center Name"
                  type="text"
                />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Please input Center phone!" }
                ]}
                label="Phone"
                name="phone"
                layout="vertical"
              >
                <Input
                  className="loginInput"
                  placeholder="Phone"
                  type="number"
                />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Please input Center location!" }
                ]}
                label="Location"
                name="location"
                layout="vertical"
              >
                <Input
                  className="loginInput"
                  placeholder="Location"
                  type="text"
                />
              </Form.Item>
              <Row>
                <Col className="text-center" md={12}>
                  <Button htmlType="submit" className="courseBtn1 mx-3">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </Modal>

      <Modal show={showEdit} onHide={closeEdit}>
        <Container className="p-4">
          <Row>
            <Col className="text-center" sm={12}>
              {" "}
              <h6>Edit Center</h6>
            </Col>
          </Row>
          <Row>
            {" "}
            <Form
              onFinish={editCenter}
              // onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Form.Item label="Center Name" name="title" layout="vertical">
                <Input
                  className="loginInput"
                  placeholder="Center Name"
                  type="text"
                />
              </Form.Item>
              <Form.Item label="Phone" name="phone" layout="vertical">
                <Input
                  className="loginInput"
                  placeholder="Phone"
                  type="number"
                />
              </Form.Item>
              <Form.Item label="Location" name="location" layout="vertical">
                <Input
                  className="loginInput"
                  placeholder="Location"
                  type="text"
                />
              </Form.Item>
              <Row>
                <Col className="text-center" md={12}>
                  <Button htmlType="submit" className="courseBtn1 mx-3">
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </Modal>
      <StudentsInSession
        id={centerID}
        show={showStudents}
        hide={closeShowStudent}
      />
    </div>
  );
}

export default ErrorHandler(ViewCenters, axiosInstance);
