import React, {useEffect, useRef, useState} from "react";
import styles from "./Notifications.module.css";
import {ReactComponent as NotificationIcon} from "../../../images/notification.svg";
import {ReactComponent as ArrowIcon} from "../../../images/notification-arrow.svg";
import NotificationCard from "./NotificationCard";
import axiosInstance from "../../../api/axios";
import {Spin} from "antd";
import useOnScreen from "../../../hooks/onscreen";

export default function Notifications({className}) {

    const [isCollapsed, setCollapsed] = useState(false);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [notificationData, setNotificationData] = useState({
        count: 0,
        notifications: [],
        lastFetchedPage: 0,
    });

    const notificationTypes = {
        1: NotificationCard.NewChapterNotificationCard,
        2: NotificationCard.ExamNotificationCard,
        3: NotificationCard.NewSessionNotificationCard,
        4: NotificationCard.ZoomLinkNotificationCard,
        5: NotificationCard.NewVideoNotificationCard,
        6: NotificationCard.QuizNotificationCard,
        7: NotificationCard.HWNotificationCard,
        8: NotificationCard.MaterialNotificationCard,
    }

    const lastElementRef = useRef();
    const isLastElementVisible = useOnScreen(lastElementRef);

    async function getNotifications() {
        if (page == notificationData.lastFetchedPage) return;

        setLoading(true);
        const result = await axiosInstance.get(`notification?page=${page}`)
            .catch(error => {
                console.log({error});
                setLoading(false);
            });
        console.log({result})
        if (result.data.data) {
            const newNotifications = result.data.data;
            const count = result.data.count;

            setNotificationData({
                count: page == 1 ? count : notificationData.count,
                notifications: [...notificationData.notifications, ...newNotifications],
                lastFetchedPage: page,
            });

            if (result.data.next)
                setPage(page + 1);

        }
        setLoading(false);
    }

    useEffect(() => {
        getNotifications()
    }, []);

    useEffect(() => {
        if (!loading && isLastElementVisible)
            getNotifications()
    }, [isLastElementVisible])
    return (
        <div className={`${styles.container} ${isCollapsed && styles.collapsed} ${className}`}>
            <header className={`${styles.title} noselect`}
                    onClick={() => setCollapsed(!isCollapsed)}
            >
                <div className={styles.headerIcon}>
                    <NotificationIcon/>
                </div>
                <h1>Notifications</h1>
                {notificationData.count > 0 && <div className={styles.counter}>{notificationData.count}</div>}
                <div className={[styles.arrow, styles.headerIcon].join(" ")}>
                    <ArrowIcon/>
                </div>
            </header>
            {


                <div className={styles.list}>
                    {
                        notificationData.notifications.length > 0 ? notificationData.notifications.map(it => {
                                const Card = notificationTypes[it.notification_type];
                                return <Card key={it.id} {...it}/>
                            })
                            : !loading && <div className={styles.notificationInfoMessage}>No New Notifications.</div>
                    }
                    <div className={styles.notificationInfoMessage}
                         ref={lastElementRef}>
                        {loading && <Spin/>}
                    </div>
                </div>


            }


        </div>
    )
}