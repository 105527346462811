import styles from "./AdminHome.module.css";
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import HomeCourse from "./HomeCourse";
import { Pagination, Select, Tooltip, Empty, Spin } from "antd";
import { useSelector } from "react-redux";
import AddCourse from "../../modals/AddCourse";
import { ReactComponent as GradeOne } from "../../../images/grade/grade-1.svg";
import { ReactComponent as GradeTwo } from "../../../images/grade/grade-2.svg";
import { ReactComponent as GradeThree } from "../../../images/grade/grade-3.svg";

function AdminHome() {
  const user = useSelector((state) => state.auth.user);

  const [courses, setCourses] = useState([]);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [currentEduLevel, setCurrentEduLevel] = useState(0);
  const header = {
    0: (
      <>
        All
        <br />
        Grades
      </>
    ),
    1: <GradeOne />,
    2: <GradeTwo />,
    3: <GradeThree />
  };
  useEffect(() => {
    setLoading(true);
    getCourses();
  }, []);
  const getCourses = async () => {
    let result = await axiosInstance.get(
      `/Course/?page=1${
        currentEduLevel != 0 ? `&edu_lvl=${currentEduLevel}` : ""
      }`
    );
    console.log({ result: result.data.data });
    setLoading(false);
    setCourses(
      result.data.data.map((it) => {
        const date = new Date(it.created_date).toDateString();
        return { ...it, date };
      })
    );
    setCount(result.data.count);
  };

  const updateCourses = (e) => {
    if (e !== "") {
      getCourses();
    }
  };
  const onPageChange = async (page) => {
    setCurrent(page);
    let result = await axiosInstance.get(
      `/Course/?page=${page}${
        currentEduLevel != 0 ? `&edu_lvl=${currentEduLevel}` : ""
      }`
    );
    setCourses(result.data.data);
    setCount(result.data.count);
  };
  useEffect(() => {
    getCourses();
  }, [currentEduLevel]);
  return (
    <>
      <div className={["mt-5", styles.container].join(" ")}>
        <header>
          <h1 className="pageTitle">{header[currentEduLevel]}</h1>
          <select
            value={currentEduLevel}
            onChange={(e) => setCurrentEduLevel(e.target.value)}
          >
            {[
              { value: 0, children: "All Grades" },
              { value: 1, children: "Grade One" },
              { value: 2, children: "Grade Two" },
              { value: 3, children: "Grade Three" }
            ].map((it) => (
              <option
                className={
                  it.value !== currentEduLevel ? styles.selectedEduLevel : ""
                }
                key={it.value}
                {...it}
              />
            ))}
          </select>
        </header>
        <div className="pb-5">
          {courses.length === 0 && loading === false ? (
            <Empty />
          ) : loading ? (
            <Spin tip="Loading...." />
          ) : (
            <div className={styles.courses}>
              {courses.map((course) => {
                return (
                  <HomeCourse
                    updateCourses={updateCourses}
                    data={course}
                    getData={getCourses}
                  />
                );
              })}
            </div>
          )}
        </div>
        <Row className="p-4">
          {courses.length !== 0 ? (
            <Pagination
              onChange={onPageChange}
              defaultCurrent={current}
              total={count}
              current={current}
            />
          ) : null}
        </Row>
      </div>
      {user.user_type === "3" ? null : (
        <>
          {" "}
          <Tooltip>
            <div className="whatsappIcon">
              <a
                href="https://wa.me/+201116797976"
                class="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fab fa-whatsapp whatsapp-icon"></i>
              </a>
            </div>
          </Tooltip>
          <div onClick={() => setShowAdd(true)} className="addBtn">
            <Tooltip title="New Chapter">
              {" "}
              <i class="fas fa-plus"></i>
            </Tooltip>
          </div>
          <AddCourse
            show={showAdd}
            hide={() => setShowAdd(false)}
            getData={getCourses}
          />
        </>
      )}
    </>
  );
}

export default ErrorHandler(AdminHome, axiosInstance);
