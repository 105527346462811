import "./App.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from "./components/pages/auth/Login";
import Registeration from "./components/pages/auth/Registeration";
import {useSelector} from "react-redux";
import AdminHome from "./components/Admin/AdminHome";
import SideMenu from "./components/Layouts/SideMenu";
import CreateCourse from "./components/Admin/CreateCourse";
import CreateSession from "./components/Admin/CreateSession";
import EditSession from "./components/Admin/EditSession";
import AllSessions from "./components/Admin/AllSessions";
import AllAssistants from "./components/Admin/AllAssistants";
import CreateAssistant from "./components/Admin/CreateAssistant";
import AllStudents from "./components/Admin/AllStudents";
import StudentDetailsAdmin from "./components/Admin/StudentDetailsAdmin";
import CreateSessionQuiz from "./components/Admin/Examination/CreateSessionQuiz";
import CreateQuestions from "./components/Admin/CreateQuestions";
import AllQuizes from "./components/Admin/AllQuizes";
import ViewMaterial from "./components/Admin/ViewMaterial";
import ViewCenters from "./components/Admin/ViewCenters";
import GenerateCode from "./components/Admin/GenerateCode";
import Permissions from "./components/Admin/Permissions";
import CreateExam from "./components/Admin/Examination/createExam";
import ViewExams from "./components/Admin/Examination/ViewExams";
import ViewQuestions from "./components/Admin/viewQuestions";
import StudentsScore from "./components/Admin/StudentsScore";
import ViewLogs from "./components/Admin/ViewLogs";
import Reviews from "./components/Admin/Reviews";
import SessionDetails from "./components/Student/SessionDetails";
import IncomeReports from "./components/Admin/incomeReports";
import MarkQuiz from "./components/Admin/Examination/MarkSheet";
import Wallet from "./components/Student/Wallet";
import {Layout} from "antd";
import Home from "./components/Student/Home";
import CreateSheetQuestions from "./components/Admin/CreateSheetQuestions";
import StudentViewAllSession from "./components/Student/StudentViewAllSession";
import VideoPage from "./components/Student/VideoPage";
import OnGoingAzharQuestions from "./components/Student/Questions/Azhar/OnGoingAzharQuestions";
import CreateQues from "./components/Admin/CreateQues";
import Profile from "./components/Student/Profile";
import ExamsPage from "./components/Student/ExamsPage";
import useNotification from "./firebase/use-notifications";
import {ToastContainer} from "react-toastify";
import StudentQuestions from "./components/Student/Questions/Ordinary";
import ForgetPasswordRequest from "./components/pages/auth/ForgetPasswordRequest";
import ForgetPassword from "./components/pages/auth/ForgetPassword";
import StudentReport from "./components/Admin/StudentReport";
import Footer from "./components/Layouts/Footer";
import EditSheets from "./components/Admin/EditSheets";

function App() {
    const isAuth = useSelector((state) => state.auth.isAuth);
    const user = useSelector((state) => state.auth.user);
    const {Content} = Layout;

    const newNotification = useNotification();
    console.log({newNotification});

    return (
        <>
            <ToastContainer position="bottom-right"/>
            <Router>
                <div className="App">
                    <Layout style={{minHeight: "100vh"}}>
                        {isAuth ? (
                            <>
                                <SideMenu/>

                                <Layout className="site-layout">
                                    <Content className="pagePadding">
                                        <>
                                            <Routes>
                                                {user.user_type === "3" ? (
                                                    <>
                                                        <Route path="/home" element={<Home/>}/>
                                                        <Route path="/sessions" element={<AdminHome/>}/>
                                                        <Route path="/home" element={<AdminHome/>}/>
                                                        <Route path="/exams" element={<ExamsPage/>}/>
                                                        <Route
                                                            path="/exam/:id"
                                                            element={<StudentQuestions.OnGoing type="exam"/>}
                                                        />
                                                        {/*<Route*/}
                                                        {/*    path="/questions/:id"*/}
                                                        {/*    element={<ViewQuestions type="quiz"/>}*/}
                                                        {/*/>*/}
                                                        <Route
                                                            path="/questions/:id"
                                                            element={<StudentQuestions.OnGoing type="quiz"/>}
                                                        />
                                                        <Route
                                                            path="/quiz_azhar/:id"
                                                            element={<OnGoingAzharQuestions type="quiz"/>}
                                                        />
                                                        <Route
                                                            path="/exam_azhar/:id"
                                                            element={<OnGoingAzharQuestions type="exam"/>}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Route path="/home" element={<AdminHome/>}/>
                                                        <Route path="/exams/:id" element={<ViewExams/>}/>
                                                        <Route
                                                            path="/questions/:id"
                                                            element={<ViewQuestions/>}
                                                        />
                                                    </>
                                                )}

                                                <Route
                                                    path={"/Video/:id"}
                                                    element={<VideoPage/>}
                                                />
                                                <Route
                                                    path="/create_course"
                                                    element={<CreateCourse/>}
                                                />
                                                <Route
                                                    path="/create_session/:id"
                                                    element={<CreateSession/>}
                                                />
                                                <Route
                                                    path="/view_sessions/:id"
                                                    element={<AllSessions/>}
                                                />
                                                <Route
                                                    path="/edit_session/:id"
                                                    element={<EditSession/>}
                                                />
                                                <Route
                                                    path="/create_exam/:id"
                                                    element={<CreateExam/>}
                                                />
                                                <Route path="/assistants" element={<AllAssistants/>}/>
                                                <Route
                                                    path="/create_assistant"
                                                    element={<CreateAssistant/>}
                                                />
                                                <Route path="/students" element={<AllStudents/>}/>
                                                <Route
                                                    path="/student_details/:id"
                                                    element={<StudentDetailsAdmin/>}
                                                />
                                                <Route path="/my_profile/:id" element={<Profile/>}/>
                                                <Route
                                                    path="/create_quiz/:id"
                                                    element={<CreateSessionQuiz/>}
                                                />

                                                <Route
                                                    path="/create_questions/:id"
                                                    element={<CreateQuestions/>}
                                                />
                                                <Route
                                                    path="/view_quiz_sheets/:id"
                                                    element={<EditSheets type="quiz"/>}
                                                />
                                                <Route
                                                    path="/view_exam_sheets/:id"
                                                    element={<EditSheets type="exam"/>}
                                                />
                                                <Route
                                                    path="/view_quizes/:id"
                                                    element={<AllQuizes/>}
                                                />
                                                <Route
                                                    path="/create_sheet_questions/:type/:id"
                                                    element={<CreateSheetQuestions/>}
                                                />
                                                <Route
                                                    path="StudentSessions"
                                                    element={<StudentViewAllSession/>}
                                                />

                                                <Route
                                                    path="/view_material/:id"
                                                    element={<ViewMaterial/>}
                                                />
                                                <Route path="/centers" element={<ViewCenters/>}/>
                                                <Route
                                                    path="/generate_code"
                                                    element={<GenerateCode/>}
                                                />
                                                <Route
                                                    path="/Permissions/:id"
                                                    element={<Permissions/>}
                                                />
                                                <Route
                                                    path="/view_results/:id/:type/:azhar"
                                                    element={<StudentsScore/>}
                                                />
                                                <Route
                                                    path="/income_reports"
                                                    element={<IncomeReports/>}
                                                />
                                                <Route path="/logs/:id" element={<ViewLogs/>}/>
                                                <Route path="/reviews/:id" element={<Reviews/>}/>
                                                <Route
                                                    path="/session_details/:id"
                                                    element={<SessionDetails/>}
                                                />
                                                <Route
                                                    path="/mark_azhar_quiz/:student/:id"
                                                    element={<MarkQuiz type="quiz"/>}
                                                />
                                                <Route
                                                    path="/mark_azhar_exam/:student/:id"
                                                    element={<MarkQuiz type="exam"/>}
                                                />
                                                <Route path="/wallet" element={<Wallet/>}/>
                                                <Route path="/ques" element={<CreateQues/>}/>
                                                <Route
                                                    path="/student_report/:id"
                                                    element={<StudentReport/>}
                                                />
                                            </Routes>
                                        </>
                                    </Content>
                                </Layout>
                            </>
                        ) : (
                            <main>
                                <Routes>
                                    {" "}
                                    <Route path="/" element={<Login/>}/>
                                    <Route
                                        path="/forget_password_request"
                                        element={<ForgetPasswordRequest/>}
                                    />
                                    <Route path="/forget-password" element={<ForgetPassword/>}/>
                                    <Route path="/register" element={<Registeration/>}/>
                                </Routes>
                                <Footer/>
                            </main>
                        )}
                    </Layout>
                </div>
            </Router>
        </>
    );
}

export default App;
