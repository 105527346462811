import React, {useState} from "react";
import {Container, Row, Col, Card, Modal} from "react-bootstrap";
import axiosInstance from "../../../api/axios";
import img from "../../../images/courseImg.png";
import {Form, Input, Button} from "antd";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import DeleteModal from "../../modals/deleteModal";
import UpdateCourse from "../../modals/UpdateCourse";
import AddReview from "../../modals/AddReview";
import styles from "./AdminHome.module.css";

export default function HomeCourse(props) {
    const [show, setShow] = useState(false);
    const [courseID, setCourseID] = useState("");
    const [showEdit, setShowEdit] = useState(false);
    const [showReview, setShowReview] = useState(false);
    const [review, setReview] = useState("");
    const [disabled, setDisabled] = useState(true);

    const user = useSelector((state) => state.auth.user);

    const showModal = (e) => {
        setCourseID(e.target.id);
        setShow(true);
    };
    const close = () => {
        setShow(false);
    };

    const showEditModel = (e) => {
        setCourseID(e.target.id);
        setShowEdit(true);
    };
    const closeEdit = () => {
        setShowEdit(false);
    };

    const openReview = (e) => {
        setCourseID(e.target.id);
        setShowReview(true);
    };
    const closeReview = () => {
        setShowReview(false);
    };

    return (
        <div className={["home-courses", styles.card].join(" ")}>
            <Card className="">
                <Card.Body style={{textAlign: "left"}}>
                    <Container fluid>
                        <div className={styles.cardHeader}>
                            <h4> {props.data.title} </h4>
                            {user.user_type == "3" ? null : (
                                <>
                                        <div
                                            id={props.data.id}
                                            onClick={showEditModel}
                                        >
                                            <i
                                                id={props.data.id}
                                                className="fas fa-edit actionIcon"
                                            />
                                        </div>
                                        <div
                                            id={props.data.id}
                                            onClick={showModal}
                                        >
                                            <i
                                                id={props.data.id}
                                                onClick={showModal}
                                                className="fas fa-trash actionIcon"
                                            />
                                        </div>
                                </>
                            )}
                        </div>

                        <Row>
                            <Col sm="12" md="12">
                                <p className="courseDetails">
                                    <i class="fas fa-graduation-cap"></i>{" "}
                                    {props.data.edu_lvl == "1"
                                        ? "First Grade"
                                        : props.data.edu_lvl == "2"
                                            ? "Second Grade"
                                            : "Third Grade"}
                                </p>
                                <p className="courseDetails">
                                    <i class="fas fa-clock"></i> Created at:
                                    <span style={{color: "#262626"}}> {props.data.date}</span>
                                </p>
                            </Col>
                        </Row>

                        {user.user_type == "3" ? (
                            <>
                                {" "}
                                <hr/>
                                <Row>
                                    <div className="actionBtns">
                                        <Link to={`/view_sessions/${props.data.id}`}>
                                            <i className="fas fa-eye "></i> Session
                                        </Link>
                                        {user.attendance_place === "1" ? null : (
                                            <Link to={`/exams/${props.data.id}`}>
                                                <i className="fas fa-eye"></i> Exam
                                            </Link>
                                        )}
                                        <span
                                            style={{cursor: "pointer"}}
                                            onClick={openReview}
                                            id={props.data.id}
                                        >
                      <i className="fas fa-star actionIcon"></i> Add Review
                    </span>
                                    </div>
                                </Row>
                            </>
                        ) : (
                            <>
                                <hr/>
                                <div>
                                    <div className={styles.chapterActions}>
                                        <Link to={`/create_session/${props.data.id}`}>
                                            <i className="fas fa-plus-circle"></i>Create Session
                                        </Link>
                                        <Link to={`/view_sessions/${props.data.id}`}>
                                            <i className="fas fa-eye "></i>View Sessions
                                        </Link>
                                        <Link to={`/create_exam/${props.data.id}`}>
                                            <i id={props.data.id} className="fas fa-plus-circle "></i>Create Exam
                                        </Link>
                                        <Link to={`/exams/${props.data.id}`}>
                                            <i className="fas fa-eye"></i>View Exams
                                        </Link>
                                        <Link className="reviewBtn" to={`/reviews/${props.data.id}`}>
                                            <i className="fas fa-star"></i>Reviews
                                        </Link>
                                    </div>
                                </div>
                            </>
                        )}
                    </Container>
                </Card.Body>
            </Card>

            <DeleteModal
                show={show}
                text="Are u sure u want to delete this Course?"
                hide={close}
                link={`/CourseAdmin/${courseID}`}
                getData={props.getData}
                setShow={setShow}
            />

            <UpdateCourse
                show={showEdit}
                hide={closeEdit}
                id={courseID}
                getData={props.getData}
                setShow={setShowEdit}
            />

            <AddReview
                show={showReview}
                hide={closeReview}
                id={courseID}
                getData={props.getData}
            />

        </div>
    );
}
