import firebase from "firebase/app";
import "firebase/messaging";


const credentials = {
    firebaseConfig: {
        apiKey: "AIzaSyBYMGU0soIp-ZYU-zfTqi2wSIWzhCHqzdQ",
        authDomain: "chemvalley-personal.firebaseapp.com",
        projectId: "chemvalley-personal",
        storageBucket: "chemvalley-personal.appspot.com",
        messagingSenderId: "552347039876",
        appId: "1:552347039876:web:3d99727b75eb0fb751aad1",
        measurementId: "G-QNS7ZV8M4E"
    },
    vapidKey: "BP3LFxp77WPrbXPM3AIcIhaC-_B-j6IDYhiTKIESayfwJg_hi9JbshsNpY_AikuVfOQXJvi1UCTImi-BLFrUUTs"
}

const firebaseConfig = credentials.firebaseConfig;
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

let messaging = firebase.messaging.isSupported() ? firebase.messaging() : undefined;


const getToken = async () => messaging ? await messaging
        .getToken({
            vapidKey: credentials.vapidKey,
        }).catch((err) => console.log("An error occurred while retrieving token. ", err))
    : undefined;


async function sendToken(token) {

}

async function initMessaging() {
    const perm = await Notification.requestPermission();
    if (perm !== 'granted') return;
    const token = await getToken();
    console.log(`token ${token}`)
    await sendToken(token);

}

export function onMessageListener(messageListener) {
    initMessaging().then(() => {
        messaging.onMessage(messageListener)
    });
}