import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import img1 from "../../../images/avecImg.png";
import "../../../style-sheets/loginStyle.css";
import {Form, Input, Button} from "antd";
import {useNavigate} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../../images/logo.png";
import axiosInstance from "../../../api/axios";
import toastErrors from "../../../utils/toast-errors";
import {Result} from "antd";

export default function ForgetPasswordRequest(props) {
    let navigate = useNavigate();

    const [isSuccess, setSuccess] = useState(undefined);

    function getPlaceholder(type) {
        const typeString = type.charAt(0).toUpperCase() + type.slice(1);
        return `${typeString} Password`
    }

    async function onSubmit(values) {

        try {
            const res = await axiosInstance.post("forget-password/", values);
            if (res.data.success)
                setSuccess(res.data.success);

        } catch (error) {
            toastErrors(error.response.data);
        }

    }

    return (
        <div className="loginPage py-5">
            <Container className="loginContainer">
                <Row>
                    <Col sm="3">
                        <img src={logo} alt="chem-valley-logo" className="logo img-fluid"/>
                    </Col>
                </Row>
                <Row>
                    <Col className="imgCol1" sm={6}>
                        <div className="img-box">
                            {" "}
                            <img alt="img-login" src={img1} className="img-fluid loginImg"/>
                        </div>
                    </Col>
                    <Col className="pl-5 text-left" sm={6}>
                        {
                            isSuccess
                                ? <div className="resetSuccess">
                                    <Result status="success" title={isSuccess}/>
                                </div>
                                : <Form
                                    onFinish={onSubmit}
                                    style={{
                                        margin: "30px auto 0px auto",
                                    }}
                                    layout="vertical"
                                >
                                    <h1 className="">Forget Password</h1>

                                    <Form.Item
                                        label={"Email"}
                                        name={"email"}
                                        rules={[
                                            {required: true, message: "Please fill this field!"},
                                        ]}
                                    >
                                        <Input
                                            name={"email"}
                                            placeholder={"Enter your email"}
                                            className="loginInput"
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            className="loginBtn1"
                                            // onClick={onSubmit}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            GET RESET LINK
                                        </Button>
                                    </Form.Item>
                                </Form>
                        }
                    </Col>
                </Row>
                <Row></Row>
            </Container>
        </div>
    );
};
