import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import img1 from "../../../../images/avecImg.png";
import "../../../../style-sheets/loginStyle.css";
import {Form, Input, Button, Result} from "antd";
import {useNavigate, useSearchParams} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../../../images/logo.png";
import axiosInstance from "../../../../api/axios";
import {toast} from "react-toastify";
import toastErrors from "../../../../utils/toast-errors";

export default function ForgetPassword(props) {
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = {
        id: searchParams.get("uid64"),
        token: searchParams.get("token"),
        valid: searchParams.get("token_valid") === "true",
    }

    const [passwords, setPasswords] = useState({new: "", confirm: ""});

    function getPlaceholder(type) {
        const typeString = type.charAt(0).toUpperCase() + type.slice(1);
        return `${typeString} Password`
    }

    async function onSubmit() {
        try {
            const res = await axiosInstance.put("rest_password_view/", {
                uid64: token.id,
                token: token.token,
                password: passwords.new,
            });
            if (res.data.success) {
                toast.success(res.data.success);
                setTimeout(() => navigate("/"), 300);
            }
        } catch (errors) {
            toastErrors(errors.response.data);
        }

    }

    return (
        <div className="loginPage py-5">
            <Container className="loginContainer">
                <Row>
                    <Col sm="3">
                        <img src={logo} alt="chem-valley-logo" className="logo img-fluid"/>
                    </Col>
                </Row>
                <Row>
                    <Col className="imgCol1" sm={6}>
                        <div className="img-box">
                            {" "}
                            <img alt="img-login" src={img1} className="img-fluid loginImg"/>
                        </div>
                    </Col>
                    <Col className="pl-5 text-left" sm={6}>
                        {
                            token.valid ? <Form
                                    onFinish={onSubmit}
                                    style={{
                                        margin: "30px auto 0px auto",
                                    }}
                                    layout="vertical"
                                >
                                    <h1 className="">Forget Password</h1>

                                    {
                                        Object.keys(passwords).map(key => (
                                            <Form.Item
                                                key={key}
                                                label={getPlaceholder(key)}
                                                name={key}
                                                // hasFeedback={key == "confirm"}
                                                rules={key == "new" ? [
                                                        {
                                                            required: true,
                                                            message: "Please confirm your password!"
                                                        }
                                                    ]
                                                    :
                                                    [
                                                        {
                                                            required: true,
                                                            message: "Please confirm your password!"
                                                        },
                                                        ({getFieldValue}) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue("new") === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(
                                                                    new Error(
                                                                        "The two passwords that you entered do not match!"
                                                                    )
                                                                );
                                                            }
                                                        })
                                                    ]
                                                }
                                            >
                                                <Input.Password
                                                    value={passwords[key]}
                                                    name={key}
                                                    onChange={(e) => setPasswords({...passwords, [key]: e.target.value})}
                                                    placeholder={getPlaceholder(key)}
                                                    className="loginInput"
                                                />
                                            </Form.Item>
                                        ))
                                    }
                                    <Form.Item>
                                        <Button
                                            className="loginBtn1"
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            CONFIRM
                                        </Button>
                                    </Form.Item>
                                </Form>
                                : <div className="resetSuccess">
                                    <Result status="error" title={"Invalid Link"}/>
                                </div>
                        }
                    </Col>
                </Row>
                <Row></Row>
            </Container>
        </div>
    );
};
