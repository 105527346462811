import React, {useEffect, useState} from "react";
import StudentQuestions from "./StudentQuestions";
import styles from "./OnGoingStudentQuestions.module.css";
import requests from "./requests";
import {useParams} from "react-router-dom";
import toastErrors from "../../../../../utils/toast-errors";
import ErrorHandler from "../../../../../error-handler/ErrorHandler";
import axiosInstance from "../../../../../api/axios";
import {useSelector} from "react-redux";
import {Spin, Result, Empty} from "antd";
import {toast} from "react-toastify";
import PreviousQuestions from "../../Common/PreviousQuestions";
import LoadingPage from "../../../../common/LoadingPage";
import useScreenShotPreventer from "../../../../../hooks/screenshotpreventer";

function OnGoingStudentQuestions(props) {
    const states = {
        loading: 1,
        normal: 2,
        error: 3,
        success: 4,
        previous: 5,
        empty: 6,
    };

    useScreenShotPreventer();

    const params = useParams();
    const [screenState, setScreenState] = useState(states.loading);
    const [questions, setQuestions] = useState({});
    const [selectedChoices, setSelectedChoices] = useState({});
    const user = useSelector((state) => state.auth.user);
    const [displayError, setDisplayError] = useState();
    const [modelAnswers, setModelAnswers] = useState({});

    // const durationInSec = 60 * 60;
    // const startTimeInSec = 10 * 60;
    // const counterData = { durationInSec, startTimeInSec };

    async function fetch() {
        setScreenState(states.loading);
        const [data, errors, status] = await requests.get(axiosInstance, {
            type: props.type,
            id: params.id,
        });
        console.log({data, errors, status});
        if (errors) {
            if (status === 400) {
                setScreenState(states.error);
                setDisplayError(errors.error ?? "Something went wrong in our side.");
            } else {
                setScreenState(states.error);
                setDisplayError("Something went wrong in our side.");
            }
        } else {
            if (status === 208) {
                setModelAnswers(data);
                setScreenState(states.previous);
            } else {
                setQuestions(data);
                setScreenState(
                    data.data && data.data[0] ? states.normal : states.empty
                );
                console.log({data})
            }
        }
    }

    async function onSubmit(skipEmptyCheck = false) {
        const [data, errors] = await requests.submit(axiosInstance, {
            id: params.id,
            type: props.type,
            questions: questions.data,
            selectedChoices: selectedChoices,
            isAzhar: user.azhar,
            skipEmptyCheck,
        });

        if (errors) toastErrors(errors);
        else {
            skipEmptyCheck ? toast.warn(data) : toast.success(data);
            setScreenState(states.success);
        }
    }

    async function onTimeOut() {
        await onSubmit(true);
    }

    useEffect(() => {
        if (screenState === states.success || screenState === states.loading)
            fetch();
    }, [screenState]);

    switch (screenState) {
        case states.loading:
            return <LoadingPage/>;
        case states.error:
            return (
                <Result
                    status="error"
                    title={"Access Failed"}
                    subTitle={displayError}
                />
            );
        case states.previous:
            return (
                <PreviousQuestions
                    modelAnswers={modelAnswers}
                    type={props.type}
                />
            );
        case states.empty:
            return (
                <div>
                    <Empty/>
                </div>
            );
        default:
            return (
                <StudentQuestions
                    questions={questions}
                    selectedChoices={selectedChoices}
                    onChange={setSelectedChoices}
                    onSubmit={onSubmit}
                    counterData={questions.counter}
                    onTimeOut={onTimeOut}
                    {...props}
                />
            );
    }
}

export default ErrorHandler(OnGoingStudentQuestions, axiosInstance);
