import React, { useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import axiosInstance from "../../api/axios";
import { Form, Input, Button } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { ToastContainer, toast } from "react-toastify";

function AddCourse({ show, hide, getData }) {
  const [disable, setDisabled] = useState(false);
  const onFinish = async (data) => {
    setDisabled(true);
    console.log(data);
    let result = await axiosInstance.post(`/CourseAdmin/`, data).catch((err) => {
      console.log(err.response);
      setDisabled(false);
    });
    if (result) {
      console.log(result);
      if (result.data) {
        console.log(result);
        setTimeout(() => {
          toast.info(`New Chapter has been created Successfully`);
        }, 500);
        hide();
        getData();
        // setDisabled(true);
      }
    }
  };
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Container className="p-4">
          <Row className="mb-3">
            <Col className="" sm={12}>
              <span className="title2">Create New Chapter</span>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Form
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                layout="vertical"
              >
                <Form.Item
                  rules={[
                    { required: true, message: "Please input Course Title!" }
                  ]}
                  label="Title"
                  name="title"
                  layout="vertical"
                >
                  <Input
                    className="loginInput"
                    placeholder="Title"
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Please input Course Level!" }
                  ]}
                  label="Education Level"
                  name="edu_lvl"
                  layout="vertical"
                >
                  <Input
                    className="loginInput"
                    placeholder="Level"
                    type="number"
                    max={"3"}
                  />
                </Form.Item>
                <Row>
                  <Col className="text-center" md={12}>
                    <Button
                      disabled={disable}
                      htmlType="submit"
                      className="courseBtn1 mx-3"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal>

    </>
  );
}

export default ErrorHandler(AddCourse, axiosInstance);
