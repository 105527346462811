import React from "react";
import styles from "./QuestionCard.module.css";

export default function AnswerState({state}) {


    return (
        <div className={[styles.answerState, styles[state]].join(" ")}>
        </div>
    )
}