import React, {useEffect, useState} from "react";
import styles from "./EditSheets.module.css";
import axiosInstance from "../../../api/axios";
import {useParams} from "react-router-dom";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import EditableSheetItem from "./EditableSheetItem";
import toastErrors from "../../../utils/toast-errors";
import LoadingPage from "../../common/LoadingPage";
import {toast} from "react-toastify";
import {Empty} from "antd";

function EditSheets({type}) {
    const params = useParams();
    const [sheets, setSheets] = useState([]);
    const [loading, setLoading] = useState(true);
    const getSheets = async () => {
        setLoading(true)
        try {
            const result = await axiosInstance.get(`admin_azhar_sheet?${type ?? "quiz"}_id=${params.id}`);
            if (result.data) {
                setSheets(result.data);
                setLoading(false);
            }
        } catch (errors) {
            toastErrors(errors)
        }
    }


    const editSheet = async (id, data) => {
        try {
            const result = await axiosInstance.put(`admin_azhar_sheet/${id}`, data);
            if (result.data) {
                toast.success("Sheet edited successfully.");
            }
        } catch (error) {
            toastErrors(error);
        }
    }

    const deleteSheet = async (id) => {
        try {
            const result = await axiosInstance.delete(`admin_azhar_sheet/${id}`);
            if (result) {
                toast.success("Sheet deleted successfully.");
            }
        } catch (error) {
            toastErrors(error);
        }
    }

    useEffect(() => {
        getSheets();
    }, []);
    return (
        <div className={styles.container}>
            <h1>Sheets</h1>
            {
                loading ? <LoadingPage/> :
                    sheets.length === 0 ? <Empty/> : sheets?.map((it, i) =>
                        <EditableSheetItem
                            key={it.id} sheet={it} order={i + 1}
                            onSubmit={editSheet}
                            onDelete={async (id) => {
                                const copy = [...sheets];
                                delete copy[i];
                                setSheets(copy);
                                await deleteSheet(id);
                            }}
                        />
                    )
            }
        </div>
    )
}

export default ErrorHandler(EditSheets, axiosInstance);