import { IS_AZHAR } from "../actions/actionTypes";
const initialState = {
  azhar : ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IS_AZHAR:
      return {
        ...state,
        azhar : action.payload
      };

    
    default:
      return state;
  }
}