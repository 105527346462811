import React from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";

import { Empty } from "antd";

export default function SubscribedSessionModal({ show, hide, data }) {
  return (
    <Modal show={show} onHide={hide}>
      <Container className="p-3">
        <Row>
          <Col className="text-center" sm={12}>
            {" "}
            <h5>{data.user?.first_name} sessions</h5>
          </Col>
        </Row>
        <Row>
          <Col className="" md={12}>
            {data.student_session?.length !== 0 ? (
              data.student_session?.map((session) => {
                return (
                  <p>
                    {" "}
                    <a href={`/edit_session/${session.id}`}>{session.title}</a>
                  </p>
                );
              })
            ) : (
              <>
                {" "}
                <Empty />
                <p>There's no suscribed session for this student</p>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
