import React, { useState } from 'react'
import { Spin, Empty } from "antd";
import Slider from 'react-slick';
import styles from "./ExamsPage.module.css"
import ExamCard from './ExamCard';
function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <i class="fas fa-arrow-right"></i>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <i class="fas fa-arrow-left"></i>
        </div>
    );
}
export default function ExamsCategory({ title, data }) {

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoSlidesToShow: true,
        variableWidth: true,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>
    };

    return (
        <div className={styles.examsCategory}>
            <h1 className="chapterTitle mt-4">{title}</h1>
            <div>
                {data.length == 0 ? (
                    <Empty />
                ) : (
                    <Slider {...settings}>
                        {data.map((it, i) => (
                            <div key={it.id}>
                                <ExamCard slide={it} />
                            </div>
                        ))}
                    </Slider>
                )}
            </div>
        </div>
    )
}
