import React, {useState, useEffect} from "react";
import {Row, Col, Button} from "react-bootstrap";
import axiosInstance from "../../../api/axios";
import {Empty, Modal, Pagination, Select} from "antd";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import "../../../style-sheets/session.css";
import {Collapse} from "antd";
import {ToastContainer, toast} from "react-toastify";
import {useSelector} from "react-redux";


function OpenExamManually({show, hide, id, eduLevel}) {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [current, setCurrent] = useState(1);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const {Option} = Select;

    const getStudents = async () => {
        let result = await axiosInstance.get(`/get_students_not_allowed_to_exam/${id}`);
        console.log(result);
        if (result) {
            setData(result.data);
            setCount(result.data?.length ?? 0);
        }
    };

    useEffect(() => {
        if (show) getStudents();
    }, [show]);

    const handleStudents = (e) => {
        console.log(e);
        setSelectedStudents(e);
    };
    const add = async () => {
        const sentStudent = selectedStudents.map(it => it.split("&")[1]);
        let result = await axiosInstance.put(`/student_exam_bulk_open/${show}`, {
            students: sentStudent,
        });
        console.log(result);


        if (result) {
            if (result.status === 200) {
                setTimeout(() => {
                    toast.info(`Students has been added successfully`);
                }, 500);
                setTimeout(() => {
                    setSelectedStudents([])
                    hide();
                }, 3500);
            }
        }
    };

    return (
        <Modal visible={show} onCancel={hide} size={"lg"}>
            <div className="p-3">
                <Row>
                    <Col className="text-center" sm="12">
                        <p>Select students to open the exam for them</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center" sm={12}>
                        {" "}
                        <Select
                            mode="multiple"
                            onChange={handleStudents}
                            value={selectedStudents}
                            placeholder="Select Students"
                            onClick={getStudents}
                            style={{display: "block", width: 100}}
                            //   onPopupScroll={onScrollStates}
                        >
                            {data.map((student) => {
                                return (
                                    <Option
                                        name={student.user.first_name}
                                        key={student.id}
                                        value={`${student.code}&${student.id}`}
                                    >
                                        {student.user.first_name} {student.user.last_name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-3" sm="12">
                        <Button
                            disabled={selectedStudents.length > 0 ? false : true}
                            className="courseBtn1"
                            onClick={add}
                        >
                            Confirm
                        </Button>
                    </Col>
                </Row>
            </div>

        </Modal>
    );
}

export default ErrorHandler(OpenExamManually, axiosInstance);
