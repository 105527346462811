import React, {forwardRef} from "react";
import styles from "./EditSheets.module.css";


const SheetInput = forwardRef(({label, ...rest}, ref) => {
    return (
        <div className={styles.inputContainer}>
            <label>{label}</label>
            <input ref={ref} {...rest}/>
        </div>
    )
})
export default SheetInput;