import React, {useEffect, useMemo, useState} from "react";
import styles from "./MarkSheet.module.css";
import axiosInstance from "../../../../api/axios";
import {Input, Button, Pagination, Checkbox, Spin, Empty} from "antd";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import {useNavigate, useParams} from "react-router-dom";
import SheetItem from "./SheetItem";
import PrimaryButton from "../../../common/Button/PrimaryButton";
import requests from "./requests";
import toastErrors from "../../../../utils/toast-errors";
import LoadingPage from "../../../common/LoadingPage";
import {toast} from "react-toastify";

function MarkSheet({type}) {
    const [data, setData] = useState([]);
    const [mcqMarks, setMcqMarks] = useState({})
    const [marks, setMarks] = useState({})
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    let params = useParams();

    const getMaxGrade = () => data.reduce((prev, cur) => prev + (+cur.sheet.mark), 0);
    const getFinalGrade = () => Object.values(marks).reduce((prev, cur) => prev + (+cur.result), 0);
    const maximumGrade = useMemo(getMaxGrade, [data]);
    const finalGrade = useMemo(getFinalGrade, [marks]);

    const getSheets = async () => {
        setLoading(true);

        const [result, errors] = await requests.get(axiosInstance, {
            type,
            sheetsId: params.id,
            studentId: params.student
        })

        if (errors)
            toastErrors(errors)
        else if (result) {
            setData(result.data);
            const initialMarks = result.data.reduce((prev, {id, result}, i) => {
                return {
                    ...prev, [i]: {id, result: result ?? 0}
                }
            }, {});
            setMarks(initialMarks);
            setMcqMarks({studentMark: result.studentmark, fullMark: result.full_mark})
        }

        setLoading(false);
    };
    useEffect(() => {
        getSheets();
    }, []);


    async function onSubmit() {
        const [result, errors] = await requests.submit(axiosInstance, {
            type, marks,
            sheetsId: params.id,
            studentId: params.student,
        });
        if (errors) toastErrors(errors)
        else if (result) toast.success(result);
    }


    return (
        <div className={styles.container}>
            {
                loading ? <LoadingPage/> :
                    <>
                        <h4 style={{color: "#001731"}}>
                            <i
                                onClick={() => navigate(-1)}
                                className="fas fa-chevron-left pe-4 pb-4"
                            ></i>
                            Mark Sheets
                        </h4>
                        {data.length === 0 ? <Empty/> :
                            <>
                                <div className={styles.sheetsList}>
                                    {
                                        data.map((sheet, i) =>
                                            <SheetItem key={sheet.id} sheet={sheet}
                                                       value={marks[i]?.result ?? sheet.result}
                                                       onChange={(val) => setMarks({
                                                           ...marks,
                                                           [i]: {id: sheet.id, result: val}
                                                       })}
                                            />)
                                    }
                                </div>

                                <div className={styles.submitGroup}>
                                    <div className={styles.finalGrade}>
                                        <div>MCQ Mark: <span>{mcqMarks.studentMark}</span>/{mcqMarks.fullMark}</div>
                                        <div>
                                            Final
                                            Mark: <span>{finalGrade + mcqMarks.studentMark}</span>/{maximumGrade + mcqMarks.fullMark}
                                        </div>
                                    </div>
                                    <PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
                                </div>
                            </>
                        }
                    </>
            }
        </div>
    );
}

export default ErrorHandler(MarkSheet, axiosInstance);
