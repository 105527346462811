import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axiosInstance from "../../api/axios";
import { Form, Input, Button, Spin } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SubLink from "../Link";

function CreateSession(props) {
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [imageName, setImageName] = useState("");
  const [videoName, setVideoName] = useState("");
  const [videoRender, setVideoRender] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const { TextArea } = Input;

  const removeVideo = () => {
    setVideoRender(true);

    setVideo(null);
    setVideoName("");
    setVideoRender(false);
  };

  const removeImage = () => {
    setImage(null);
    setImageName("");
  };

  const onFinish = async (data) => {
    setLoading(true);

    setDisabled(true);
    let formdata = new FormData();
    if (image !== null) {
      formdata.append("image", image);
    }
    if (video !== null) {
      formdata.append("video", video);
    }
    if (data.zoom_link !== undefined || data.zoom_link == "") {
      formdata.append("zoom_link", data.zoom_link);
    }
    formdata.append("description", data.description);
    formdata.append("price", data.price);
    formdata.append("course", params.id);
    formdata.append("order", data.order);
    formdata.append("title", data.title);

    let result = await axiosInstance
      .post(`/Session/`, formdata)
      .catch((err) => {
        if (err.response && err.response.status !== 500) {
          for (const error in err.response.data) {
            setTimeout(() => {
              toast.error(
                `${
                  error +
                  " : " +
                  err.response.data[error][0].charAt(0).toUpperCase() +
                  err.response.data[error][0].slice(1)
                }`
              );
            }, 500);
          }
        } else {
          setTimeout(() => {
            toast.error("Server Error !");
          }, 500);
        }
        setDisabled(false);
        setLoading(false);
      });
    if (result) {
      setLoading(false);

      console.log(result);
      if (result.data) {
        setTimeout(() => {
          toast.info(`Session Added Successfully`);
        }, 500);
        setTimeout(() => {
          navigate("/home");
        }, 3000);
      }
    }
  };
  const onImgUpload = (e) => {
    e.preventDefault();
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
  };
  const onVideoUpload = (e) => {
    e.preventDefault();
    setVideo(e.target.files[0]);
    setVideoName(e.target.files[0].name);
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  return (
    <div className="">
      <Container>
        <Row>
          <Col className="py-4" sm={12}>
            {" "}
            <SubLink text="Courses" />
            <i class="fas fa-chevron-right px-3 title2"></i>
            <span className="title2">Create Session</span>
          </Col>
        </Row>
        <Form
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Row>
            <Col sm="6">
              {" "}
              <Form.Item
                rules={[
                  { required: true, message: "Please input session title!" },
                ]}
                label="Session Title"
                name="title"
                layout="vertical"
              >
                <Input
                  className="loginInput"
                  placeholder="Session Title"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              {" "}
              <Form.Item
                label="Session Zoom Link"
                name="zoom_link"
                layout="vertical"
                rules={[{ required: false }]}
              >
                <Input
                  className="loginInput"
                  placeholder="Session Zoom Link"
                  type="text"
                />
              </Form.Item>
            </Col>
            <Col sm="6">
              <Form.Item
                rules={[
                  { required: true, message: "Please input session price!" },
                ]}
                label="Price"
                name="price"
                layout="vertical"
              >
                <Input
                  className="loginInput"
                  placeholder="Price"
                  type="number"
                />
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Form.Item
                label="Upload Video"
                // name="edu_lvl"
                layout="vertical"
              >
                <div class="wrapper">
                  <div class="file-upload">
                    <input
                      onChange={onVideoUpload}
                      type="file"
                      onClick={handleClick}
                    />
                    <i class="fa fa-plus"></i>
                  </div>
                </div>
                <>
                  {video != null ? (
                    <div className="d-flex align-items-center justify-content-between">
                      <p>{videoName}</p>

                      <i
                        class="fas fa-window-close"
                        onClick={() => removeVideo()}
                      ></i>
                    </div>
                  ) : null}
                </>
              </Form.Item>
            </Col>
            <Col sm="6">
              {" "}
              <Form.Item
                label="Upload Image"
                // name="edu_lvl"
                layout="vertical"
              >
                <div class="wrapper">
                  <div class="file-upload">
                    <input
                      onChange={onImgUpload}
                      type="file"
                      onClick={handleClick}
                    />
                    <i class="fa fa-plus"></i>
                  </div>
                </div>

                {image != null ? (
                  <div className="d-flex align-items-center justify-content-between">
                    <p>{imageName}</p>

                    <i
                      class="fas fa-window-close"
                      onClick={() => removeImage()}
                    ></i>
                  </div>
                ) : null}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Form.Item
                rules={[
                  { required: true, message: "Please input session order!" },
                ]}
                label="Display Order"
                name="order"
                layout="vertical"
              >
                <Input
                  className="loginInput"
                  placeholder="Display Order"
                  type="number"
                />
              </Form.Item>{" "}
            </Col>
            <Col sm="6">
              <Form.Item
                label="Description"
                name="description"
                layout="vertical"
              >
                <TextArea
                  rows={4}
                  className="loginInput"
                  placeholder="Description"
                  type="text"
                />
              </Form.Item>
            </Col>
            <Col sm="6"></Col>
          </Row>
          <Row>
            <Col className="text-center" md={12}>
              <Button
                disabled={disabled}
                htmlType="submit"
                className="courseBtn1 mx-3"
              >
                {loading ? <Spin tip="loading..." /> : "Submit"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
}

export default ErrorHandler(CreateSession, axiosInstance);
