import React from "react";
import styles from "./QuestionsGrid.module.css";

export default function QuestionsGrid({questions, selectedChoices, onSelect}) {
    const answeredCount = Object.keys(selectedChoices).length;
    const unansweredCount = +questions.count - answeredCount;
    return (
        <div className={styles.container}>
            <h6>Answered: <span>{answeredCount}</span></h6>
            <h6>Unanswered: <span>{unansweredCount}</span></h6>
            <div className={styles.questionsGrid}>
                {
                    questions.data?.map((it, i) => {
                        const state = selectedChoices[i] ? "selected" : ""
                        return <button
                            onClick={() => onSelect(i)}
                            className={[styles.questionGridItem, styles[state]].join(" ")}
                        >{i + 1}</button>
                    })
                }
            </div>
        </div>
    )
}