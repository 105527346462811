import React, {useState} from 'react'
import {useEffect} from 'react'
import {Container} from 'react-bootstrap'
import axiosInstance from '../../../api/axios'
import ExamsCategory from './ExamsCategory'
import ErrorHandler from "../../../error-handler/ErrorHandler";
import useScreenShotPreventer from "../../../hooks/screenshotpreventer";

function ExamsPage({}) {

    const [formattedExams, setFormattedExams] = useState()
    // useEffect(() => { setFormattedExams(formateExams()) }, [exams])

    useEffect(() => {
        getExams()
    }, [])

    // useScreenShotPreventer()

    async function getExams() {
        const result = await axiosInstance.get("all_exams");
        console.log({result})
        setFormattedExams(formatExams(result.data))
    }

    function formatExams(exams) {
        const today = new Date();
        const usedExams = exams.reduce((prev, cur) => {
            const currentDate = new Date(cur.end_date)
            // console.log({ today, currentDate })
            const prevMut = {...prev}
            if (today > currentDate)
                prevMut.previous.push({
                    ...cur,
                    id: cur.id,
                    title: cur.title,
                    description: cur.description,
                    image: cur.image,
                    isExpired: true
                })
            else prevMut.live.push({
                ...cur,
                id: cur.id,
                title: cur.title,
                description: cur.description,
                image: cur.image,
                isExpired: false
            })

            return prevMut;
        }, {live: [], previous: []})

        console.log({usedExams})
        return [{title: "Live Exams", data: usedExams.live}, {title: "Previous Exams", data: usedExams.previous}];
    }

    return (
        <Container sm={12}>
            <h2 className="pageTitle">Exams</h2>
            {formattedExams?.map((it, i) => <ExamsCategory key={i} {...it} />)}
        </Container>
    )
}

export default ErrorHandler(ExamsPage, axiosInstance);


