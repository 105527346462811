import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import img1 from "../../../images/avecImg.png";
import "../../../style-sheets/loginStyle.css";
import {Form, Input, Button} from "antd";

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {userLogin, LogOut} from "../../../global-state/actions/authAction";
// import { showNav } from "../global-state/actions/showNavAction";
import {Link, useNavigate} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import logo from "../../../images/logo.png";

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [BeErr, setBEerror] = useState("");
    const [emailErr, setEmailerr] = useState("");
    let navigate = useNavigate();
    const onFinish = () => {
    };
    const handleEmail = (e) => {
        setEmail(e.target.value);
        if (
            e.target.value.match(
                /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
            )
        ) {
            setEmail(e.target.value);
            setEmailerr("");
        } else {
            setEmailerr("Enter valid email!");
        }
    };
    const handlePassword = (e) => {
        setPassword(e.target.value);
    };
    const onLogin = async () => {
        let loginrequest = {};
        loginrequest.email = email;
        loginrequest.password = password;

        const userData = await props.userLogin(loginrequest);

        if (userData == "No active account found with the given credentials") {
            setTimeout(() => {
                toast.error(userData);
            }, 500);
        } else {
            navigate("/home");
        }
    };
    return (
        <div className="loginPage py-5">
            {/* <Container className="p-4"></Container> */}
            <Container className="loginContainer">
                <Row>
                    <Col sm="3">
                        <img src={logo} alt="chem-valley-logo" className="logo img-fluid"/>
                    </Col>
                </Row>
                <Row>
                    <Col className="imgCol1" sm={6}>
                        <div className="img-box">
                            {" "}
                            <img alt="img-login" src={img1} className="img-fluid loginImg"/>
                        </div>
                    </Col>
                    <Col className="pl-5 text-left" sm={6}>
                        <Form
                            onFinish={onFinish}
                            // onFinishFailed={onFinishFailed}
                            style={{
                                margin: "30px auto 0px auto",
                            }}
                            layout="vertical"
                        >
                            <h1 className="">Sign In</h1>

                            <Form.Item
                                label="Email"
                                name="Email"
                                rules={[
                                    {required: true, message: "Please input your Email!"},
                                ]}
                                layout="vertical"
                            >
                                <Input
                                    className="loginInput"
                                    placeholder="Email"
                                    onChange={handleEmail}
                                    type="email"
                                    name="email"
                                    value={email}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {required: true, message: "Please input your password!"},
                                ]}
                            >
                                <Input.Password
                                    value={password}
                                    name="password"
                                    onChange={handlePassword}
                                    placeholder="Password"
                                    className="loginInput"
                                    style={{
                                        marginBottom: "0px"
                                    }}
                                />
                            </Form.Item>

                            <Row className={"forget"} style={{
                                paddingInlineEnd: "26px"
                            }}>
                                <Link to={"/forget_password_request"}>Forget Password?</Link>
                            </Row>
                            {/* <a href="/forget_password" className="forget">
                Forgot Password?
              </a> */}
                            <Form.Item>
                                <Button
                                    className="loginBtn1"
                                    onClick={onLogin}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    LOGIN{" "}
                                </Button>
                            </Form.Item>
                            <Row>
                                <Col style={{margin: "auto"}} sm={12}>
                                    {BeErr !== "" ? (
                                        <p style={{color: "red", fontWeight: "bold"}}>{BeErr}</p>
                                    ) : null}
                                </Col>
                            </Row>

                            <p className="p1">
                                Don't Have An Account ?{" "}
                                <a href="/register" className="span1">
                                    Sign Up
                                </a>
                            </p>
                        </Form>
                    </Col>
                </Row>
                <Row></Row>
            </Container>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({userLogin, LogOut}, dispatch);
};
export default connect(null, mapDispatchToProps)(Login);
