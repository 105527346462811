import React, {useEffect, useState} from "react";
import {Container, Row, Col, OverlayTrigger, Tooltip} from "react-bootstrap";
import axiosInstance from "../../api/axios";
import {Pagination, Spin} from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import {useParams, Link} from "react-router-dom";
import DataTable from "react-data-table-component";
import {CSVLink} from "react-csv";
import {getMarkedQuiz} from "../../global-state/actions/getMarkedQuizAction";
import {questionType} from "../../global-state/actions/questionAction";

import {useDispatch} from "react-redux";

function StudentsScore() {
    const params = useParams();
    const isAzhar = params.azhar == "1";
    const dispatchID = useDispatch();
    const dispatchType = useDispatch();
    const [columns, setColumns] = useState([
        {
            name: "First Name",
            selector: (row) => row.student.user.first_name,
            sortable: true,
            right: true
        },
        {
            name: "Last Name",
            selector: (row) => row.student.user.last_name,
            sortable: true,
            right: true
        },
        {
            name: "Mark",
            sortable: true,
            right: true,
            cell: (row) => (
                <div className="px-2">{row.mark}/{row.full_mark}</div>
            )
        },
        {
            name: "Status",
            width: "120px",
            button: true,
            cell: (row) => (
                <div className="px-2">{row.passed ? "Passed" : "Failed"}</div>
            )
        },
        {
            name: "Actions",
            width: "120px",
            button: true,
            cell: (row) => (
                <>
                    {/* <div id={row.id} className="exported-row">
            <p>{row.student.user.first_name}</p>
            <p>{row.mark}</p>
          </div> */}
                    <div className="exported-row">
                        <CSVLink
                            // onClick={(event) => {
                            //   console.log(event.target);
                            //   return false; // 👍🏻 You are stopping the handling of component
                            // }}
                            data={[
                                {firstname: row.student.user.first_name, Mark: row.mark}
                            ]}
                        >
                            <OverlayTrigger
                                key={"1"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>Download Result</Tooltip>
                                }
                            >
                                <i
                                    style={{fontSize: "22px", marginInline: "4px"}}
                                    className="actionIcon fas fa-file-download"
                                ></i>
                            </OverlayTrigger>
                        </CSVLink>
                        {isAzhar && <OverlayTrigger
                            key={"2"}
                            placement={"top"}
                            overlay={
                                <Tooltip id={`tooltip-${"top"}`}>Mark Sheet</Tooltip>
                            }
                        >
                            <Link to={`/mark_azhar_${params.type}/${row.student.id}/${params.id}`}>
                                <i
                                    style={{fontSize: "22px", marginInline: "4px"}}
                                    className="actionIcon fas fa-clipboard-check"
                                />
                            </Link>
                        </OverlayTrigger>}
                        <OverlayTrigger
                            key={"2"}
                            placement={"top"}
                            overlay={
                                <Tooltip id={`tooltip-${"top"}`}>Send To Father</Tooltip>
                            }
                        >
                            <a
                                href={`https://wa.me/+2${row.student.father_phone}`}

                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i
                                    style={{fontSize: "22px", marginInline: "4px"}}
                                    className="actionIcon fab fa-whatsapp whatsapp-icon"></i>
                            </a>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key={"2"}
                            placement={"top"}
                            overlay={
                                <Tooltip id={`tooltip-${"top"}`}>Send To Mother</Tooltip>
                            }
                        >
                            <a
                                href={`https://wa.me/+2${row.student.mother_phone}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i style={{fontSize: "22px", marginInline: "4px"}}
                                   className="actionIcon fab fa-whatsapp whatsapp-icon"/>
                            </a>
                        </OverlayTrigger>
                        {params.azhar == "1" && row.mark == "1" ? (
                            <OverlayTrigger
                                key={"2"}
                                placement={"top"}
                                overlay={<Tooltip id={`tooltip-${"top"}`}>Mark Quiz</Tooltip>}
                            >
                                <Link to={`/mark_azhar_quiz/${row.student.id}/${params.id}`}>
                                    <i
                                        style={{fontSize: "22px"}}
                                        className="actionIcon fas fa fa-eye ps-2"
                                        onClick={() => {
                                            dispatchID(getMarkedQuiz(row.id));
                                            dispatchType(
                                                questionType(params.type == "quiz" ? "quiz" : "exam")
                                            );
                                        }}
                                    ></i>
                                </Link>
                            </OverlayTrigger>
                        ) : null}
                    </div>
                </>
            )
        }
    ]);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [current, setCurrent] = useState(1);
    const [loading, setLoading] = useState(false);

    const getScores = async () => {
        let result;
        if (params.type == "quiz") {
            result = await axiosInstance.get(
                `/StudentQuiz/?quiz=${params.id}&page=1`
            );
        } else {
            result = await axiosInstance.get(
                `/StudentExam/?exam=${params.id}&page=1`
            );
        }

        console.log(result);
        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);

        getScores();
    }, []);
    const onPageChange = async (page) => {
        setCurrent(page);
        let result;
        if (params.type == "quiz") {
            result = await axiosInstance.get(
                `/StudentQuiz/?quiz=${params.id}&page=${page}`
            );
        } else {
            result = await axiosInstance.get(
                `/StudentExam/?exam=${params.id}&page=${page}`
            );
        }
        setData(result.data.data);
        setCount(result.data.count);
    };

    return (
        <div>
            <div className="">
                <Container>
                    <Row>
                        <Col className="py-4" sm={6}>
                            {" "}
                            <span className="title1">
                {params.type === "quiz" ? "Quiz Results" : "Exam Results"}
              </span>
                        </Col>
                    </Row>
                    {loading ? (
                        <Spin tip="loading...."/>
                    ) : (
                        <>
                            {" "}
                            <Row>
                                <Col style={{textAlign: "center"}} sm={12}>
                                    <DataTable columns={columns} data={data}/>
                                </Col>
                            </Row>
                            <Row className="p-4">
                                {data.length !== 0 ? (
                                    <Pagination
                                        onChange={onPageChange}
                                        defaultCurrent={current}
                                        total={count}
                                        current={current}
                                    />
                                ) : null}
                            </Row>
                        </>
                    )}
                </Container>
            </div>
        </div>
    );
}

export default ErrorHandler(StudentsScore, axiosInstance);
