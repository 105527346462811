import React, {useMemo} from "react";
import styles from "./NotificationCard.module.css";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import formatDate from "../../../../utils/format-date";

export default function NotificationCard({
                                             className,
                                             icon, title, created_at: date,
                                             body, secondary, extraText,
                                             buttonText, children: actions,
                                             onClick, ...rest
                                         }) {


    const notificationDate = new Date(date);
    const formattedDate = useMemo(() => formatDate(notificationDate), [])


    return (
        <div className={`${styles.container} ${className}`} {...rest}>
            <div className={styles.header}>
                <div className={styles.title}>
                    <img className={styles.icon} src={icon}/>
                    <h1>{title}</h1>
                    <span className={`${styles.contentText} ${styles.date}`}>{formattedDate}</span>
                </div>
            </div>
            <div className={styles.main}>{secondary}</div>
            <div className={[styles.contentText, styles.info].join(" ")}>{body}</div>
            {extraText && <div className={[styles.contentText, styles.extra].join(" ")}>{extraText}</div>}
            <div className={styles.actions}>
                <button className={`noselect`}
                        onClick={onClick}
                >{buttonText}</button>
                {actions}
            </div>
        </div>
    )
}