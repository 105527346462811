import { LOGOUT, LOGIN, REGISTER, ACCESS_TOKEN } from "./actionTypes";
import axios from "axios";
import { BASE_URL } from "../../api/axios";
import Cookies from "js-cookie";
export const userLogin = (userdata, history) => async (dispatch) => {

  const logindata = await new Promise((resolve, reject) => {

    axios
      .post(BASE_URL + "/auth/jwt/create", userdata)
      .then((res) => {
        resolve(res.data);
        if (res.data) {
          console.log(res.data);
          const accessToken = res.data.access;
          const refreshToken = res.data.refresh;
          localStorage.setItem("refreshToken", refreshToken);
          Cookies.set("accessToken", accessToken);
          dispatch({ type: ACCESS_TOKEN, payload: res.data.access });
          dispatch({ type: LOGIN, payload: res.data });
          // const device = axios
          //     .post(
          //       BASE_URL + "/devices",
          //       {
          //         registration_id: localStorage.getItem("fireToken"),
          //         type: "web",
          //         // user:res.data.id,
          //         // device_id:res.data.email
          //       },
          //       {
          //         headers: {
          //           Authorization: `Bearer ${accessToken}`,
          //         },
          //       }
          //     )
          //     .catch((error) => {
          //       console.log(error.response);
          //     });
        }
      })
      .catch((err) => {
        // reject(err);
        if (err.response) {
          console.log(err.response.data.detail);
          resolve(err.response.data.detail);
        }
      });
  });
  return logindata;
};

export const userRegister = (userdata, history) => async (dispatch) => {
  const regiserdata = await new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + "/student_signup", userdata)
      .then((res) => {
        resolve(res.data);
        if (res.data) {
          dispatch({ type: REGISTER, payload: res.data });
          // if (res.data.user_type === "4") {
          //   history.push("/calender-home");
          // } else {
          //   history.push("/home");
          // }
        }
      })
      .catch((err) => {
        // reject(err);
        if (err.response) {
          console.log(err.response.data);
          resolve(err.response.data);
        }
      });
  });
  return regiserdata;
};

export const LogOut = () => async (dispatch) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  dispatch({ type: LOGOUT });
};
