import React, { useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import { Input, Form, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";

function ResetPasswordModal({ show, hide }) {
  const [form] = Form.useForm();

  const changePassword = async (values) => {
    let result = await axiosInstance
      .put(`/rest_password`, values)
      .catch((err) => {
        if (err.response && err.response.status !== 500) {
          for (const error in err.response.data) {
            setTimeout(() => {
              toast.error(
                `${
                  error +
                  " : " +
                  err.response.data[error][0].charAt(0).toUpperCase() +
                  err.response.data[error][0].slice(1)
                }`
              );
            }, 500);
          }
        } else {
          setTimeout(() => {
            toast.error("Server Error !");
          }, 500);
        }
      });
    if (result) {
      console.log(result);
      if (result.data) {
        hide();
        setTimeout(() => {
          toast.success(`Password has been updated successfully!`);
        }, 500);
        form.resetFields();
      }
    }
  };
  return (
    <Modal show={show} onHide={hide}>
      <Container className="p-5">
        <Row>
          <Col className="" md={12}>
            <p className="changePassTitle">
              <i
                style={{ color: "red" }}
                class="fas fa-exclamation-circle pe-2"
              ></i>
              Change your password
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center" md={12}>
            <Form onFinish={changePassword} layout="vertical">
              <Form.Item
                name="old_password"
                label="Old Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your old password!"
                  }
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="new_password"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your new password!"
                  }
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["new_password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!"
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    }
                  })
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Button className="courseBtn1" htmlType="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3" sm={12}>
            {" "}
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
export default ErrorHandler(ResetPasswordModal, axiosInstance);
