import React, {useEffect, useState} from "react";
import {
    Container,
    Row,
    Col,
    Modal,
    OverlayTrigger,
    Tooltip,
    Button
} from "react-bootstrap";
import axiosInstance from "../../api/axios";
import {Pagination, Spin} from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import "../../style-sheets/session.css";
import {useParams, Link} from "react-router-dom";
import DataTable from "react-data-table-component";
import {ToastContainer, toast} from "react-toastify";
import AddStudentsToSession from "../Layouts/AddStudentsToSession";
import {useSelector} from "react-redux";
import SubLink from "../Link";
import ActiviteSessionManually from "../Layouts/ActiviteSessionManually";

function AllSessions() {
    const params = useParams();
    const user = useSelector((state) => state.auth.user);
    const [columns, setColumns] = useState([
        {
            name: "Session Title",
            selector: "title",
            sortable: true,
            right: true
        },

        {
            name: "Session Price",
            selector: "price",
            sortable: true,
            right: true
        },
        {
            name: "Session order",
            selector: "order",
            sortable: true,
            right: true
        },
        {
            name: "Education Level",
            selector: "course.edu_lvl",
            sortable: true,
            right: true
        },
        {
            name: "Actions",
            width: "150px",
            button: true,
            cell: (row) => (
                <div className="table-data-feature">
                    {user.user_type == "3" ? (
                        <React.Fragment>
                            <OverlayTrigger
                                key={"2"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>View Session</Tooltip>
                                }
                            >
                                <Link to={`/session_details/${row.id}`}>
                                    <i className="actionIcon fas fa fa-eye ps-2"></i>
                                </Link>
                            </OverlayTrigger>

                            <OverlayTrigger
                                key={"2"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>View Materials</Tooltip>
                                }
                            >
                                <Link to={`/view_material/${row.id}`}>
                                    <i className="actionIcon fas fa fa-eye ps-2"></i>
                                </Link>
                            </OverlayTrigger>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <OverlayTrigger
                                key={"2"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>View Session</Tooltip>
                                }
                            >
                                <Link to={`/edit_session/${row.id}`}>
                                    <i className="actionIcon fas fa fa-eye ps-2"></i>
                                </Link>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key={"2"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>Delete Session</Tooltip>
                                }
                            >
                                <i
                                    onClick={showDelete}
                                    id={row.id}
                                    className="actionIcon fas fa fa-trash ps-2"
                                ></i>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key={"2"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>Create Material</Tooltip>
                                }
                            >
                                <i
                                    onClick={showAddMaterial}
                                    id={row.id}
                                    className="actionIcon fas fa fa-plus ps-2"
                                ></i>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key={"2"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>View Materials</Tooltip>
                                }
                            >
                                <Link to={`/view_material/${row.id}`}>
                                    <i className="actionIcon fas fa fa-eye ps-2"></i>
                                </Link>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key={"2"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>
                                        Add Students Manually
                                    </Tooltip>
                                }
                            >
                                <i
                                    onClick={(e) => showAddStudents(e, row.course.edu_lvl)}
                                    id={row.id}
                                    className="actionIcon fas fa fa-plus ps-2"
                                ></i>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key={"2"}
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-${"top"}`}>
                                        activite Session Manually{" "}
                                    </Tooltip>
                                }
                            >
                                <i
                                    onClick={showSessionsModel}
                                    id={row.id}
                                    className="actionIcon fas fa fa-plus ps-2"
                                ></i>
                            </OverlayTrigger>
                        </React.Fragment>
                    )}
                </div>
            )
        }
    ]);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [current, setCurrent] = useState(1);
    const [sessionID, setSessionID] = useState("");
    const [sessionEduLevel, setSessionEduLevel] = useState();
    const [show, setShow] = useState("");
    const [showMaterial, setShowMaterial] = useState("");
    const [material, setMaterial] = useState("");
    const [materialName, setMaterialName] = useState("");
    const [showStudents, setShowStudents] = useState(false);
    const [showReopen, setShowReopen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSubmit, setloadingSubmit] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const getSession = async () => {
        let result = await axiosInstance.get(
            `/sessions_in_course/${params.id}?page=1`
        );
        console.log({sessions: result});
        if (result) {
            setLoading(false);
            setData(result.data.data);
            setCount(result.data.count);
        }
    };

    useEffect(() => {
        setLoading(true);
        getSession();
    }, []);
    const onPageChange = async (page) => {
        setCurrent(page);
        let result = await axiosInstance.get(
            `/sessions_in_course/${params.id}?page=${page}`
        );
        setData(result.data.data);
        setCount(result.data.count);
    };
    const showDelete = (e) => {
        setSessionID(e.target.id);
        setShow(true);
    };
    const close = () => {
        setShow(false);
    };
    const deleteSession = async () => {
        let result = await axiosInstance.delete(`/Session/${sessionID}`);
        if (result) {
            console.log(result);
            setShow(false);
            getSession();
        }
    };

    const showAddMaterial = (e) => {
        setSessionID(e.target.id);
        setShowMaterial(true);
    };
    const hideMaterial = () => {
        setShowMaterial(false);
    };
    const onFileUpload = (e) => {
        e.preventDefault();
        setMaterial(e.target.files[0]);
        setMaterialName(e.target.files[0].name);
    };
    const createMaterial = async () => {
        setloadingSubmit(true);
        setDisabled(true);
        let formdata = new FormData();
        formdata.append("material", material);
        formdata.append("session", sessionID);
        let result = await axiosInstance
            .post(`/Material/`, formdata)
            .catch((err) => {
                setTimeout(() => {
                    toast.error("Error Uploading Material");
                }, 500);
                setDisabled(false);
                setloadingSubmit(false);
            });
        if (result) {
            console.log(result);
            setShowMaterial(false);
            setloadingSubmit(false);

            if (result.data) {
                setTimeout(() => {
                    toast.info(`Material Added Successfully`);
                }, 500);
                setMaterial("");
                setMaterialName("");
                setDisabled(false);
            }
        }
    };

    const showAddStudents = (e,edu) => {
        setSessionID(e.target.id);
        setSessionEduLevel(edu)
        setShowStudents(true);
    };

    const showSessionsModel = (e) => {
        setSessionID(e.target.id);
        setShowReopen(true);
    };

    const closeStudentsModel = () => {
        setShowStudents(false);
    };

    const closeSessionsModel = () => {
        setShowReopen(false);
    };
    return (
        <div>
            <div className="">
                <Container>
                    <Row>
                        <Col className="py-4" sm={12}>
                            {" "}
                            <SubLink text="Courses"/>
                            <i class="fas fa-chevron-right px-3 title2"></i>
                            <span className="title2">View Sessions</span>
                        </Col>
                    </Row>
                    {!loading ? (
                        <>
                            <Row>
                                <Col style={{textAlign: "center"}} sm={12}>
                                    <DataTable columns={columns} data={data}/>
                                </Col>
                            </Row>
                            <Row className="p-4">
                                {data.length !== 0 ? (
                                    <Pagination
                                        onChange={onPageChange}
                                        defaultCurrent={current}
                                        total={count}
                                        current={current}
                                    />
                                ) : null}
                            </Row>
                        </>
                    ) : (
                        <Spin tip="...loading"/>
                    )}
                </Container>
            </div>
            <Modal show={show} onHide={close}>
                <Container className="p-4">
                    <Row>
                        <Col className="text-center" sm={12}>
                            {" "}
                            <h6>Are you sure you want to delete this session?</h6>
                        </Col>
                        <Row>
                            <Col className="text-center" md={12}>
                                <Button onClick={deleteSession} className="courseBtn1 mx-3">
                                    Delete
                                </Button>
                                <Button className="courseBtn1" onClick={close}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </Modal>
            <Modal show={showMaterial} onHide={hideMaterial}>
                <Container className="p-4">
                    <Row>
                        <Col className="text-center" sm={12}>
                            {" "}
                            <h6>Create Material for this session</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center py-3" md={12}>
                            <div class="wrapper">
                                <div class="file-upload2">
                                    <input onChange={onFileUpload} type="file"/>
                                    <i class="fa fa-plus pe-2"></i>Upload Here
                                </div>
                            </div>
                            <p>{materialName}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" md={12}>
                            <Button
                                disabled={disabled}
                                onClick={createMaterial}
                                className="courseBtn1 mx-3"
                            >
                                {loadingSubmit ? <Spin tip="loading..."/> : "Submit"}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
            <AddStudentsToSession
                eduLevel={sessionEduLevel}
                hide={closeStudentsModel}
                show={showStudents}
                id={sessionID}
            />

            <ActiviteSessionManually
                hide={closeSessionsModel}
                show={showReopen}
                id={sessionID}
            />
        </div>
    );
}

export default ErrorHandler(AllSessions, axiosInstance);
