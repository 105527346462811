import React from "react";
import {Col, Row} from "react-bootstrap";
import {Form} from "antd";

export default function MaterialUploader({
                                             uploadLevels,
                                             videoModelAnswers, setVideoModelAnswers,
                                             documentModelAnswers, setDocumentModelAnswers,
                                         }) {

    function handleModelAnswerUpload(e, {materialType, quizLevel}) {
        e.preventDefault();
        const value = (prev) => ({
            ...prev,
            [quizLevel]: {
                file: e.target.files[0],
                name: e.target.files[0].name,
            }
        });
        handleMaterialType(materialType, value);
    }

    function removeModelAnswerItem({materialType, quizLevel}) {
        const value = (prev) => {
            const copy = {...prev};
            delete copy[quizLevel];
            return copy;
        };
        handleMaterialType(materialType, value);
    }

    function handleMaterialType(materialType, value) {
        if (materialType === "video")
            setVideoModelAnswers(value);
        else setDocumentModelAnswers(value);
    }

    const handleClick = (event) => {
        const {target = {}} = event || {};
        target.value = "";
    };

    return (
        <div>
            {
                uploadLevels.map(it => {
                    if (!it) return;
                    const {level, name} = it;
                    return (
                        <div key={it.level}>
                            {
                                name && <Row><h6>{name}</h6></Row>
                            }
                            <Row>
                                < Col sm="6">
                                    <Form.Item
                                        label="Upload Video Model answers"
                                        layout="vertical"
                                    >
                                        <div class="wrapper">
                                            <div class="file-upload">
                                                <input
                                                    accept="video/*"
                                                    onChange={(e) => handleModelAnswerUpload(e, {
                                                        materialType: "video",
                                                        quizLevel: level,
                                                    })}
                                                    type="file"
                                                    onClick={handleClick}
                                                />
                                                <i class="fa fa-plus"></i>
                                            </div>
                                        </div>
                                        {videoModelAnswers[level] != null ? (
                                            <div
                                                className="d-flex align-items-center justify-content-between">
                                                <p>{videoModelAnswers[level].name}</p>

                                                <i
                                                    class="fas fa-window-close"
                                                    onClick={() => removeModelAnswerItem({
                                                        materialType: "video",
                                                        quizLevel: level,
                                                    })}
                                                ></i>
                                            </div>
                                        ) : null}
                                    </Form.Item>
                                </Col>
                                <Col sm="6">
                                    <Form.Item
                                        label="Upload document Model answers"
                                        // name="edu_lvl"
                                        layout="vertical"
                                    >
                                        <div class="wrapper">
                                            <div class="file-upload">
                                                <input
                                                    onChange={(e) => handleModelAnswerUpload(e, {
                                                        materialType: "document",
                                                        quizLevel: level,
                                                    })}
                                                    type="file"
                                                    onClick={handleClick}
                                                />
                                                <i class="fa fa-plus"></i>
                                            </div>
                                        </div>
                                        {documentModelAnswers[level] != null ? (
                                            <div
                                                className="d-flex align-items-center justify-content-between">
                                                <p>{documentModelAnswers[level].name}</p>

                                                <i
                                                    class="fas fa-window-close"
                                                    onClick={() => removeModelAnswerItem({
                                                        materialType: "document",
                                                        quizLevel: level,
                                                    })}
                                                ></i>
                                            </div>
                                        ) : null}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    )
                })

            }
        </div>
    )
}