import React, {useState, useEffect} from "react";
import {Row, Col} from "react-bootstrap";
import axiosInstance from "../../api/axios";
import {Table, Button, Skeleton} from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import {useParams} from "react-router-dom";
import img1 from "../../images/grade1.svg";
import img2 from "../../images/grade2.svg";
import img3 from "../../images/grade3.svg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactWhatsapp from "react-whatsapp";
import {BASE_URL} from "../../api/axios";

const StudentReport = () => {
    const params = useParams();
    const [details, setDetails] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isExam, setIsExam] = useState(true);
    const [reportLink, setReportLink] = useState("");

    const columns = [
        {
            title: isExam ? "Chapter" : "Session",
            render: (row) => (
                <p>{isExam ? row?.exam?.title : row?.quiz?.session?.title}</p>
            )
        },
        {
            title: "Time Stamp",
            dataIndex: "created_at",
            render: (row) => <p>{row.split("T")[0]}</p>
        },
        {
            title: "Marks",
            render: (row) => (
                <p>
                    {row?.mark}/{row?.full_mark}
                </p>
            )

            // dataIndex: "mark"
        }
    ];
    const getStudentDetails = async () => {
        let result = await axiosInstance.get(`/StudentProfile/${params.id}`);
        if (result) {
            setDetails(result.data);
            setLoading(false);
        }
    };
    useEffect(() => {
        setLoading(true);
        getStudentDetails();
        // getGradesDetails();
    }, []);

    // const exportPDF = () => {
    //   const unit = "pt";
    //   const size = "A4"; // Use A1, A2, A3 or A4
    //   const orientation = "portrait"; // portrait or landscape

    //   const marginLeft = 40;
    //   const doc = new jsPDF(orientation, unit, size);

    //   doc.setFontSize(15);

    //   const title = `${details.user?.first_name} ${details.user?.middle_name} ${details.user?.last_name} Report`;
    //   const headers = [["Chapter Name", "Date", "Mark"]];

    //   const datatab = data.map((elt) => [
    //     isExam ? elt.exam?.title : elt.quiz?.session?.title,
    //     elt.created_at,
    //     `${elt.mark}/${elt.full_mark}`
    //   ]);

    //   let content = {
    //     startY: 50,
    //     head: headers,
    //     body: datatab
    //   };

    //   doc.text(title, marginLeft, 40);
    //   doc.autoTable(content);
    //   doc.save("report.pdf");
    // };

    const getGradesDetails = async () => {
        let result = await axiosInstance.get(
            `/report?student=${params.id}&is_exam=${isExam}`
        );
        if (result) {
            console.log(result.data);
            setData(result.data.data[0]);
            setReportLink(result.data.link);
        }
    };
    useEffect(() => {
        getGradesDetails();
    }, [isExam]);

    const generateReport = async () => {
        let result = await axiosInstance
            .get(`${BASE_URL}${reportLink} `, {responseType: "blob"})
            .catch((err) => console.log(err));
        // console.log(result);
        const file = window.URL.createObjectURL(new Blob([result.data]));
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = file;
        // the filename you want
        a.download = "report.pdf";
        // <a href=file download="Invoice.txt" style=display/>
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(file);
    };

    return (
        <div className="report px-5">
            {loading ? (
                <Skeleton active/>
            ) : (
                <>
                    {" "}
                    <div className="reportInfoRow">
                        <div>
                            <p className="studentName"> {details.user?.first_name}</p>
                            <p className="studentName">
                                {details.user?.middle_name} {details.user?.last_name}
                            </p>
                            <div className="infoDiv pt-3">
                                {" "}
                                <p>{details?.school_name}</p>
                                <p>{details?.user?.phone}</p>
                                <p>{details?.user?.email}</p>
                            </div>
                        </div>
                        <div className="infoDiv2 p-3">
                            {details.edu_lvl == "1" ? (
                                <img src={img1} alt="img"/>
                            ) : details.edu_lvl == "2" ? (
                                <img src={img2} alt="img"/>
                            ) : (
                                <img src={img3} alt="img"/>
                            )}

                            <p className="p1"> {details.azhar ? "Azhar" : "General"}</p>
                            <p className="p2">Student Code #{details?.code}</p>
                        </div>
                    </div>
                    <div className="mt-5">
                        <h6>Your Marks</h6>
                        <Row className="reportBtn">
                            <Col sm="12" md="6">
                                <Button onClick={() => setIsExam(true)} className="btn1">
                                    Exams
                                </Button>
                                <Button onClick={() => setIsExam(false)} className="btn2 ms-2">
                                    Quizes
                                </Button>
                            </Col>
                            <Col className="text-end" sm="12" md="6">
                                {details?.father_phone && (
                                    <a
                                        target={"_blank"}
                                        href={`https://wa.me/+2${details?.father_phone}?text=${BASE_URL + reportLink}`}
                                    >
                                        <button className="btn3">
                                            Send via WhatsApp
                                        </button>
                                    </a>
                                )}
                                {/* <a href={BASE_URL + reportLink} target="_blank"> */}
                                <Button onClick={generateReport} className="btn4 ms-2">
                                    Download pdf
                                </Button>
                                {/* </a> */}
                            </Col>
                        </Row>
                        <Row className="mt-3 reportTable">
                            <Table dataSource={data} columns={columns} pagination={false}/>
                        </Row>
                    </div>
                </>
            )}
        </div>
    );
};

export default ErrorHandler(StudentReport, axiosInstance);
