import React from 'react'
import styles from './ScratchCard.module.css'

function Header({ value }) {
  return (
    <div className={styles.cardHeader}>
      <h1>
        {value}
      </h1>
      <hr />
    </div>
  )
}

function ScratchCodeArea({ codeValue }) {
  return (
    <div className={styles.scratchCodeArea}>
      <h4>
        Sratch Gently:
      </h4>
      <div className={styles.scratchCodeContainer}>
        {codeValue}
      </div>
    </div>
  )
}

function Content({ codeValue }) {
  return (
    <div className={styles.content}>
      <ScratchCodeArea codeValue={codeValue} />
      <p>This card gives you access to <span>1 class</span> on:</p>
      <a href='www.chemvalley.net'>www.chemvally.net</a>
    </div>
  )
}

function Footer({ snNumber }) {
  return (
    <div className={styles.cardFooter}>
      <div className={styles.snContainer}>
        <h6>S/N:</h6>
        <div className={styles.snNumber}>
          {snNumber}
        </div>
      </div>
    </div>
  )
}

export default function ScratchCardFront({ code }) {
  const variant = [
    {
      mainClassName: styles.first,
      header: <>1<sup>st</sup> SECONDARY</>,
    },
    {
      mainClassName: styles.second,
      header: <>2<sup>nd</sup> SECONDARY</>,
    },
    {
      mainClassName: styles.third,
      header: <>3<sup>rd</sup> SECONDARY</>,
    }
  ][code.edu_lvl - 1]
  return (
    <div className={`${styles.card} ${variant.mainClassName}`} >
      <div className={styles.cardTop}>
        <Header value={variant.header} />
        <Content codeValue={code.code} />
      </div>
      <Footer snNumber={code.sn} />
    </div>
  )
}
