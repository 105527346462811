import React from "react";
import {Link} from "react-router-dom";
import styles from "./Home.module.css";
import {BASE_URL} from "../../../api/axios";
import {Col} from "react-bootstrap";

export default function HomeContinueWatching({user, videos, ...rest}) {
    return (
        <Col className={styles.column}>
            <div className="info-div">
                <p className="info1 ps-2">Good Morning,</p>
                <p className="info2 ps-2">{user.first_name}</p>
            </div>
            <div className="cont-div">
                <p className="watching-title">Continue watching</p>
                <p className="sub1">Pick up where you left off</p>
            </div>
            <div {...rest}>
                {videos.map((video) => (
                    <Link to={`/Video/${video.id}`} key={video.id}>
                        <div className={styles.videosCard} style={{
                            backgroundImage: video?.session?.image ? `url(${BASE_URL}${video.session.image})` : "",
                        }}>
                            <div className={`${styles.info}`}>
                                <div className={styles.play}>
                                    <div className={`info-div`}>
                                        <p className="videosCardCourseTitle ps-2  mb-0">
                                            {video.session.course.title}
                                        </p>
                                        <p className="videosCardSessionTitle ps-2 mb-0">
                                            {video.session.title}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </Col>
    )
}