import React, { useState } from "react";
import {
  Pagination,
  Spin,
  Input,
  Button,
  Table,
  Popover,
  List,
  Card,
  Select
} from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Col, Row } from "react-bootstrap";
import { BASE_URL } from "../../api/axios";
import axiosInstance from "../../api/axios";

const TableActionsList = ({ onChange, filters, onReset }) => {
  const [centers, setCenters] = useState([]);

  const getCenter = async () => {
    let result = await axiosInstance.get(BASE_URL + `/Center/`);
    console.log(result);
    setCenters(result.data);
  };

  return (
    <div>
      <Row>
        <Col sm="2">
          <p className="filterTitle">Attendance</p>
          <Select
            defaultValue={filters["attendance_place"] ?? null}
            className="tableFilters"
            onChange={(v) => onChange(["attendance_place", v])}
          >
            <Select.Option value={null}>Any</Select.Option>
            <Select.Option value="2">Online</Select.Option>
            <Select.Option value="1">Center</Select.Option>
          </Select>
        </Col>
        <Col sm="2">
          <p className="filterTitle">Center</p>
          <Select
            onClick={getCenter}
            defaultValue={filters["center"] ?? null}
            className="tableFilters"
            onChange={(v) => onChange(["center", v])}
          >
            <Select.Option value={null}>Any</Select.Option>
            {centers.map((center) => {
              return (
                <Select.Option
                  value={center.id}
                  name={center.name}
                  key={center.id}
                >
                  {center.title}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col sm="2">
          <p className="filterTitle">Grade</p>
          <Select
            defaultValue={filters["edu_lvl"] ?? null}
            className="tableFilters"
            onChange={(v) => onChange(["edu_lvl", v])}
          >
            <Select.Option value={null}>Any</Select.Option>
            <Select.Option value="1">
              1<sup>st</sup> SECONDARY
            </Select.Option>
            <Select.Option value="2">
              2<sup>nd</sup> SECONDARY
            </Select.Option>
            <Select.Option value="3">
              3<sup>rd</sup> SECONDARY
            </Select.Option>
          </Select>
        </Col>
        <Col sm="2">
          <p className="filterTitle">Status</p>
          <Select
            defaultValue={filters["status"] ?? null}
            className="tableFilters"
            onChange={(v) => onChange(["status", v])}
          >
            <Select.Option value={null}>Any</Select.Option>
            <Select.Option value="1">Accepted</Select.Option>
            <Select.Option value="2">Pending</Select.Option>
            <Select.Option value="3">Rejected</Select.Option>
          </Select>
        </Col>
        <Col sm="2">
          <p className="filterTitle">Type</p>
          <Select
            defaultValue={filters["azhar"] ?? null}
            className="tableFilters"
            onChange={(v) => onChange(["azhar", v])}
          >
            <Select.Option value={null}>Any</Select.Option>
            <Select.Option value={false}>Ordinary</Select.Option>
            <Select.Option value={true}>Azhar</Select.Option>
          </Select>
        </Col>
        <Col sm="2">
          <Button
            className="tableBtn mt-3"
            style={{ background: "#fff", color: "#FF6A6A" }}
            onClick={() => onReset()}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ErrorHandler(TableActionsList, axiosInstance);
